import React, { useState } from "react";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import specificStyles from "./Auth.module.css";
import { StandardCTA } from "../MireilleCTAS";
import { StandardConnexionInput } from "../MireilleInputFields";

const emailPlaceHolder = "images/placeHolders/email/emailPlaceHolder.png";
const lockPlaceHolder = "images/placeHolders/lock/lockPlaceHolder.png";

export default function SignIn(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorPassword, setErrorPassword] = useState(null);
    const [errorAuth, setErrorAuth] = useState(null);

    const signInWithEmailAndPasswordHandler = (event) => {
        event.preventDefault();
        console.log("logging");
        const inputValid = checkInputValidity();
        if (inputValid) {
            console.log("input valid");
            signInWithEmailAndPassword(getAuth(), email.trim(), password).catch(
                (error) => {
                    if (error.code === "auth/wrong-password") {
                        setErrorAuth("Le mot de passe est incorrect");
                    }
                    if (error.code === "auth/user-not-found") {
                        setErrorAuth(
                            "Aucun compte n'est associé à cet email, il faut en créer un nouveau"
                        );
                    }
                    console.error(
                        "Error signing in with password and email",
                        error
                    );
                }
            );
        }
    };

    const checkInputValidity = () => {
        const emailValid = validateEmail(email);
        if (!emailValid) {
            setErrorEmail("Il y a un problème avec le format de ton email");
        }
        const passwordValid = password.length > 5;
        if (!passwordValid) {
            setErrorPassword(
                "Ton mot de passe doit faire au moins 6 caractères"
            );
        }
        return emailValid && passwordValid;
    };

    const validateEmail = () => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const fillUserEmail = (event) => {
        const { value } = event.currentTarget;
        setErrorEmail(null);
        setErrorAuth(null);
        setEmail(value);
    };

    const fillUserPassword = (event) => {
        const { value } = event.currentTarget;
        setErrorPassword(null);
        setErrorAuth(null);
        setPassword(value);
    };

    return (
        <Box className={specificStyles.formHolder}>
            <Box className={specificStyles.formTitle}>Hello Mireille !</Box>
            <Box className={specificStyles.formSubtitle}>
                Connecte-toi et continue ta quête des produits cleans
            </Box>
            {errorEmail && (
                <Box className={specificStyles.formError}>{errorEmail}</Box>
            )}
            {errorPassword && (
                <Box className={specificStyles.formError}>{errorPassword}</Box>
            )}
            {errorAuth && (
                <Box className={specificStyles.formError}>{errorAuth}</Box>
            )}
            <FormControl>
                <Box className={specificStyles.inputTitle}>
                    Ton adresse mail
                </Box>
                <StandardConnexionInput
                    value={email}
                    disableUnderline={true}
                    onChange={fillUserEmail}
                    multiline={false}
                    placeholder={"mireille@email.com"}
                    id="input-with-icon-adornment"
                    startAdornment={
                        <InputAdornment
                            position="start"
                            className={specificStyles.imagePlaceHolderBox}
                        >
                            <img
                                src={emailPlaceHolder}
                                className={specificStyles.imagePlaceHolder}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl>
                <Box className={specificStyles.inputTitle}>
                    Ton mot de passe
                </Box>
                <StandardConnexionInput
                    value={password}
                    disableUnderline={true}
                    onChange={fillUserPassword}
                    multiline={false}
                    placeholder={"motdepasse"}
                    type="password"
                    id="input-with-icon-adornment"
                    startAdornment={
                        <InputAdornment
                            position="start"
                            className={specificStyles.imagePlaceHolderBox}
                        >
                            <img
                                src={lockPlaceHolder}
                                className={specificStyles.imagePlaceHolder}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Box
                className={specificStyles.secondaryCTA}
                onClick={props.toggleResetPwd}
            >
                J'ai oublié mon mot de passe
            </Box>
            <Box className={specificStyles.ctaHolder}>
                <StandardCTA
                    onClick={(event) => {
                        signInWithEmailAndPasswordHandler(
                            event,
                            email,
                            password
                        );
                    }}
                >
                    Je me connecte
                </StandardCTA>
            </Box>
            <Box
                className={specificStyles.secondaryCTA}
                onClick={props.toggleSignUp}
            >
                Je n'ai pas encore de compte {">"}
            </Box>
        </Box>
    );
}

// <p className="text-center my-3">
//        Don't have an account?{" "}
//        <Link to="signUp" className="text-blue-500 hover:text-blue-600">
//          Sign up here
//        </Link>{" "}
//        <br />{" "}
//        <Link to = "passwordReset" className="text-blue-500 hover:text-blue-600">
//          Forgot Password?
//        </Link>
//      </p>
