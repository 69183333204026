import React, { useState } from "react";

import {
    getAuth,
    onAuthStateChanged,
    sendPasswordResetEmail
} from "firebase/auth";

import { setFirebaseUser } from "../../redux/actions/account";
import { useDispatch } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import specificStyles from "./Auth.module.css";
import { StandardCTA } from "../MireilleCTAS";
import { StandardConnexionInput } from "../MireilleInputFields";

const emailPlaceHolder = "images/placeHolders/email/emailPlaceHolder.png";

export default function ResetPassword(props) {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [emailNotSent, setEmailNotSent] = useState(false);

    onAuthStateChanged(getAuth(), function (user) {
        if (user) {
            dispatch(setFirebaseUser(user.uid, user.email));
        } else {
            // No user is signed in.
        }
    });

    const forgotPassword = () => {
        sendPasswordResetEmail(getAuth(), email)
            .then(function (user) {
                setEmailSent(true);
            })
            .catch(function (e) {
                setEmailNotSent(true);
            });
    };

    const fillUserEmail = (event) => {
        const { value } = event.currentTarget;
        setEmail(value);
    };

    return (
        <Box className={specificStyles.formHolder}>
            <Box className={specificStyles.formTitle}>Hello Mireille !</Box>
            {emailSent ? (
                <Box className={specificStyles.formSubtitle}>
                    Nous t'avons envoyé un email pour changer ton mot de passe à{" "}
                    {email}
                </Box>
            ) : (
                <>
                    {emailNotSent ? (
                        <Box className={specificStyles.formSubtitle}>
                            Nous n'avons pas pu t'envoyer d'email. Vérfie que
                            c'est bien l'adresse email de ton compte Mireille
                        </Box>
                    ) : (
                        <Box className={specificStyles.formSubtitle}>
                            Tu as oublié ton mot de passe ?
                        </Box>
                    )}
                    <FormControl>
                        <Box className={specificStyles.inputTitle}>
                            Ton adresse mail
                        </Box>
                        <StandardConnexionInput
                            value={email}
                            disableUnderline={true}
                            onChange={fillUserEmail}
                            multiline={false}
                            placeholder={"mireille@email.com"}
                            id="input-with-icon-adornment"
                            startAdornment={
                                <InputAdornment
                                    position="start"
                                    className={
                                        specificStyles.imagePlaceHolderBox
                                    }
                                >
                                    <img
                                        src={emailPlaceHolder}
                                        className={
                                            specificStyles.imagePlaceHolder
                                        }
                                    />
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <Box className={specificStyles.ctaHolder}>
                        <StandardCTA
                            disabled={email === ""}
                            onClick={forgotPassword}
                        >
                            Changer de mot de passe
                        </StandardCTA>
                    </Box>
                </>
            )}
            <Box
                className={specificStyles.secondaryCTA}
                onClick={props.toggleResetPwd}
            >
                C'est bon, j'ai réccupré mon mot de passe
            </Box>
        </Box>
    );
}
