import React, { useState } from "react";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";
import { setFirebaseUser, setUserFirstName } from "../../redux/actions/account";
import { useDispatch } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/material";
import specificStyles from "./Auth.module.css";
import { StandardCTA } from "../MireilleCTAS";
import { StandardConnexionInput } from "../MireilleInputFields";
import * as amplitude from "@amplitude/analytics-browser";

const emailPlaceHolder = "images/placeHolders/email/emailPlaceHolder.png";
const profilePlaceHolder = "images/placeHolders/profile/profilePlaceHolder.png";
const lockPlaceHolder = "images/placeHolders/lock/lockPlaceHolder.png";

export default function SignIn(props) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorPassword, setErrorPassword] = useState(null);
    const [errorAuth, setErrorAuth] = useState(null);

    const checkInputValidity = () => {
        const emailValid = validateEmail(email);
        if (!emailValid) {
            setErrorEmail("Il y a un problème avec le format de ton email");
        }
        const passwordValid = password.length > 5;
        if (!passwordValid) {
            setErrorPassword(
                "Ton mot de passe doit faire au moins 6 caractères"
            );
        }
        return emailValid && passwordValid;
    };

    const validateEmail = () => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const createUserWithEmailAndPasswordHandler = (event) => {
        event.preventDefault();
        const inputValid = checkInputValidity();
        if (inputValid) {
            createUserWithEmailAndPassword(
                getAuth(),
                email.trim(),
                password
            ).then(
                function (user) {
                    amplitude.track("created personal space");

                    //var user = firebase.auth().currentUser;
                    //logUser(user); // Optional
                },
                function (error) {
                    if (error.code === "auth/email-already-in-use") {
                        signInWithEmailAndPassword(
                            getAuth(),
                            email.trim(),
                            password
                        ).catch((error) => {
                            if (error.code === "auth/wrong-password") {
                                setErrorAuth("Le mot de passe est incorrect");
                            }
                            console.error(
                                "Error signing in with password and email",
                                error
                            );
                        });
                    } else {
                        setErrorAuth(
                            "Il y a eu une erreur lors de la création de compte. Tu peux nous contacter pour que l'on résolve ça ensemble."
                        );
                    }
                }
            );

            /*          .catch((error) => {
                if (error.code === "auth/email-already-in-use") {
                    signInWithEmailAndPassword(
                        getAuth(),
                        email.trim(),
                        password
                    ).catch((error) => {
                        if (error.code === "auth/wrong-password") {
                            setErrorAuth("Le mot de passe est incorrect");
                        }
                        console.error(
                            "Error signing in with password and email",
                            error
                        );
                    });
                } else {
                    setErrorAuth(
                        "Il y a eu une erreur lors de la création de compte. Tu peux nous contacter pour que l'on résolve ça ensemble."
                    );
                }
            });*/
        }
    };

    const fillUserEmail = (event) => {
        const { value } = event.currentTarget;
        setErrorEmail(null);
        setErrorAuth(null);
        setEmail(value);
    };

    const fillUserPassword = (event) => {
        const { value } = event.currentTarget;
        setErrorPassword(null);
        setErrorAuth(null);
        setPassword(value);
    };

    return (
        <Box className={specificStyles.formHolder}>
            <Box className={specificStyles.formTitle}>Hello Mireille !</Box>
            <Box className={specificStyles.formSubtitle}>
                Créé ton compte{" "}
                {props.intro
                    ? props.intro
                    : " et découvre ton profil capillaire"}
            </Box>
            {errorEmail && (
                <Box className={specificStyles.formError}>{errorEmail}</Box>
            )}
            {errorPassword && (
                <Box className={specificStyles.formError}>{errorPassword}</Box>
            )}
            {errorAuth && (
                <Box className={specificStyles.formError}>{errorAuth}</Box>
            )}

            <FormControl>
                <Box className={specificStyles.inputTitle}>
                    Renseigne ton adresse mail
                </Box>
                <StandardConnexionInput
                    value={email}
                    disableUnderline={true}
                    onChange={fillUserEmail}
                    multiline={false}
                    placeholder={"mireille@email.com"}
                    id="input-with-icon-adornment"
                    startAdornment={
                        <InputAdornment
                            position="start"
                            className={specificStyles.imagePlaceHolderBox}
                        >
                            <img
                                src={emailPlaceHolder}
                                className={specificStyles.imagePlaceHolder}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <FormControl>
                <Box className={specificStyles.inputTitle}>
                    Choisis ton mot de passe
                </Box>
                <StandardConnexionInput
                    value={password}
                    disableUnderline={true}
                    onChange={fillUserPassword}
                    multiline={false}
                    placeholder={"6 caractères minimum"}
                    type="password"
                    id="input-with-icon-adornment"
                    startAdornment={
                        <InputAdornment
                            position="start"
                            className={specificStyles.imagePlaceHolderBox}
                        >
                            <img
                                src={lockPlaceHolder}
                                className={specificStyles.imagePlaceHolder}
                            />
                        </InputAdornment>
                    }
                />
            </FormControl>
            <Box className={specificStyles.ctaHolder}>
                <StandardCTA
                    onClick={(event) => {
                        createUserWithEmailAndPasswordHandler(event);
                    }}
                >
                    {props.button ? props.button : "Je créé mon compte"}
                </StandardCTA>
            </Box>
            <Box
                className={specificStyles.secondaryCTA}
                onClick={props.toggleSignUp}
            >
                J'ai déja un compte {">"}
            </Box>
        </Box>
    );
}
