import React, { useEffect, useState } from "react";
import sStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import GraphQuadrant from "../components/GraphQuadrant";
import GraphQuadrantDecor from "../components/GraphQuadrantDecor";
import ResultCard from "../components/ResultCard";
import { MainCleanCTA, CleanLink } from "../../../MireilleCTAS.js";
import { useDispatch } from "react-redux";
import { setHairProfileMatrix } from "../../../../redux/actions/hair";
import { normalizedProfileValues } from "../../../../constants/profileParameters";
import DisclaimerPrompt from "../prompts/DisclaimerPrompt";
//import { pushPageView } from "../../../utils/gtmHandler";

export default function ProfileResult(props) {
    const dispatch = useDispatch();
    const [displayLoader, setDisplayLoader] = useState(true);
    const [strengthMatrix, setStrengthMatrix] = useState([]);
    const [legend, setLegend] = useState([]);
    const [hairProfile, setHairProfile] = useState(null);
    const [showDisclaimer, setShowDisclaimer] = useState(false);
    const illus = "images/offers/lvl3.png";

    useEffect(() => {
        //pushPageView("hair_diagnostic_result");
        setTimeout(() => {
            setDisplayLoader(false);
        }, 2000);
    }, []);

    useEffect(() => {
        var svgMatrix = [];
        var legendMatrix = [];
        const svgMatrixLayout = [
            "DEFINITION",
            "NUTRITION",
            "HYDRATATION",
            "PURITY",
            "STRENGTH"
        ];
        svgMatrixLayout.forEach((element) =>
            svgMatrix.push(props.profile[element].SCORE)
        );
        const legendMatrixLayout = [
            "HYDRATATION",
            "PURITY",
            "STRENGTH",
            "DEFINITION",
            "NUTRITION"
        ];
        legendMatrixLayout.forEach((element) =>
            legendMatrix.push(props.profile[element])
        );
        setStrengthMatrix(svgMatrix);
        setLegend(legendMatrix);
        //Calculate the profile for the routines
        if (props.matrix) {
            const normalizedHairProfile = normalizeHairProfile(props.matrix);
            setHairProfile(normalizedHairProfile);
            dispatch(setHairProfileMatrix(normalizedHairProfile));
        }
    }, [props]);

    const normalizeHairProfile = (matrix) => {
        var result = {};
        result.TEXTURE = matrix.TEXTURE;
        result.POROSITY = normalizeHairStat(matrix.POROSITY, 8);
        result.PURITY = normalizeHairStat(matrix.SCORES.PURITY, 12);
        return result; //TODO change return value with result
    };

    const normalizeHairStat = (stat, root) => {
        return Math.min(Math.max(Math.floor(stat / root), 0), 24 / root - 1);
    };

    return (
        <>
            {displayLoader ? (
                <Box className={sStyles.questionLayout}>
                    <img src={illus} className={sStyles.introIllus} />
                    <Box className={sStyles.questionLabel}>
                        <Typography variant="h2">
                            Analyse en cours...
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box className={sStyles.profileDisplayLayout}>
                    <DisclaimerPrompt
                        open={showDisclaimer}
                        close={() => setShowDisclaimer(false)}
                    />
                    <Box className={sStyles.quadrantDisplayColumn}>
                        <Box className={sStyles.quadrantTitle}>
                            <Typography variant="h2">
                                Voici tes forces capillaires
                            </Typography>
                            <Typography variant="body2">
                                Plus un quartier est rempli et plus les besoins
                                de tes cheveux sont faciles à satisfaire.
                            </Typography>
                        </Box>
                        <GraphQuadrantDecor />
                        <GraphQuadrant strengthMatrix={strengthMatrix} />
                        <Box className={sStyles.profileCtaCard}>
                            <Box className={sStyles.profileBlock}>
                                <Box
                                    className={
                                        sStyles.strengthFullLegendTitleHolder
                                    }
                                >
                                    <Typography variant="h5">
                                        Ton profil capillaire
                                    </Typography>
                                </Box>
                                <Box className={sStyles.profileRecap}>
                                    <Box className={sStyles.profileDimHolder}>
                                        <Box
                                            className={sStyles.profileDimTitle}
                                        >
                                            <Typography variant="h2">
                                                POROSITÉ :
                                            </Typography>
                                        </Box>
                                        <Box
                                            className={sStyles.profileDimValue}
                                        >
                                            <Typography variant="h3">
                                                {
                                                    normalizedProfileValues.POROSITY.filter(
                                                        (item) =>
                                                            item.value ===
                                                            hairProfile.POROSITY
                                                    )[0].label
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={sStyles.profileDimHolder}>
                                        <Box
                                            className={sStyles.profileDimTitle}
                                        >
                                            <Typography variant="h2">
                                                TEXTURE :
                                            </Typography>
                                        </Box>
                                        <Box
                                            className={sStyles.profileDimValue}
                                        >
                                            <Typography variant="h3">
                                                {
                                                    normalizedProfileValues.TEXTURE.filter(
                                                        (item) =>
                                                            item.value ===
                                                            hairProfile.TEXTURE
                                                    )[0].label
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={sStyles.profileDimHolder}>
                                        <Box
                                            className={sStyles.profileDimTitle}
                                        >
                                            <Typography variant="h2">
                                                PURETÉ :
                                            </Typography>
                                        </Box>
                                        <Box
                                            className={sStyles.profileDimValue}
                                        >
                                            <Typography variant="h3">
                                                {
                                                    normalizedProfileValues.PURITY.filter(
                                                        (item) =>
                                                            item.value ===
                                                            hairProfile.PURITY
                                                    )[0].label
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {
                                    <MainCleanCTA onClick={props.goToRoutines}>
                                        <Typography variant="h2">
                                            VOIR LES ROUTINES
                                        </Typography>
                                    </MainCleanCTA>
                                }
                            </Box>
                            <Box
                                className={
                                    sStyles.strengthFullLegendTitleHolder
                                }
                            >
                                <Typography variant="h2">
                                    Et si jamais tu veux refaire le
                                    questionnaire, c'est par ici...
                                </Typography>
                            </Box>
                            <Box className={sStyles.actionBar}>
                                <CleanLink onClick={props.restartProfile}>
                                    <Typography variant="h2">
                                        Recalculer mon profil
                                    </Typography>
                                </CleanLink>
                            </Box>
                        </Box>
                    </Box>
                    {legend.length > 0 && <ResultCard legend={legend} />}
                </Box>
            )}
        </>
    );
}

/* <Box className={sStyles.actionBar}>
                <MainCleanCTA onClick={props.goBack}>
                    <Typography variant="h3">Retour</Typography>
                </MainCleanCTA>
            </Box>*/
