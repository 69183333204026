import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import Dialog from "@mui/material/Dialog";
import AuthActionsWrapper from "./AuthActionsWrapper";
import specificStyles from "./AuthActions.module.css";
import { Box } from "@mui/material";
import SignOut from "../auth/SignOut";
import ResetPassword from "../auth/ResetPassword";

export default function ConnexionForm(props) {
    const [isSignUp, setIsSignUp] = useState(true);
    const [isPasswordReset, setIsPasswordReset] = useState(false);

    const toggleSignUp = () => {
        setIsSignUp(!isSignUp);
    };

    const toggleResetPwd = () => {
        setIsPasswordReset(!isPasswordReset);
    };

    console.log(props);

    return (
        <>
            {isSignUp ? (
                <SignUp
                    button={props.button ? props.button : null}
                    intro={props.intro ? props.intro : null}
                    toggleSignUp={toggleSignUp}
                />
            ) : isPasswordReset ? (
                <ResetPassword toggleResetPwd={toggleResetPwd} />
            ) : (
                <SignIn
                    toggleSignUp={toggleSignUp}
                    toggleResetPwd={toggleResetPwd}
                />
            )}
        </>
    );
}
