
import React from 'react'

import Paywall from '../../subscriptionHandler/Paywall/Paywall'
import Footer from '../home/components/Footer'
import { useSelector } from 'react-redux'

export default function Offers() {
    const {mireilleUser} = useSelector((state) => state.account)

    return (
        <>
            <Paywall 
                displayOfferMireille={true} 
                displayHeader = {false} 
                displayOffers={true} 
                forceConnexion={false} 
                onSuccessUrl="/account" 
                onCancelUrl="/offres"
                payWallTitle="Voici les formules pour avoir accès à Mireille. Tu peux aussi offrir un accès à quelqu'un d'autre"
                payWallOffersTitle={mireilleUser !== null && mireilleUser.SUBSCRIPTION_STATUS==="SUBSCRIBED" ? "Voici les options pour offir Mireille" : "Voici tous les moyens d'acheter ou d'offrir un abonnement à Mireille"}/>
        </>

    )
}