import React, { useEffect, useState } from "react";
import specificStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function StandardAnswer(props) {
    const toggleAnswer = () => {
        props.toggleAnswer(props.answer);
    };
    return (
        <Box
            className={specificStyles.standardAnswer}
            style={{
                border: props.selected
                    ? " 1pt solid var(--mireille-purple)"
                    : "1pt solid var(--mireille-purple-opacified)"
            }}
            onClick={toggleAnswer}
        >
            <Typography variant="body1">{props.answer.label}</Typography>
        </Box>
    );
}
