import React from "react";
import * as PARAMETERS from "../../constants/parameters";
import specificStyles from "./Auth.module.css";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { StandardCleanCTA } from "../MireilleCTAS";
import { Typography } from "@mui/material";
import { getAuth, signOut } from "firebase/auth";

export default function SignOut(props) {
    const dispatch = useDispatch();

    const disconnectUser = () => {
        signOut(getAuth());
        dispatch({
            type: PARAMETERS.SET_CONNEXION_STATUS,
            payload: "Disconnected"
        });
    };

    return (
        <>
            <Box className={specificStyles.ctaHolder}>
                <StandardCleanCTA onClick={disconnectUser}>
                    <Typography variant="h3">Me déconnecter</Typography>
                </StandardCleanCTA>
            </Box>
        </>
    );
}
//<SignUp toggleSignUp={toggleSignUp}/>

//First step retreive the auth status
//1 If connected check subscription
//1.1 if subscribed -> goTo Home
//1.2 if not subscribed -> suggest subscription
//2 Else suggest connexion / account creation
//when connected back to 1
