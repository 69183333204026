import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";

export default function RoutineSearchFilter(props) {
    const [filter, setFilter] = useState(props.filter);

    useEffect(() => {
        setFilter(props.filter);
        //on init load all the routine templates without any filter
    }, [props]);

    return (
        <Box>
            <Typography>{props.criterion.filterLabel}</Typography>
            <ToggleButtonGroup
                value={filter !== null ? [filter] : []}
                onChange={(event, newFilters) =>
                    props.handleFilter(
                        event,
                        newFilters,
                        filter,
                        props.criterion.filterKey
                    )
                }
            >
                {props.criterion.filterOptions.map((option) => {
                    return (
                        <ToggleButton value={option.value}>
                            {option.label}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        </Box>
    );
}
