import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import specificStyles from "../Profile.module.css";
import { isMobile } from "react-device-detect";
//import * as agCharts from "ag-charts-community";
//import { AgChartsReact } from "ag-charts-react";
//import { Typography } from "@mui/material";
//import { Chart } from "@eunchurn/windrose-chart";
//import { Circle } from "draw-shape-reactjs";
//import Svg, { Path } from "react-native-svg";

export default function GraphQuadrant(props) {
    const ratio = isMobile ? 0.8 : 1;
    const figureSize = ratio * 250;
    const separatorWidth = 8;
    const quadrantQuantity = 5;
    const baseRadius = ratio * 20;
    const levelAmount = 4;
    var absoluteOrigin = { x: figureSize / 2, y: figureSize / 2 };
    const fullColor = "white";
    const emptyColor = "rgba(255,255,255, 0.2)";

    const baseAngle = (2 * Math.PI) / quadrantQuantity;
    let centerCircleRadius = Math.sqrt(
        Math.pow(separatorWidth, 2) / (1 - Math.pow(Math.cos(baseAngle), 2))
    );
    let portionWidth = makePortionWidthFromDims(
        figureSize,
        centerCircleRadius,
        baseAngle,
        baseRadius,
        separatorWidth,
        levelAmount
    );

    const [spiderWeb, setSpiderWeb] = useState(null);

    useEffect(() => {
        makeSpiderWeb();
    }, []);
    //what do i nedd in props ?
    //quadrant index

    const makeSpiderWeb = () => {
        var daWeb = [];
        var root = makeRootFromQuadrant(null);
        for (let index = 0; index < quadrantQuantity; index++) {
            var quadrant = makeQuadrantFromIndex(index, root);
            var quad = {};
            root = makeRootFromQuadrant(quadrant);
            var quadrantTrans = {
                y: centerCircleRadius * Math.cos(baseAngle * (index + 1 / 2)),
                x: -centerCircleRadius * Math.sin(baseAngle * (index + 1 / 2))
            };
            quad.index = index;
            quad.image = quadrant;

            quad.translate = quadrantTrans;
            /*translateQuadrant(
                quadrant,
                index,
                baseAngle,
                separatorWidth
            );*/
            daWeb.push(quad);
        }
        setSpiderWeb(daWeb);
    };

    const makeQuadrantFromIndex = (quadIndex, root) => {
        var quad = { levels: [] };
        quad.index = quadIndex;
        for (let index = 0; index < levelAmount; index++) {
            var level = makeQuadrantLevel(
                index,
                root.filter((level) => level.index === index)[0]
            );
            quad.levels.push(level);
        }
        return quad;
    };

    const makeQuadrantLevel = (index, rootLevel) => {
        var level = {};
        level.index = index;
        level.innerRadius =
            baseRadius + index * portionWidth + index * separatorWidth;
        level.outerRadius =
            baseRadius + (index + 1) * portionWidth + index * separatorWidth;
        level.startInner = rootLevel.startInner;
        level.startOuter = rootLevel.startOuter;
        level.endInner = imageFromRotation(
            absoluteOrigin,
            level.startInner,
            baseAngle
        );
        level.endOuter = imageFromRotation(
            absoluteOrigin,
            level.startOuter,
            baseAngle
        );
        return level;
    };

    const makeRootFromQuadrant = (quad) => {
        var root = [];
        if (quad) {
            for (let index = 0; index < levelAmount; index++) {
                var level = {};
                level.startInner = quad.levels.filter(
                    (level) => level.index === index
                )[0].endInner;
                level.startOuter = quad.levels.filter(
                    (level) => level.index === index
                )[0].endOuter;
                level.index = index;
                root.push(level);
            }
        } else {
            for (let index = 0; index < levelAmount; index++) {
                var level = {};
                level.startInner = {
                    x: absoluteOrigin.x,
                    y:
                        absoluteOrigin.y +
                        baseRadius +
                        index * portionWidth +
                        index * separatorWidth
                };
                level.startOuter = {
                    x: absoluteOrigin.x,
                    y:
                        absoluteOrigin.y +
                        baseRadius +
                        (index + 1) * portionWidth +
                        index * separatorWidth
                };
                level.index = index;
                root.push(level);
            }
        }
        return root;
    };

    return (
        <Box className={specificStyles.quadrantLayout}>
            <svg
                width={figureSize + "pt"}
                height={figureSize + "pt"}
                viewBox={"0 0 " + figureSize + " " + figureSize}
                version="1.1"
            >
                <g id="arc">
                    {spiderWeb &&
                        spiderWeb.map((quadrant, index) => {
                            const strengthValue = props.strengthMatrix[index];
                            var strengthVect = [];
                            var rootIndex = index;
                            for (var i = 0; i <= strengthValue; i += 1) {
                                strengthVect.push(1);
                            }
                            return (
                                <React.Fragment key={index}>
                                    {quadrant.image.levels.map(
                                        (level, index) => {
                                            const strengthScalar =
                                                strengthVect[index];
                                            return (
                                                <path
                                                    key={rootIndex * 10 + index}
                                                    fill={
                                                        strengthScalar === 1
                                                            ? fullColor
                                                            : emptyColor
                                                    }
                                                    d={getSvgFromLevel(
                                                        level,
                                                        quadrant.translate
                                                    )}
                                                ></path>
                                            );
                                        }
                                    )}
                                </React.Fragment>
                            );
                        })}
                </g>
            </svg>
        </Box>
    );
}

const imageFromRotation = (pointCenter, pointObject, angleRot) => {
    const rotRadius = getVectNorm(pointCenter, pointObject);
    const centerObjectAngle = getAngleFromVector(pointCenter, pointObject);
    var pointImage = {};
    pointImage.x =
        pointCenter.x + rotRadius * Math.cos(angleRot + centerObjectAngle);
    pointImage.y =
        pointCenter.y + rotRadius * Math.sin(angleRot + centerObjectAngle);

    return pointImage;
};

const getVectNorm = (origin, object) => {
    const norm = Math.sqrt(
        Math.pow(object.x - origin.x, 2) + Math.pow(object.y - origin.y, 2)
    );
    return norm;
};

const getStandardVectNorm = (vect) => {
    const norm = Math.sqrt(Math.pow(vect.x, 2) + Math.pow(vect.y, 2));
    return norm;
};

const getAngleFromVector = (center, object) => {
    const rotRadius = getVectNorm(center, object);
    const cosAngle = (object.x - center.x) / rotRadius;
    const sinAngle = (object.y - center.y) / rotRadius;
    if (sinAngle > 0) {
        return Math.acos(cosAngle);
    }
    return 2 * Math.PI - Math.acos(cosAngle);
};

const makePortionWidthFromDims = (
    figureSize,
    centerCircleRadius,
    baseAngle,
    baseRadius,
    separatorWidth,
    levelAmount
) => {
    return (
        (figureSize / 2 -
            centerCircleRadius * Math.cos(baseAngle / 2) -
            baseRadius -
            (levelAmount - 1) * separatorWidth) /
            levelAmount -
        5
    );
};

//radius, radius, 0, largeArcFlag, 0,
const getSvgFromLevel = (level, translation) => {
    var raidusOffset = getStandardVectNorm(translation);
    var result = "M";
    result +=
        "" +
        (level.startInner.x + translation.x) +
        ", " +
        (level.startInner.y + translation.y) +
        " ";
    result +=
        "L" +
        (level.startOuter.x + translation.x) +
        ", " +
        (level.startOuter.y + translation.y) +
        " ";
    result +=
        "A" +
        (level.outerRadius + raidusOffset) +
        ", " +
        (level.outerRadius + raidusOffset) +
        ", 0, 0, 1, " +
        (level.endOuter.x + translation.x) +
        ", " +
        (level.endOuter.y + translation.y) +
        " ";
    result +=
        "L" +
        (level.endInner.x + translation.x) +
        ", " +
        (level.endInner.y + translation.y) +
        " ";
    result +=
        "A" +
        (level.innerRadius + raidusOffset) +
        ", " +
        (level.innerRadius + raidusOffset) +
        ", 0, 0, 0, " +
        (level.startInner.x + translation.x) +
        ", " +
        (level.startInner.y + translation.y) +
        " Z";
    return result;
};
