import React, { useEffect, useState } from "react";
import StandardAnswer from "../components/StandardAnswer";

export default function MultiChoiceFormPage(props) {
    const [selectedAnswers, setSelectedAnswers] = useState(props.answers);

    const toggleAnswer = (answer) => {
        props.toggleAnswer(answer, false);
    };

    useEffect(() => {
        setSelectedAnswers(props.answers);
    }, [props]);

    return (
        <>
            {props.step.answers.map((answer, index) => {
                return (
                    <StandardAnswer
                        key={index}
                        answer={answer}
                        toggleAnswer={toggleAnswer}
                        selected={selectedAnswers.indexOf(answer) > -1}
                    />
                );
            })}
        </>
    );
}
