import React from "react";
import specificStyles from "../Press.module.css";
import Footer from "../../home/components/Footer";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

export default function BlockHolder(props) {
    return (
        <Box className={specificStyles.BlockHolder}>
            <Box className={specificStyles.BlockHolderTitle}>
                <Typography variant="h4">{props.blockName}</Typography>
            </Box>
            {props.children}
        </Box>
    );
}
