import * as PARAMETER from "../../constants/parameters";

const defaultState = {
    firebaseUser: null,
    mireilleUser: null,
    connexionStatus: "Disconnected",
    stripeClientId: null,
    promoCode: null,
    stripeCheckoutSession: null,
    stripePortalSession: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case PARAMETER.SET_FIREBASE_USER:
            return {
                ...state,
                firebaseUser: action.payload
            };
        case PARAMETER.SET_MIREILLE_USER:
            if (action.payload !== null) {
                return {
                    ...state,
                    mireilleUser: action.payload,
                    connexionStatus: "Connected"
                };
            }

        case PARAMETER.SET_STRIPE_CLIENT_ID:
            return {
                ...state,
                stripeClientId: action.payload
            };
        case PARAMETER.STRIPE_CHECKOUT_SESSION:
            return {
                ...state,
                stripeCheckoutSession: action.payload
            };
        case PARAMETER.STRIPE_PORTAL_SESSION:
            return {
                ...state,
                stripePortalSession: action.payload
            };
        case PARAMETER.SET_PROMO_CODE:
            return {
                ...state,
                promoCode: action.payload
            };
        case PARAMETER.SET_CONNEXION_STATUS:
            if (action.payload === "Disconnected") {
                return {
                    firebaseUser: null,
                    mireilleUser: null,
                    connexionStatus: "Disconnected",
                    stripeClientId: null,
                    promoCode: null,
                    stripeCheckoutSession: null,
                    stripePortalSession: null
                };
            } else {
                return { ...state, connexionStatus: action.payload };
            }
        default:
            return state;
    }
};
