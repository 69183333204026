import React from "react";
import { Box } from "@mui/material";
import ProductForSale from "./components/ProductForSale";
import specificStyles from "./Paywall.module.css";

export default function MyOffers(props) {
    return (
        <>
            {(props.displayForMe || props.displayOfferMireille) && (
                <Box className={specificStyles.offersDisplayer}>
                    <ProductForSale
                        isSubscription={false}
                        monthAmount={4}
                        selectOffer={props.selectOffer}
                        displayForMe={props.displayForMe}
                        displayOfferMireille={props.displayOfferMireille}
                    />
                    {props.displayForMe && (
                        <ProductForSale
                            isSubscription={true}
                            monthAmount={0}
                            selectOffer={props.selectOffer}
                            displayForMe={props.displayForMe}
                            displayOfferMireille={false}
                        />
                    )}
                </Box>
            )}
        </>
    );
}

/*

 <ProductForSale isSubscription={false} monthAmount={7} selectOffer={props.selectOffer} displayForMe={props.displayForMe} displayOfferMireille={props.displayOfferMireille}/>
          <ProductForSale isSubscription={false} monthAmount={12} selectOffer={props.selectOffer} displayForMe={props.displayForMe} displayOfferMireille={props.displayOfferMireille}/>*/
