import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/stores/index";
import { initializeApp } from "firebase/app";
import { useEffect } from "react";
//import ReactPixel from "react-facebook-pixel";
import * as amplitude from "@amplitude/analytics-browser";

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false
};

var firebaseConfig = {
    apiKey: "AIzaSyA_Ms6xdHjnVp-JOiJTXemMKvTIzDFewpc",
    authDomain: "mireille-app.firebaseapp.com",
    databaseURL: "https://mireille-app.firebaseio.com",
    projectId: "mireille-app",
    storageBucket: "mireille-app.appspot.com",
    messagingSenderId: "968626365037",
    appId: "1:968626365037:web:2fad79886def453e89d564"
    /*,
    measurementId: "G-N8X0D7XGSL"*/
};

initializeApp(firebaseConfig);

//ReactPixel.init("331680132331014");
amplitude.init("8c7c7a90e76b0ff23f57274df5574c85");
//ReactPixel.revokeConsent();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
