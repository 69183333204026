import { isMobile } from "react-device-detect";

export const normalizedProfileValues = {
    POROSITY: [
        { value: 0, label: "Faible" },
        { value: 1, label: "Moyenne" },
        { value: 2, label: "Forte" }
    ],
    PURITY: [
        { value: 0, label: "Faible" },
        { value: 1, label: "Forte" }
    ],
    TEXTURE: [
        { value: "STRAIGHT", label: "Lisses" },
        { value: "WAVY", label: "Ondulés" },
        { value: "CURLY", label: "Bouclés" },
        { value: "FRIZZ", label: "Frisés" },
        { value: "KINKY", label: "Crépus" }
    ]
};

export const PROFILE_DECOR = [
    {
        key: "HYDRATATION",
        label: "HYDRATATION",

        decorIllus: "images/profile/strengthLegendIllus/hydratation.png",
        pic: "images/profile/hairStength/hydratation.png",
        dimWidth: (isMobile ? 0.8 : 1) * 16 + "pt",
        dimHeight: (isMobile ? 0.8 : 1) * 24 + "pt",
        dimMarginTop: (isMobile ? 0.8 : 1) * 15 + "pt",
        dimMarginLeft: (isMobile ? 0.8 : 1) * 159 + "pt",
        definition:
            "\n\nMême si les longueurs des cheveux sont une matière morte, ils ont besoin de contenir une certaine quantité d'eau.\n\nUn cheveu déshydraté est plus fragile et moins souple.\n\nLe cuir chevelu quand à lui est une peau qui a besoin d'hêtre correctement hydratée sinon elle pèle ce qui peut provoquer des péllicules et des démangeaisons."
    },
    {
        key: "NUTRITION",
        label: "NUTRITION",
        decorIllus: "images/profile/strengthLegendIllus/nutrition.png",
        pic: "images/profile/hairStength/nutrition.png",
        dimWidth: (isMobile ? 0.8 : 1) * 24 + "pt",
        dimHeight: (isMobile ? 0.8 : 1) * 24 + "pt",
        dimMarginTop: -(isMobile ? 0.8 : 1) * 5 + "pt",
        dimMarginLeft: (isMobile ? 0.8 : 1) * 35 + "pt",
        definition:
            "\n\nLe cheveu est lipophile, c'est à dire qu'il peut absorber les matières grasses comme les huiles végétales.\n\nUn cheveu bien nourri est brillant, souple et plus résistant."
    },
    {
        key: "PURITY",
        label: "PURETÉ",
        decorIllus: "images/profile/strengthLegendIllus/purity.png",
        pic: "images/profile/hairStength/purity.png",
        dimWidth: (isMobile ? 0.8 : 1) * 26 + "pt",
        dimHeight: (isMobile ? 0.8 : 1) * 22 + "pt",
        dimMarginTop: -(isMobile ? 0.8 : 1) * 5 + "pt",
        dimMarginLeft: (isMobile ? 0.8 : 1) * 207 + "pt",
        definition:
            "\n\nLes cheveux ont besoin d'être nettoyés. Pour Mireille, la pureté c'est avoir des cheveux et un cuir chevelu propres, sans toxines et sans excès de sebum.\n\nL'effet cheveux lourds qui accompagne l'usage de certains produits est un problème différent. Il s'agit généralement de produits trop riches en matières grasses par rapport à ta porosité."
    },
    {
        key: "DEFINITION",
        label: "DEFINITION",
        decorIllus: "images/profile/strengthLegendIllus/definition.png",
        pic: "images/profile/hairStength/definition.png",
        dimWidth: (isMobile ? 0.8 : 1) * 35 + "pt",
        dimHeight: (isMobile ? 0.8 : 1) * 17 + "pt",
        dimMarginTop: (isMobile ? 0.8 : 1) * 27 + "pt",
        dimMarginLeft: (isMobile ? 0.8 : 1) * 78 + "pt",
        definition:
            "\n\nLa définition c'est la capacité des cheveux à s'organiser en belles mèches cohérentes.\n\nPour améliorer la définition il faut un cheveu fort, bien nourri et hydraté, mais aussi le coiffer avec les bonnes techniques."
    },
    {
        key: "STRENGTH",
        label: "FORCE",
        decorIllus: "images/profile/strengthLegendIllus/strength.png",
        pic: "images/profile/hairStength/strength.png",
        dimWidth: (isMobile ? 0.8 : 1) * 30 + "pt",
        dimHeight: (isMobile ? 0.8 : 1) * 30 + "pt",
        dimMarginTop: (isMobile ? 0.8 : 1) * 27 + "pt",
        dimMarginLeft: (isMobile ? 0.8 : 1) * 122 + "pt",
        definition:
            "\n\nLa force c'est létat du cheveux. Généralement une longueur contient de nombreuses micro-fissures qui fragilisent le cheveu et finissent par le faire casser.\n\nPour renforcer un cheveu il faut lui apporter des protéines qui viennent combler les fissure et le consolider.\n\nIl peut du coup pousser sans se casser par la pointe."
    }
];

export const POROSITY_VALUES = [
    {
        value: 0,
        label: "faible",
        description:
            "ta porosité capillaire est faible, tes cheveux absorbent peu les soins"
    },
    {
        value: 1,
        label: "moyenne",
        description: "ta porosité capillaire est moyenne"
    },
    {
        value: 2,
        label: "forte",
        description:
            "ta porosité capillaire est forte, tes cheveux absorbent très bien les soins mais nécessitent un temps de sèchage long"
    }
];
export const SET_POROSITY = "SET_POROSITY";
export const TEXTURE_VALUES = [
    {
        value: 0,
        label: "lisses"
    },
    {
        value: 1,
        label: "ondulés"
    },
    {
        value: 2,
        label: "bouclés"
    },

    {
        value: 3,
        label: "frisés"
    },
    {
        value: 4,
        label: "crépus"
    }
];
export const SET_TEXTURE = "SET_TEXTURE";
export const RESET_FORM = "RESET_FORM";

const voteOptions = {
    porosity: {
        low: "LOW",
        medium: "MEDIUM",
        high: "HIGH"
    },
    texture: {
        straight: "STRAIGHT",
        wavy: "WAVY",
        curly: "CURLY",
        frizz: "FRIZZ",
        kinky: "KINKY"
    },
    levels: {
        low: "LEVEL_LOW",
        medium: "LEVEL_MEDIUM",
        high: "LEVEL_HIGH",
        grand: "LEVEL_GRAND"
    }
};

export const textureOptionValues = {
    STRAIGHT: {
        BASE_POROSITY: 6,
        BASE_DEFINITION: 24
    },
    WAVY: {
        BASE_POROSITY: 10,
        BASE_DEFINITION: 20
    },
    CURLY: {
        BASE_POROSITY: 12,
        BASE_DEFINITION: 14
    },
    FRIZZ: {
        BASE_POROSITY: 16,
        BASE_DEFINITION: 10
    },
    KINKY: {
        BASE_POROSITY: 20,
        BASE_DEFINITION: 6
    }
};

export const porosityOptionValues = [
    {
        VALUE: "LOW",
        MID_RANGE: 4,
        BASE_NUTRITION: 20,
        BASE_HYDRATATION: 20,
        BASE_STRENGTH: 20
    },
    {
        VALUE: "MEDIUM",
        MID_RANGE: 12,
        BASE_NUTRITION: 12,
        BASE_HYDRATATION: 12,
        BASE_STRENGTH: 12
    },
    {
        VALUE: "HIGH",
        MID_RANGE: 20,
        BASE_NUTRITION: 4,
        BASE_HYDRATATION: 4,
        BASE_STRENGTH: 4
    }
];

export const voteOptionsValues = {
    LEVEL_LOW: 3,
    LEVEL_MEDIUM: 9,
    LEVEL_HIGH: 15,
    LEVEL_GRAND: 21
};

export const scoreDescriptions = {
    STRENGTH: [
        {
            SCORE: 0,
            DESCRIPTION:
                "Pour renforcer tes cheveux il leur faut des prots (pour la prise de masse), prévoit beaucoup de soin protéinés dans ta routine"
        },
        {
            SCORE: 1,
            DESCRIPTION:
                "Pour renforcer tes cheveux il leur faut des prots (pour la prise de masse), prévoit de faire des soin protéinés réguliers dans ta routine"
        },
        {
            SCORE: 2,
            DESCRIPTION:
                "Tes cheveux sont forts, mais tu peux encore les renforcer en incluant des soins protéinés dans ta routine, ils pourront pousser sans se casser"
        },
        {
            SCORE: 3,
            DESCRIPTION:
                "Tes cheveux sont très forts, un petit soin protéiné dans ta routine de temps en temps et le tour est joué"
        }
    ],
    NUTRITION: [
        {
            SCORE: 0,
            DESCRIPTION:
                "Tes longueurs sont facilement affamées, il leur faut du gras pour affronter l'hiver. Et tu ne risques pas vraiment d'en mettre trop, de toutes façon elles vont tout manger"
        },
        {
            SCORE: 1,
            DESCRIPTION:
                "Tes longueurs on besoin de gras pour se nourrir, les soins nourissants et les bains d'huile sont tes amis"
        },
        {
            SCORE: 2,
            DESCRIPTION:
                "Tes longueurs sont faciles à nourrir, un peu de gras dans ta routine devrait suffire"
        },
        {
            SCORE: 3,
            DESCRIPTION:
                "Tes longueurs sont naturellement très bien nourris, un peu de gras dans ta routine devrait suffire"
        }
    ],
    HYDRATATION: [
        {
            SCORE: 0,
            DESCRIPTION:
                "Tes cheveux perdent facilement leur eau et sont facilement assoifés, ta routine doit impérativement contenir des soins hydratants réguliers"
        },
        {
            SCORE: 1,
            DESCRIPTION:
                "Tes cheveux ont facilement soif, ta routine doit insister sur l'hydratation"
        },
        {
            SCORE: 2,
            DESCRIPTION:
                "Tes cheveux sont naturellement correctement hydratés, un peu de soins hydratants dans ta routine et le tour est joué"
        },
        {
            SCORE: 3,
            DESCRIPTION:
                "Tes cheveux sont naturellement désaltérés en profondeur, un peu de soins hydratants dans ta routine et le tour est joué"
        }
    ],
    DEFINITION: [
        {
            SCORE: 0,
            DESCRIPTION:
                "Tes cheveux sont difficiles à coiffer,  ils ont besoin que tu prennes beaucoup de temps pour les coiffer comme tu veux"
        },
        {
            SCORE: 1,
            DESCRIPTION:
                "Tes cheveux sont plutôt difficiles à coiffer, ils ont besoin que tu prennes du temps pour les coiffer comme tu veux"
        },
        {
            SCORE: 2,
            DESCRIPTION:
                "Tes cheveux sont plutôt facile coiffer, avec les bonnes techniques ça devrait se passer simplement"
        },
        {
            SCORE: 3,
            DESCRIPTION:
                "Tes cheveux sont très facile coiffer, tu n'as besoin de travailler beaucoup sur ce point pour atteindre tes objectifs"
        }
    ],
    PURITY: [
        {
            SCORE: 0,
            DESCRIPTION:
                "Tes cheveux et ton cuir chevelu graissent très vite. Attention n'utilises pas un shampoing trop fort, ça agresse ton cuir chevelu et stimule la production de sébum. Essaye plutôt de les laver deux fois par semaine avec un shampoing doux et réhydrate ton cuir chevelu après pour l'apaiser."
        },
        {
            SCORE: 1,
            DESCRIPTION:
                "Tes cheveux et ton cuir chevelu graissent vite. Attention n'utilises pas un shampoing trop fort, ça agresse ton cuir chevelu et stimule la production de sébum. Essaye plutôt de les laver deux fois par semaine avec un shampoing doux et réhydrate ton cuir chevelu après pour l'apaiser."
        },
        {
            SCORE: 2,
            DESCRIPTION:
                "Tes cheveux et ton cuir chevelu sont sains. Un lavage par semaine avec un shampoing doux devrait suffire pour les garder propres"
        },
        {
            SCORE: 3,
            DESCRIPTION:
                "Tes cheveux et ton cuir chevelu sont très sains. Un lavage par semaine avec un shampoing doux devrait suffire pour les garder propres"
        }
    ]
};

export const scoreDefinitions = {
    STRENGTH: "",
    NUTRITION: "",
    HYDRATATION: "",
    DEFINITION: "",
    PURITY: ""
};

export const profileSteps = [
    {
        keyLabel: "HAIR_TEXTURE",
        template: "HAIR_TEXTURE",
        label: "En termes de texture, comment sont tes cheveux ?",
        answers: [
            {
                keyLabel: "STRAIGHT",
                label: "Lisses",
                pic: "images/profile/hairTextures/straight.png",
                scores: [
                    { flag: "TEXTURE", vote: voteOptions.texture.straight }
                ]
            },
            {
                keyLabel: "WAVY",
                label: "Ondulés",
                pic: "images/profile/hairTextures/wavy.png",
                scores: [{ flag: "TEXTURE", vote: voteOptions.texture.wavy }]
            },
            {
                keyLabel: "CURLY",
                label: "Bouclés",
                pic: "images/profile/hairTextures/curly.png",
                scores: [{ flag: "TEXTURE", vote: voteOptions.texture.curly }]
            },
            {
                keyLabel: "FRIZZ",
                label: "Frisés",
                pic: "images/profile/hairTextures/frizz.png",
                scores: [{ flag: "TEXTURE", vote: voteOptions.texture.frizz }]
            },
            {
                keyLabel: "KINKY",
                label: "Crépus",
                pic: "images/profile/hairTextures/kinky.png",
                scores: [{ flag: "TEXTURE", vote: voteOptions.texture.kinky }]
            }
        ],
        validation: {
            bypass: false
        }
    },
    /*{
        keyLabel: "HAIR_THICKNESS",
        template: "SINGLE_CHOICE",
        label: "Combien de tours de chouchou fais tu quand tu attaches tes cheveux ?",
        answers: [
            {
                keyLabel: "THICK",
                label: "un seul",
                scores: [{ flag: "QUANTITY", vote: voteOptions.levels.high }]
            },
            {
                keyLabel: "BALANCE",
                label: "Deux tours",
                scores: [{ flag: "QUANTITY", vote: voteOptions.levels.medium }]
            },
            {
                keyLabel: "THIN",
                label: "Au moins trois",
                scores: [
                    {
                        flag: "QUANTITY",
         voteAmount:  1,
                        vote: voteOptions.levels.low
                    }
                ]
            }
        ],
        validation: {
            bypassLabel: "JE N'ATTACHE PAS MES CHEVEUX",
            bypass: true
        }
    },*/
    /*{
        keyLabel: "HAIR_LENGTH",
        template: "SINGLE_CHOICE",
        label: "Et niveau longueur ça donne quoi ?",
        answers: [
            {
                keyLabel: "ZERO",
                label: "Boule à Z",
                scores: [
                    {
                        flag: "QUANTITY",
         voteAmount:  1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "SHORT_PLUS",
                label: "Très courts",
                scores: [
                    {
                        flag: "QUANTITY",
         voteAmount:  1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            { keyLabel: "SHORTS", label: "Courts" },
            {
                keyLabel: "SHORT_HALF",
                label: "Mi-longs",
                scores: [{ flag: "QUANTITY", vote: voteOptions.levels.medium }]
            },
            {
                keyLabel: "LONG",
                label: "Longs",
                scores: [
                    {
                        flag: "QUANTITY",
         voteAmount:  1,
                        vote: voteOptions.levels.high
                    }
                ]
            },
            {
                keyLabel: "LONG_PLUS",
                label: "Très Longs",
                scores: [{ flag: "QUANTITY", vote: voteOptions.levels.grand }]
            }
        ],
        validation: {
            bypass: false
        }
    },*/
    {
        keyLabel: "HAIR_HISTORY",
        template: "MULTI_CHOICE",
        label: "Qu'as tu fait à tes cheveux au cours des 6 derniers mois ?",
        subLabel: "Plusieurs réponses possibles :",
        answers: [
            {
                keyLabel: "COLOR",
                label: "Coloration",
                scores: [
                    {
                        flag: "VIRTUAL_POROSITY",
                        voteAmount: 2,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "UNCOLOR",
                label: "Décoloration",
                scores: [
                    {
                        flag: "VIRTUAL_POROSITY",
                        voteAmount: 3,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "COLOR_N",
                label: "Henné ou henné neutre",
                scores: [
                    {
                        flag: "HYDRATATION",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    },
                    {
                        flag: "NUTRITION",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    },
                    {
                        flag: "STRENGTH",
                        voteAmount: 2,
                        vote: voteOptions.levels.high
                    }
                ]
            },
            {
                keyLabel: "B_STRAIGHTENER",
                label: "Lissage brésilien",
                scores: [
                    {
                        flag: "VIRTUAL_POROSITY",
                        voteAmount: 2,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "STAIGHTENER",
                label: "Défrisage",
                scores: [
                    {
                        flag: "VIRTUAL_POROSITY",
                        voteAmount: 4,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "BRUSHING",
                label: "Brushings réguliers",
                scores: [
                    {
                        flag: "VIRTUAL_POROSITY",
                        voteAmount: 1,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "TANIN",
                label: "Lissage au tanin",
                scores: [
                    {
                        flag: "VIRTUAL_POROSITY",
                        voteAmount: 2,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "BOTOX",
                label: "Botox capillaire",
                scores: [
                    {
                        flag: "VIRTUAL_POROSITY",
                        voteAmount: 2,
                        vote: voteOptions.levels.grand
                    }
                ]
            }
        ],
        validation: {
            bypassLabel: "RIEN DE TOUT ÇA",
            bypass: true
        }
    },
    {
        keyLabel: "SCALP_ISSUES",
        template: "MULTI_CHOICE",
        label: "Parles nous de ton cuir chevelu...",
        subLabel: "Plusieurs réponses possibles :",
        validation: {
            bypassLabel: "TOUT VA BIEN",
            bypass: true
        },
        answers: [
            {
                keyLabel: "FLAKES",
                label: "Pellicules",
                scores: [
                    {
                        flag: "HYDRATATION",
                        voteAmount: 2,
                        vote: voteOptions.levels.low
                    },
                    {
                        flag: "NUTRITION",
                        voteAmount: 2,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "DRY",
                label: "Sécheresse",
                scores: [
                    {
                        flag: "HYDRATATION",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    },
                    {
                        flag: "NUTRITION",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "FAT",
                label: "Racines grasses",
                scores: [
                    {
                        flag: "PURITY",
                        voteAmount: 2,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "SCRATCH",
                label: "Démangeaisons",
                scores: [
                    {
                        flag: "HYDRATATION",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "LOW_VOLUME",
                label: "Peu de volume",
                scores: [
                    {
                        flag: "PURITY",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "HIGH_VOLUME",
                label: "Trop de volume",
                scores: [
                    {
                        flag: "DEFINITION",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "FALL",
                label: "Chutes de cheveux",
                scores: [
                    {
                        flag: "ALERT",
                        message:
                            "La solution ne se trouve pas dans la routine, mais dans le mode de vie"
                    }
                ]
            }
        ]
    },
    {
        keyLabel: "FAT_SPEED",
        template: "SINGLE_CHOICE",
        label: "Sans lavage, tes cheveux sont gras en...",
        validation: {
            bypassLabel: "JE NE SAIS PAS",
            bypass: true
        },
        answers: [
            {
                keyLabel: "VERY_FAST_FAT",
                label: "moins de 3 jours",
                scores: [
                    {
                        flag: "PURITY",
                        voteAmount: 4,
                        vote: voteOptions.levels.low
                    }
                ]
            },

            {
                keyLabel: "FAST_FAT",
                label: "entre 3 et 5 jours",
                scores: [
                    {
                        flag: "PURITY",
                        voteAmount: 4,
                        vote: voteOptions.levels.medium
                    }
                ]
            },
            {
                keyLabel: "REGULAR_FAT",
                label: "entre 5 et 7 jours",
                scores: [
                    {
                        flag: "PURITY",
                        voteAmount: 4,
                        vote: voteOptions.levels.high
                    }
                ]
            },
            {
                keyLabel: "SLOW_FAT",
                label: "Plus de 7 jours",
                scores: [
                    {
                        flag: "PURITY",
                        voteAmount: 4,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "VERY_SLOW_FAT",
                label: "Ils ne graissent pas",
                scores: [
                    {
                        flag: "PURITY",
                        voteAmount: 4,
                        vote: voteOptions.levels.grand
                    }
                ]
            }
        ]
    },
    {
        keyLabel: "HAIR_ISSUES",
        template: "MULTI_CHOICE",
        label: "Maintenant parlons de tes longueurs. Comment vont-elles ?",
        subLabel: "Plusieurs réponses possibles :",

        answers: [
            {
                keyLabel: "DEFINITION",
                label: "Boucles mal définies",
                scores: [
                    {
                        flag: "DEFINITION",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "DAMAGED",
                label: "Fourchues",
                scores: [
                    {
                        flag: "STRENGTH",
                        voteAmount: 2,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "HEAVY",
                label: "Poisseuses",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "DRY",
                label: "Sèches",
                scores: [
                    {
                        flag: "NUTRITION",
                        voteAmount: 1,
                        vote: voteOptions.levels.medium
                    },
                    {
                        flag: "HYDRATATION",
                        voteAmount: 1,
                        vote: voteOptions.levels.medium
                    }
                ]
            },
            {
                keyLabel: "DULL",
                label: "Ternes",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 2,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "FOAMY",
                label: "Mousseuses",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 2,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "FRAGILE",
                label: "Cassantes",
                scores: [
                    {
                        flag: "STRENGTH",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "ELASTIC",
                label: "Elastiques (chewing gum)",
                scores: [
                    {
                        flag: "ALERT",
                        message:
                            "Il est trop tard pour tes longueurs, elle ne sont plus réccupérable. Il va falloir couper et attendre les repousses"
                    }
                ]
            }
        ],
        validation: {
            bypassLabel: "TOUT VA BIEN",
            bypass: true
        }
    },
    {
        keyLabel: "UNTANGLE",
        template: "MULTI_CHOICE",
        label: "Qu'est ce que tu peux nous dire sur le démêlage ?",
        subLabel: "Plusieurs réponses possibles :",

        answers: [
            {
                keyLabel: "LONG",
                label: "C'est très long",
                scores: [
                    {
                        flag: "STRENGTH",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "HARD",
                label: "C'est très dur",
                scores: [
                    {
                        flag: "STRENGTH",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "FALL",
                label: "J'y laisse des cheveux",
                scores: [
                    {
                        flag: "STRENGTH",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "FALL_PLUS",
                label: "J'y laisse plein de cheveux",
                scores: [
                    {
                        flag: "STRENGTH",
                        voteAmount: 2,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "FREQUENCY",
                label: "Je dois les démêler tout le temps",
                scores: [
                    {
                        flag: "STRENGTH",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            }
        ],
        validation: {
            bypassLabel: "RIEN A SIGNLAER",
            bypass: true
        }
    },
    /* {
        keyLabel: "HEALTH_ISSUES",
        template: "MULTI_CHOICE",
        label: "Parle nous un peu de ta situation de santé (ces données ne seront pas enregistrées).",
        subLabel: "Plusieurs réponses possibles :",
        validation: {
            bypassLabel: "RIEN DE TOUT ÇA",
            bypass: true
        },
        answers: [
            { keyLabel: "PREGNANT", label: "Je suis enceinte" },
            { keyLabel: "LACTATING", label: "J'allaite" },
            {
                keyLabel: "CHRONIC_DISEASE",
                label: "J'ai une maladie chronique"
            },
            { keyLabel: "ALLERGIES", label: "J'ai des allergies" },
            { keyLabel: "ALLERGIES_PLUS", label: "J'ai beaucoup d'allergies" }
        ]
    },*/
    {
        keyLabel: "DRY_DURATION",
        template: "SINGLE_CHOICE",
        label: "À quelle vitesse tes cheveux sèchent-t-il à l'air libre ?",
        answers: [
            {
                keyLabel: "SLOW_PLUS",
                label: "Terriblement lentement",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 4,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "SLOW",
                label: "Plutôt lentement",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 2,
                        vote: voteOptions.levels.high
                    }
                ]
            },
            {
                keyLabel: "REGULAR",
                label: "Vitesse normale... je crois",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 2,
                        vote: voteOptions.levels.medium
                    }
                ]
            },
            {
                keyLabel: "FAST",
                label: "À la vitesse de l'éclair",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 4,
                        vote: voteOptions.levels.low
                    }
                ]
            }
        ],
        validation: {
            bypassLabel: "JE NE SAIS PAS",
            bypass: false
        }
    },
    {
        keyLabel: "OIL_IMPACT",
        template: "SINGLE_CHOICE",
        label: "Comment tes cheveux réagissent à un bain d'huile (sans lavage juste après) ?",
        answers: [
            {
                keyLabel: "GREAT",
                label: "Ils ont tout bu",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 4,
                        vote: voteOptions.levels.grand
                    }
                ]
            },
            {
                keyLabel: "GOOD",
                label: "J'ai besoin d'un lavage",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 2,
                        vote: voteOptions.levels.medium
                    }
                ]
            },
            {
                keyLabel: "HEAVY",
                label: "Même un lavage ne suffit pas",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 4,
                        vote: voteOptions.levels.low
                    }
                ]
            }
        ],
        validation: {
            bypassLabel: "JE NE SAIS PAS",
            bypass: true
        }
    },
    {
        keyLabel: "PRODUCT_QUANTITY",
        template: "SINGLE_CHOICE",
        label: "Quand tu utilises un leave-in tu en mets quelle quantité (exprimée en fruits à coque) ?",
        answers: [
            {
                keyLabel: "NUT_MINUS",
                label: "Une mini-noisette",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "NUT",
                label: "Une noisette",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 1,
                        vote: voteOptions.levels.low
                    }
                ]
            },
            {
                keyLabel: "NUTS",
                label: "Quelques noisettes",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 1,
                        vote: voteOptions.levels.medium
                    }
                ]
            },
            {
                keyLabel: "WALNUTS",
                label: "Plusieurs noix",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 1,
                        vote: voteOptions.levels.high
                    }
                ]
            },
            {
                keyLabel: "COCONUT",
                label: "Une noix de coco",
                scores: [
                    {
                        flag: "POROSITY_HINT",
                        voteAmount: 1,
                        vote: voteOptions.levels.grand
                    }
                ]
            }
        ],
        validation: {
            bypassLabel: "JE NE SAIS PAS",
            bypass: true
        }
    },
    {
        keyLabel: "GOAL",
        template: "SINGLE_CHOICE",
        label: "Quel est ton objectif capillaire principal du moment ?",
        answers: [
            { keyLabel: "DEFINITION", label: "La définition de mes boucles" },
            { keyLabel: "VOLUME", label: "Avoir plus de volume" },
            { keyLabel: "GROWTH", label: "Accélérer la pousse" },
            {
                keyLabel: "HAIR_LENGTH",
                label: "Régler mes problèmes de longueurs"
            },
            { keyLabel: "SCALP", label: "Régler mes problèmes de racines" }
        ],
        validation: {
            bypass: false
        }
    }
];

export const routineSteps = [
    {
        keyLabel: "WASH_FREQUENCY",
        template: "SINGLE_CHOICE",
        label: "À quelle fréquence laves-tu tes cheveux ?",
        answers: [
            { keyLabel: "MONTH1", label: "1 fois / mois" },
            { keyLabel: "MONTH2", label: "2 fois / mois" },
            { keyLabel: "WEEK1", label: "1 fois / semaine" },
            { keyLabel: "WEEK2", label: "2 fois / semaine" },
            { keyLabel: "WEEK2PLUS", label: "+2 fois / semaine" }
        ],
        validation: {
            bypass: false
        }
    },
    {
        keyLabel: "CARE_FREQUENCY",
        template: "SINGLE_CHOICE",
        label: "À quelle fréquence fais-tu des soins ?",
        answers: [
            { keyLabel: "MONTH1", label: "1 fois / mois" },
            { keyLabel: "MONTH2", label: "2 fois / mois" },
            { keyLabel: "WEEK1", label: "1 fois / semaine" },
            { keyLabel: "SOMETIMES", label: "Parfois" },
            { keyLabel: "NEVER", label: "Jamais" }
        ],
        validation: {
            bypass: false
        }
    }
];
