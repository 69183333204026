import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { StandardCleanCTA, MainCleanCTA } from "../../../MireilleCTAS";
import specificStyles from "../Paywall.module.css";

export default function ProductForSale(props) {
    var [offerTitle, setOfferTitle] = useState("");
    var [offerDescription, setOfferDescription] = useState("");
    var [offerPricing, setOfferPricing] = useState("");
    var [offerMonthlyPricing, setOfferMonthlyPricing] = useState("");
    var [illus, setIllus] = useState("");

    const initiateSubscription = (forMe) => {
        var params = {};
        params.MAKE_PURCHASE = true;
        params.SUBSCRIPTION = props.isSubscription;
        params.MONTH_AMOUNT = props.monthAmount;
        params.FOR_ME = forMe;
        props.selectOffer(params);
    };

    useEffect(() => {
        makeOfferDisplay();
    }, []);

    const makeOfferDisplay = () => {
        if (props.isSubscription) {
            setOfferTitle("Abonnement annuel 💜");
            setOfferPricing("19,99€ par an soit :");
            setOfferMonthlyPricing("1,67€ ");
            setOfferDescription(
                "Un abonnement annuel, résiliable à tout moment. La période entamée est facturée intégralement."
            );
            setIllus("images/offers/lvl4.png");
        } else {
            if (props.monthAmount === 4) {
                setOfferTitle("Pass routine 🐛 ➡ 🦋");
                setOfferPricing("4 mois pour 15€ soit :");
                setOfferMonthlyPricing("3,75€ ");
                setOfferDescription(
                    "Un Pass de 4 mois, pour découvrir ta routine parfaite !"
                );
                setIllus("images/offers/lvl3.png");
            } /*else if (props.monthAmount === 7) {
                setOfferTitle("Pass transition 🐛 ➡ 🦋");
                setOfferPricing("7 mois pour 14€ soit :");
                setOfferMonthlyPricing("2€ ");
                setOfferDescription(
                    "Un Pass de 7 mois, le temps d'effectuer ta transition et mettre en place tes nouvelles routines !"
                );
                setIllus("images/offers/lvl3.png");
            } else if (props.monthAmount === 12) {
                setOfferTitle("Pass annuel ❤️");
                setOfferPricing("1 an pour 19,99€ soit :");
                setOfferMonthlyPricing("1,67€ ");
                setOfferDescription(
                    "Un Pass d'un an, comme l'abonnement mais sans reconduction automatique !"
                );
                setIllus("images/offers/lvl3.png");
            }*/
        }
    };

    return (
        <Box className={specificStyles.salesBlock}>
            <img src={illus} />
            <Box className={specificStyles.HomeContentTitleHolder}>
                <Typography
                    variant="h5"
                    className={specificStyles.HomeContentTitlePurple}
                >
                    {offerTitle}
                </Typography>
            </Box>

            <Box className={specificStyles.PricingHolder}>
                <Typography
                    variant="body1"
                    className={specificStyles.HomeContentPurple}
                >
                    {offerPricing}
                </Typography>
            </Box>
            <Box className={specificStyles.MonthlyPricingHolder}>
                <Typography variant="h6" inline>
                    {offerMonthlyPricing}
                </Typography>
                <Box className={specificStyles.PerMonthZone}>
                    <Typography variant="h3" inline>
                        /mois
                    </Typography>
                </Box>
            </Box>
            <Box className={specificStyles.HomeContentHolder}>
                <Typography
                    variant="body1"
                    className={specificStyles.offerDescription}
                >
                    {offerDescription}
                </Typography>
            </Box>

            {props.displayOfferMireille && (
                <Box className={specificStyles.salesCTAHolder}>
                    <StandardCleanCTA
                        onClick={() => initiateSubscription(false)}
                    >
                        <Typography variant="h3">Pour offrir 🎁</Typography>
                    </StandardCleanCTA>
                </Box>
            )}
            {props.displayForMe && (
                <Box className={specificStyles.salesCTAHolder}>
                    <MainCleanCTA onClick={() => initiateSubscription(true)}>
                        <Typography variant="h3">Pour moi</Typography>
                    </MainCleanCTA>
                </Box>
            )}
        </Box>
    );
}
