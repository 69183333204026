import React from "react";
import Dialog from "@mui/material/Dialog";
import sStyles from "../Profile.module.css";
import { Box, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

export default function DisclaimerPrompt(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            PaperProps={{
                style: { borderRadius: (isMobile ? 0.8 : 1) * 25 + "pt" }
            }}
        >
            <Box className={sStyles.profileCtaCard}>
                <Box className={sStyles.strengthFullLegendTitleHolder}>
                    <Typography variant="h5">Ça arrive bientôt 💜</Typography>
                </Box>

                <Box className={sStyles.profileRecap}>
                    <Typography variant="body2">
                        Je fais le maximum pour sortir les premières routines au
                        plus vite, c'est une question de jours !
                    </Typography>
                    <Typography variant="body2">Siegfried</Typography>
                </Box>
            </Box>
        </Dialog>
    );
}
