import React, { useEffect, useState } from "react";
import sStyles from "../Routines.module.css";
import { Box } from "@mui/system";
import {
    getRoutinesByDeepLinkUrl,
    standardHeaders
} from "../../../../constants/settings";
import RoutineDisplay from "../components/RoutineDisplay";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ConnexionPopin from "../../../subscriptionHandler/ConnexionPopin";
//import { pushPageView } from "../../../utils/gtmHandler";

export default function RoutineLandingPage(props) {
    const { mireilleUser, connexionStatus } = useSelector(
        (state) => state.account
    );
    const [routine, setRoutine] = useState(null);
    const [connect, isConnect] = useState(false);
    const navigate = useNavigate();

    /* useEffect(() => {
        console.log(props);
        if (props.deepLink) {
            loadRoutineByDeepLink(props.deepLink);
        }
    }, [props]);*/

    const loadRoutineByDeepLink = (deepLink) => {
        fetch(getRoutinesByDeepLinkUrl, {
            headers: standardHeaders,
            method: "POST",
            body: JSON.stringify({ DEEP_LINK: deepLink })
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.STATUS == "Ok") {
                    setRoutine(data.ROUTINE.ROUTINE);
                } else {
                    goToDiag();
                }
            })
            .catch((errorData) => {
                goToDiag();
            });
    };

    const goToDiag = () => {
        isConnect(false);
        setRoutine(null);
        navigate("/profile", { replace: true });
    };

    /*useEffect(() => {
        pushPageView("routine_" + props.deepLink);
    }, []);*/

    useEffect(() => {
        if (mireilleUser !== null) {
            isConnect(false);
            if (props.deepLink) {
                loadRoutineByDeepLink(props.deepLink);
            }
        } else {
            isConnect(true);
        }
    }, [mireilleUser]);

    return (
        <Box className={sStyles.simpleLayout}>
            {routine && (
                <RoutineDisplay
                    routine={routine}
                    handleClose={goToDiag}
                    redirect={"routine_" + props.deepLink}
                />
            )}
            {connect && (
                <ConnexionPopin
                    isOpen={connect}
                    canClose={false}
                    handleClose={() => {
                        goToDiag();
                    }}
                    overrideInviteCreate={
                        "et découvre cette fantastique routine capillaire"
                    }
                />
            )}
        </Box>
    );
}
