import React, { useState } from "react";
import FrFR from "../../../constants/localisations/fr-Fr";
import { isMobile } from "react-device-detect";
import { ReactComponent as Illustration } from "../../../assets/svgs/trad-illus.svg";
import specificStyles from "./Trad.module.css";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import IngredientRow from "./components/IngredientRow";
import IngredientDetailModal from "./components/IngredientDetailModal";
import { StandardCleanCTA, MainCleanCTA } from "../../MireilleCTAS";
import { Typography } from "@mui/material";

function MireilleTrad() {
    const [toTrad, setToTrad] = useState("");
    const [processedCompo, setProcessedCompo] = useState(null);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [hasSelectedIngredient, setHasSelectedIngredient] = useState(false);

    const clearTradField = () => {
        setToTrad("");
    };

    const launchTrad = () => {
        if (toTrad.length > 4) {
            const evalCompoUrl =
                "https://compo.processor.cosmetichacker.biz:8822/process_crude_compo/";
            fetch(evalCompoUrl, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify(toTrad)
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.Status === "ok") {
                        setProcessedCompo(data.processedCompo);
                    }
                })
                .catch((errorData) => setProcessedCompo(null));
        }
    };

    const fillTradField = (event) => {
        event.preventDefault();
        setToTrad(event.target.value);
    };

    const selectIngredient = (ingredient) => {
        var myHeaders = new Headers();
        var raw = JSON.stringify({ NAME: ingredient.INGREDIENT_NAME });
        const url =
            "https://productapi.cosmetichacker.biz:8503/getIngredientCardByName/";
        myHeaders.append("Content-Type", "application/json");
        fetch(url, {
            method: "POST",
            headers: myHeaders,
            body: raw
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.Status === "Ok") {
                    const ingredientDetail = data.Document;
                    setSelectedIngredient(ingredientDetail);
                    setHasSelectedIngredient(true);
                } else {
                    setSelectedIngredient(null);
                    setHasSelectedIngredient(false);
                }
            })
            .catch((err) => {
                setSelectedIngredient(null);
                setHasSelectedIngredient(false);
            });
    };

    const deselectIngredient = () => {
        setSelectedIngredient(null);
        setHasSelectedIngredient(false);
    };

    const textFieldStyle = {
        borderRadius: "20pt",
        width: "313pt",
        maxWidth: "100%",
        textAlign: "top",
        minHeight: "200pt"
    };

    const placeHolder = FrFR.screen.translatescreen.PLACEHOLDER;
    return (
        <Paper className={specificStyles.scrollPaper}>
            <Box className={specificStyles.tradTitle}>
                <Typography variant="h4">
                    {FrFR.screen.translatescreen.TRANSLATE_TITLE}
                </Typography>
            </Box>

            <Illustration />

            <Box className={specificStyles.tradDescription}>
                <Typography variant="h3">
                    {FrFR.screen.translatescreen.TRANSLATE_DESC}
                </Typography>
            </Box>
            <Box className={specificStyles.tradInvite}>
                <Typography variant="h5">
                    {FrFR.screen.translatescreen.TRANSLATE_INVITE}
                </Typography>
            </Box>
            <Box sx={{ maxWidth: "90%" }}>
                <TextField
                    value={toTrad}
                    onChange={fillTradField}
                    multiline={true}
                    variant="filled"
                    sx={{
                        width: "313pt",
                        maxWidth: "100%",
                        textAlign: "top",
                        minHeight: "200pt",
                        background: "rgba(48, 19, 102, 0.05)",
                        borderRadius: "20pt",

                        "& .MuiInputBase-root": {
                            padding: "12pt",
                            backgroundColor: "transparent !important"
                        },
                        "& .MuiInputBase-input": {
                            color: "#301366",
                            fontSize: "1rem",
                            "@media (min-width:450px)": {
                                fontSize: "1.1rem"
                            },
                            "::-webkit-scrollbar": {
                                display: "none"
                            }
                        }
                    }}
                    className={specificStyles.inputElement}
                    rows={11}
                    placeholder={placeHolder}
                    inputProps={{ spellCheck: "false" }}
                    InputProps={{
                        disableUnderline: true
                    }}
                />
            </Box>

            <Box container className={specificStyles.tradActionBar}>
                <Box className={specificStyles.buttonWrapper}>
                    <StandardCleanCTA onClick={clearTradField}>
                        <Typography variant="h3">
                            {FrFR.screen.translatescreen.TRANSLATE_EMPTY}
                        </Typography>
                    </StandardCleanCTA>
                </Box>
                <Box className={specificStyles.buttonWrapper}>
                    <MainCleanCTA onClick={launchTrad}>
                        <Typography variant="h3">
                            {FrFR.screen.translatescreen.TRANSLATE_TRANSLATE}
                        </Typography>
                    </MainCleanCTA>
                </Box>
            </Box>

            {processedCompo && (
                <Box container className={specificStyles.compoContainer}>
                    {processedCompo.map((row) => {
                        return (
                            <IngredientRow
                                ingredient={row}
                                onSelect={selectIngredient}
                            />
                        );
                    })}
                </Box>
            )}
            {hasSelectedIngredient && (
                <IngredientDetailModal
                    ingredient={selectedIngredient}
                    isOpen={hasSelectedIngredient}
                    closeSelf={deselectIngredient}
                />
            )}
        </Paper>
    );
}

export default MireilleTrad;
