import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import specificStyles from "../Account.module.css";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { StandardConnexionInput } from "../../../MireilleInputFields";

import {
    getAuth,
    EmailAuthProvider,
    reauthenticateWithCredential,
    updateEmail,
    updatePassword
} from "firebase/auth";
import { MainCTA } from "../../../MireilleCTAS";
import { InputAdornment } from "@mui/material";
const emailPlaceHolder = "images/placeHolders/email/emailPlaceHolder.png";
const lockPlaceHolder = "images/placeHolders/lock/lockPlaceHolder.png";

export default function EditAccountDataPopin(props) {
    var [email, setEmail] = useState("");
    var [newPassword, setNewPassword] = useState("");
    var [oldPassword, setOldPassword] = useState("");
    var [errorMessage, setErrorMessage] = useState(null);
    var [emailPlaceHolderText, setEmailPlaceHolder] = useState("");

    useEffect(() => {
        getCurrentEmail();
    }, []);

    const getCurrentEmail = () => {
        let firebaseUser = getAuth().currentUser;
        setEmailPlaceHolder(firebaseUser.email);
    };

    const reauthenticate = (currentPassword) => {
        var user = getAuth().currentUser;
        var cred = EmailAuthProvider.credential(user.email, currentPassword);
        return reauthenticateWithCredential(user, cred);
    };

    const resetPassword = () => {
        if (oldPassword !== "") {
            reauthenticate(oldPassword)
                .then(() => {
                    var user = getAuth().currentUser;
                    updatePassword(user, newPassword)
                        .then(() => {
                            reauthenticate(newPassword);
                            purgeInputData();
                        })
                        .catch((error) => {
                            setErrorMessage(
                                "Tu ne peux pas utiliser ce nouveau mot de passe"
                            );
                        });
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage(
                        "Tu t'es trompé sur ton ancien mot de passe"
                    );
                });
        } else {
            setErrorMessage(
                "N'oublie pas de renseigner ton ancien mot de passe"
            );
        }
    };

    const resetEmail = () => {
        if (oldPassword !== "") {
            reauthenticate(oldPassword)
                .then(() => {
                    var user = getAuth().currentUser;
                    updateEmail(user, email)
                        .then(() => {
                            getCurrentEmail();
                            purgeInputData();
                        })
                        .catch((error) => {
                            setErrorMessage(
                                "Tu ne peux pas utiliser cet email"
                            );
                        });
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage("Tu t'es trompé sur ton mot de passe");
                });
        } else {
            setErrorMessage("N'oublie pas de renseigner ton mot de passe");
        }
    };

    const purgeInputData = () => {
        setEmail("");
        setOldPassword("");
        setNewPassword("");
        setErrorMessage(null);
        props.handleClose();
    };

    const updateOldPassword = (event) => {
        const { value } = event.currentTarget;
        setErrorMessage(null);
        setOldPassword(value);
    };

    const updateNewPassword = (event) => {
        const { value } = event.currentTarget;
        setErrorMessage(null);
        setNewPassword(value);
    };

    const updateNewEmail = (event) => {
        const { value } = event.currentTarget;
        setErrorMessage(null);
        setEmail(value);
    };

    return (
        <Dialog
            open={props.editEmail || props.editPwd}
            onClose={props.purgeInputData}
        >
            <Box className={specificStyles.manageAccountPopin}>
                {props.editEmail ? (
                    <>
                        <Box className={specificStyles.manageDataTitleHolder}>
                            <Typography variant="h5">
                                Changer mon adresse mail
                            </Typography>
                        </Box>

                        {errorMessage && (
                            <Box className={specificStyles.manageDataError}>
                                <Typography variant="body1">
                                    {errorMessage}
                                </Typography>
                            </Box>
                        )}
                        <Box
                            className={specificStyles.manageDataSubTitleHolder}
                        >
                            <Typography variant="body1">
                                Dis moi ton nouvel email
                            </Typography>
                        </Box>
                        <Box>
                            <StandardConnexionInput
                                value={email}
                                disableUnderline={true}
                                onChange={updateNewEmail}
                                multiline={false}
                                placeholder={emailPlaceHolderText}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        className={
                                            specificStyles.imagePlaceHolderBox
                                        }
                                    >
                                        <img
                                            src={emailPlaceHolder}
                                            className={
                                                specificStyles.manageAccountImage
                                            }
                                        />
                                    </InputAdornment>
                                }
                            />
                        </Box>

                        <Box
                            className={specificStyles.manageDataSubTitleHolder}
                        >
                            <Typography variant="body1">
                                Rappelle moi ton mot de passe
                            </Typography>
                        </Box>
                        <Box>
                            <StandardConnexionInput
                                value={oldPassword}
                                disableUnderline={true}
                                onChange={updateOldPassword}
                                multiline={false}
                                placeholder="tonmotdepasse"
                                id="input-with-icon-adornment"
                                type="password"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        className={
                                            specificStyles.imagePlaceHolderBox
                                        }
                                    >
                                        <img
                                            src={lockPlaceHolder}
                                            className={
                                                specificStyles.manageAccountImage
                                            }
                                        />
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box className={specificStyles.ctaZone}>
                            <Box className={specificStyles.ctaHolder}>
                                <MainCTA onClick={() => resetEmail()}>
                                    Mets à jour mon email
                                </MainCTA>
                            </Box>
                            <Box className={specificStyles.ctaHolder}>
                                <MainCTA onClick={props.handleClose}>
                                    Finalement non
                                </MainCTA>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box className={specificStyles.manageDataTitleHolder}>
                            <Typography variant="h5">
                                Changer mon mot de passe
                            </Typography>
                        </Box>
                        {errorMessage && (
                            <Box className={specificStyles.manageDataError}>
                                <Typography variant="body1">
                                    {errorMessage}
                                </Typography>
                            </Box>
                        )}
                        <Box
                            className={specificStyles.manageDataSubTitleHolder}
                        >
                            <Typography variant="body1">
                                Dis moi ton nouveau mot de passe
                            </Typography>
                        </Box>
                        <Box>
                            <StandardConnexionInput
                                value={newPassword}
                                disableUnderline={true}
                                onChange={updateNewPassword}
                                multiline={false}
                                placeholder={"6 caractères minimum"}
                                type="password"
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        className={
                                            specificStyles.imagePlaceHolderBox
                                        }
                                    >
                                        <img
                                            src={lockPlaceHolder}
                                            className={
                                                specificStyles.manageAccountImage
                                            }
                                        />
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box
                            className={specificStyles.manageDataSubTitleHolder}
                        >
                            <Typography variant="body1">
                                Et ton mot de passe actuel
                            </Typography>
                        </Box>

                        <Box>
                            <StandardConnexionInput
                                value={oldPassword}
                                disableUnderline={true}
                                onChange={updateOldPassword}
                                multiline={false}
                                placeholder="tonmotdepasse"
                                type="password"
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        className={
                                            specificStyles.imagePlaceHolderBox
                                        }
                                    >
                                        <img
                                            src={lockPlaceHolder}
                                            className={
                                                specificStyles.manageAccountImage
                                            }
                                        />
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box className={specificStyles.ctaZone}>
                            <Box className={specificStyles.ctaHolder}>
                                <MainCTA onClick={() => resetPassword(true)}>
                                    C'est parti
                                </MainCTA>
                            </Box>
                            <Box className={specificStyles.ctaHolder}>
                                <MainCTA onClick={props.handleClose}>
                                    Finalement non
                                </MainCTA>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Dialog>
    );
}
