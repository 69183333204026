import React, { useEffect } from "react";

import SubscriptionHandlerModal from "../../subscriptionHandler/SubscriptionHandlerModal";
import SpecialOfferHandler from "../../subscriptionHandler/SpecialOfferHandler";
import Sales from "./components/Sales";
import Experts from "./components/Experts";
import Independance from "./components/Independance";
import Landing from "./components/Landing";
import Footer from "./components/Footer";
//import { pushPageView } from "../../utils/gtmHandler";

export default function LandingPage(props) {
    /*useEffect(() => {
        pushPageView("landing_" + props.promoCode);
    }, []);*/

    return (
        <>
            <Landing promoCode={props.promoCode} />
            <Footer displayStoreCTAs={false} />
        </>
    );
}
