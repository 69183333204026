import React from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import specificStyles from "../Account.module.css";

export default function AccessCouponSection() {
    const { mireilleUser } = useSelector((state) => state.account);
    return (
        <Box className={specificStyles.salesBlock}>
            <Typography
                variant="h5"
                className={specificStyles.manageDataTitleHolder}
            >
                Mes pass cadeau :
            </Typography>
            <Box className={specificStyles.manageDataSubTitleHolder}>
                <Typography variant="body1">
                    Envoie un code à qui tu veux pour offrir un pass à Mireille
                    !
                </Typography>
            </Box>

            {mireilleUser.COUPONS.map((coupon, index) => {
                return (
                    <>
                        {coupon.USED === false && (
                            <Box className={specificStyles.couponHolder}>
                                <Typography variant="body2">
                                    {coupon.COUPON}
                                </Typography>
                            </Box>
                        )}
                    </>
                );
            })}
        </Box>
    );
}
