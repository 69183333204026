import React from "react";
import specificStyles from "./Press.module.css";
import Footer from "../home/components/Footer";
import { Box } from "@mui/material";
import BlockHolder from "./components/BlockHolder";
import articlesJson from "./data/articles.json";
import communiquesJson from "./data/communiques.json";
import ArticleBrick from "./components/ArticleBrick";
import { Typography } from "@mui/material";
import ContactBlock from "./components/ContactBlock";
import CommuniqueBlock from "./components/CommuniqueBlock";
import {
    pressRTPIllusFolderUrl,
    pressComunqueFolderUrl,
    pressComunquePicFolderUrl
} from "../../../constants/settings";

export default function Press() {
    return (
        <>
            <Box className={specificStyles.background}>
                <Box className={specificStyles.pageHeart}>
                    <Box className={specificStyles.section}>
                        <BlockHolder blockName="Contact">
                            <ContactBlock />
                        </BlockHolder>
                    </Box>
                    <Box className={specificStyles.BlockHolderTitle}>
                        <Typography variant="h4">
                            Communiqués de Presse
                        </Typography>
                    </Box>
                    <Box className={specificStyles.section}>
                        {communiquesJson.communiques.map(
                            (communique, index) => {
                                return (
                                    <CommuniqueBlock
                                        key={index}
                                        url={
                                            pressComunqueFolderUrl +
                                            communique.docName
                                        }
                                        illus={
                                            pressComunquePicFolderUrl +
                                            communique.illus
                                        }
                                        title={communique.title}
                                        date={communique.date}
                                    />
                                );
                            }
                        )}
                    </Box>
                    <Box className={specificStyles.BlockHolderTitle}>
                        <Typography variant="h4">
                            Ils parlent de nous
                        </Typography>
                    </Box>
                    <Box className={specificStyles.section}>
                        {articlesJson.articles
                            .sort((a, b) =>
                                parseInt(a.priority) > parseInt(b.priority)
                                    ? -1
                                    : 1
                            )
                            .map((article, index) => {
                                if (parseInt(article.priority) > 0) {
                                    return (
                                        <ArticleBrick
                                            key={index}
                                            illus={
                                                pressRTPIllusFolderUrl +
                                                article.pic
                                            }
                                            url={article.url}
                                            quote={article.quote}
                                        />
                                    );
                                }
                            })}
                    </Box>
                </Box>
            </Box>
            <Footer displayStoreCTAs={true} />
        </>
    );
}

//<BlockHolder blockName="Dossier Presse" />
