export const searchOptions = {
    porosity: {
        filterKey: "POROSITY",
        filterLabel: "Porosité",
        filterOptions: [
            { value: 0, label: "Faible", exclusive: true },
            { value: 1, label: "Moyenne", exclusive: true },
            { value: 2, label: "Élevée", exclusive: true }
        ]
    },
    purity: {
        filterKey: "PURITY",
        filterLabel: "Pureté",
        filterOptions: [
            { value: 0, label: "Faible", exclusive: true },
            { value: 1, label: "Élevée", exclusive: true }
        ]
    },
    texture: {
        filterKey: "TEXTURE",
        filterLabel: "Texture",
        filterOptions: [
            { value: "STRAIGHT", label: "Lisses", exclusive: true },
            { value: "WAVY", label: "Ondulés", exclusive: true },
            { value: "CURLY", label: "Bouclés", exclusive: true },
            { value: "FRIZZ", label: "Frisés", exclusive: true },
            { value: "KINKY", label: "Crépus", exclusive: true }
        ]
    },
    published: {
        filterKey: "PUBLISHED",
        filterLabel: "C'est publié ?",
        filterOptions: [
            { value: true, label: "Oui", exclusive: true },
            { value: false, label: "Non", exclusive: true }
        ]
    }
};
