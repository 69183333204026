import React, { useEffect, useState } from "react";
import { profileSteps } from "../../../constants/profileParameters";
import specificStyles from "./Profile.module.css";
import { Box, Typography } from "@mui/material";
import { MainCleanCTA } from "../../MireilleCTAS.js";
import HairTextureFormPage from "./pageTemplates/HairTextureFormPage.js";
import SingleChoiceFormPage from "./pageTemplates/SingleChoiceFormPage.js";
import MultiChoiceFormPage from "./pageTemplates/MultiChoiceFormPage.js";
import Intro from "./pageTemplates/Intro";
import ProfileResult from "./pageTemplates/ProfileResult";
import {
    porosityOptionValues,
    voteOptionsValues,
    textureOptionValues,
    scoreDescriptions
} from "../../../constants/profileParameters";
//import { pushPageView, trackStandardEvent } from "../../utils/gtmHandler";
import Paywall from "../../subscriptionHandler/Paywall/Paywall";
import { useDispatch, useSelector } from "react-redux";
import { getProfileUrl, updateProfileUrl } from "../../../constants/settings";
import { useNavigate } from "react-router-dom";
import ConnexionPopin from "../../subscriptionHandler/ConnexionPopin";
import NewPaywall from "../../subscriptionHandler/Paywall/NewPaywall";

export default function FormProfile() {
    const navigate = useNavigate();
    const { mireilleUser } = useSelector((state) => state.account);
    const [index, setIndex] = useState(0);
    const [displayResults, setDisplayResults] = useState(false);
    const [profileStepsAnswers, setProfileStepsAnswers] = useState([]);
    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [displayIntro, setDisplayIntro] = useState(true);
    const [displayConnect, setDisplayConnect] = useState(false);
    const [displayPaywall, setDisplayPaywall] = useState(false);
    const [nextDisabled, setNextDisabled] = useState(false);
    const [profile, setProfile] = useState({});
    const [crudeMatrix, setCrudeMatrix] = useState({});
    const [profileMatrix, setProfileMatrix] = useState({});

    const SCORE_LAYOUTS = [
        "STRENGTH",
        "NUTRITION",
        "HYDRATATION",
        "DEFINITION",
        "PURITY"
    ];

    const goFurther = () => {
        /*trackStandardEvent("Lead", {
            lead_name: "profile step " + index + " completed"
        });*/
        if (displayIntro) {
            setDisplayIntro(false);
            if (!mireilleUser) {
                setDisplayConnect(true);
            }
        } else {
            if (index + 2 > profileSteps.length) {
                processResults();
            } else {
                setIndex(index + 1);
            }
        }
        setNextDisabled(true);
    };

    useEffect(() => {
        if (mireilleUser) {
            setDisplayConnect(false);
        }
    }, [mireilleUser]);

    const processResults = () => {
        var profileCopy = profile;
        var baseMatrix = {};
        var refinedMatrix = {};
        profileCopy.texture = parseScores(
            profileStepsAnswers,
            getStandardScores,
            "TEXTURE"
        )[0];
        profileCopy.basePorosity =
            textureOptionValues[profileCopy.texture].BASE_POROSITY;
        profileCopy.virtualPorosityVotes = parseScores(
            profileStepsAnswers,
            getVotedScores,
            "VIRTUAL_POROSITY"
        );
        profileCopy.porosityHintVotes = parseScores(
            profileStepsAnswers,
            getVotedScores,
            "POROSITY_HINT"
        );
        if (profileCopy.virtualPorosityVotes.length > 0) {
            var virtualPorosity = applyVotes(
                profileCopy.basePorosity,
                convertVotes(profileCopy.virtualPorosityVotes)
            );
            var votedVirtualPorosity = applyVotes(
                virtualPorosity,
                convertVotes(profileCopy.porosityHintVotes)
            );
            profileCopy.currentPorosity = Math.round(votedVirtualPorosity);
        } else {
            var votedVirtualPorosity = applyVotes(
                profileCopy.basePorosity,
                convertVotes(profileCopy.porosityHintVotes)
            );
            profileCopy.currentPorosity = Math.round(votedVirtualPorosity);
        }

        var closerPorosity = getCloserPorosity(profileCopy.currentPorosity);
        baseMatrix.DEFINITION =
            textureOptionValues[profileCopy.texture].BASE_DEFINITION;
        baseMatrix.STRENGTH = closerPorosity.BASE_STRENGTH;
        baseMatrix.HYDRATATION = closerPorosity.BASE_HYDRATATION;
        baseMatrix.NUTRITION = closerPorosity.BASE_NUTRITION;

        profileCopy.baseMatrix = baseMatrix;

        profileCopy.hydratationVotes = parseScores(
            profileStepsAnswers,
            getVotedScores,
            "HYDRATATION"
        );

        refinedMatrix.HYDRATATION = Math.round(
            applyVotes(
                baseMatrix.HYDRATATION,
                convertVotes(profileCopy.hydratationVotes)
            )
        );
        profileCopy.purityVotes = parseScores(
            profileStepsAnswers,
            getVotedScores,
            "PURITY"
        );
        refinedMatrix.PURITY = Math.round(
            profileCopy.purityVotes.length > 0
                ? applyVotes(null, convertVotes(profileCopy.purityVotes))
                : 24
        );

        profileCopy.strengthVotes = parseScores(
            profileStepsAnswers,
            getVotedScores,
            "STRENGTH"
        );
        refinedMatrix.STRENGTH = Math.round(
            applyVotes(
                baseMatrix.STRENGTH,
                convertVotes(profileCopy.strengthVotes)
            )
        );
        profileCopy.definitionVotes = parseScores(
            profileStepsAnswers,
            getVotedScores,
            "DEFINITION"
        );
        refinedMatrix.DEFINITION = Math.round(
            applyVotes(
                baseMatrix.DEFINITION,
                convertVotes(profileCopy.definitionVotes)
            )
        );
        profileCopy.nutritionVotes = parseScores(
            profileStepsAnswers,
            getVotedScores,
            "NUTRITION"
        );
        refinedMatrix.NUTRITION = Math.round(
            applyVotes(
                baseMatrix.NUTRITION,
                convertVotes(profileCopy.nutritionVotes)
            )
        );

        //offset porosity or virtual porosity if there is a virtual porosity
        //prepare basic profile needs from porosity or virual porosity
        //for each hair score, parse results for the scores
        //adapt each score with the score offsets
        //good job, the profile is ready
        setProfile(profileCopy);
        var saveMatrix = {};
        saveMatrix.POROSITY = profileCopy.currentPorosity;
        saveMatrix.TEXTURE = profileCopy.texture;
        saveMatrix.SCORES = refinedMatrix;
        setCrudeMatrix(saveMatrix);
        saveProfile(saveMatrix);
    };

    useEffect(() => {
        if (mireilleUser && mireilleUser.FIREBASE_ID) {
            loadUseProfile(mireilleUser.FIREBASE_ID);
        }
    }, [mireilleUser]);

    const saveProfile = (matrix) => {
        console.log("saving the matrix");
        if (mireilleUser && mireilleUser.FIREBASE_ID) {
            var toSave = {
                PROFILE: matrix,
                FIREBASE_ID: mireilleUser.FIREBASE_ID
            };
            var standardHeaders = new Headers();
            standardHeaders.append("Content-Type", "application/json");

            console.log(toSave);
            fetch(updateProfileUrl, {
                method: "POST",
                headers: standardHeaders,
                body: JSON.stringify(toSave)
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const loadUseProfile = (firebaseId) => {
        var raw = {
            FIREBASE_ID: firebaseId
        };
        var standardHeaders = new Headers();
        standardHeaders.append("Content-Type", "application/json");

        fetch(getProfileUrl, {
            method: "POST",
            headers: standardHeaders,
            body: JSON.stringify(raw)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.STATUS === "Ok") {
                    setCrudeMatrix(data.PROFILE);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if ("SCORES" in crudeMatrix) {
            var simplifiedMatrix = {};
            const refinedMatrix = crudeMatrix.SCORES;
            SCORE_LAYOUTS.forEach((strength) => {
                var score = Math.round((refinedMatrix[strength] - 3) / 6);
                score = score > 3 ? 3 : score;
                score = score < 0 ? 0 : score;
                simplifiedMatrix[strength] = {
                    SCORE: score
                };
                simplifiedMatrix[strength].DESCRIPTION = scoreDescriptions[
                    strength
                ].filter((description) => {
                    return (
                        description.SCORE === simplifiedMatrix[strength].SCORE
                    );
                })[0].DESCRIPTION;
            });

            setProfileMatrix(simplifiedMatrix);
            setDisplayResults(true);
        }
    }, [crudeMatrix]);

    const getCloserPorosity = (ref) => {
        var distance = ref;
        var result = {};
        for (var i = 0; i < porosityOptionValues.length; i += 1) {
            var distanceCandidate = Math.sqrt(
                Math.pow(ref - porosityOptionValues[i].MID_RANGE, 2)
            );
            if (distanceCandidate < distance) {
                result = porosityOptionValues[i];
            }
        }
        return result;
    };

    const convertVotes = (array) => {
        var results = [];
        for (var i = 0; i < array.length; i++) {
            results.push({
                score: voteOptionsValues[array[i].score],
                voteAmount: array[i].voteAmount
            });
        }
        return results;
    };

    const parseScores = (array, nestedFunction, criterion) => {
        const results = [];
        for (var i = 0; i < array.length; i++) {
            var step = array[i];
            for (var j = 0; j < step.answers.length; j++) {
                var ans = step.answers[j];
                if (ans.scores) {
                    for (var k = 0; k < ans.scores.length; k++) {
                        var score = ans.scores[k];
                        var releventScore = nestedFunction(score, criterion);
                        if (releventScore) {
                            results.push(releventScore);
                        }
                    }
                }
            }
        }
        return results;
    };

    const applyVotes = (value, array) => {
        var total = 0;
        var count = 0;
        if (value !== null) {
            total = value * 2;
            count = 2;
        }
        array.forEach((element) => {
            total += element.score * element.voteAmount;
            count += element.voteAmount;
        });
        return total / count;
    };

    const getStandardScores = (score, scoreFlag) => {
        if (score.flag === scoreFlag) {
            return score.vote;
        } else return;
    };

    const getVotedScores = (score, scoreFlag) => {
        if (score.flag === scoreFlag) {
            return { score: score.vote, voteAmount: score.voteAmount };
        } else return;
    };

    const restartProfile = () => {
        setDisplayResults(false);
        setProfile({});
        setIndex(0);
        setProfileStepsAnswers([]);
        setCrudeMatrix({});
        setProfileMatrix({});
    };

    const goBack = () => {
        if (index > 0) {
            if (displayResults) {
                setDisplayResults(false);
                setProfile({});
            } else {
                setIndex(index - 1);
            }
            var allAnsCopy = profileStepsAnswers;
            allAnsCopy.pop();
            setProfileStepsAnswers(allAnsCopy);
        }
    };

    const bypass = () => {
        validateStepAnswers([]);
    };

    const toggleAnswer = (answer, exlusive) => {
        var currentAnsCopy = [...currentAnswers];
        const index = currentAnswers.indexOf(answer);
        if (index > -1) {
            currentAnsCopy.splice(index, 1);
        } else {
            if (exlusive) {
                currentAnsCopy = [answer];
            } else {
                currentAnsCopy.push(answer);
            }
        }
        setCurrentAnswers(currentAnsCopy);
        setNextDisabled(currentAnsCopy.length === 0);
    };

    const validateStepAnswers = (answers) => {
        var allAnsCopy = profileStepsAnswers;
        var stepAnswers = { questionKeyLabel: profileSteps[index].keyLabel };
        stepAnswers.answers = answers;
        allAnsCopy.push(stepAnswers);
        setProfileStepsAnswers(allAnsCopy);
        setCurrentAnswers([]);
        goFurther();
    };

    const goToRoutines = () => {
        if (mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED") {
            navigate("/routines", { replace: true });
        } else {
            //trackStandardEvent("AddToCart", {});
            setDisplayPaywall(true);
        }
    };

    return (
        <>
            {displayPaywall ? (
                <NewPaywall
                    forceConnexion={false}
                    onSuccessUrl="routines"
                    onCancelUrl="profile"
                    canClose={true}
                    handleClose={() => setDisplayPaywall(false)}
                />
            ) : (
                <>
                    {displayConnect ? (
                        <ConnexionPopin
                            isOpen={displayConnect}
                            canClose={false}
                            handleClose={() => {}}
                            overrideInviteCreate={
                                "et découvre les fantastiques routines de nos ambassadrices"
                            }
                        />
                    ) : (
                        <Box className={specificStyles.pageHeart}>
                            {displayResults ? (
                                <ProfileResult
                                    goBack={goBack}
                                    profile={profileMatrix}
                                    matrix={crudeMatrix}
                                    restartProfile={restartProfile}
                                    goToRoutines={goToRoutines}
                                />
                            ) : displayIntro ? (
                                <Box className={specificStyles.questionLayout}>
                                    <Intro />
                                    <Box className={specificStyles.actionBar}>
                                        <MainCleanCTA onClick={goFurther}>
                                            <Typography variant="h3">
                                                C'est parti
                                            </Typography>
                                        </MainCleanCTA>
                                    </Box>
                                </Box>
                            ) : (
                                <Box className={specificStyles.questionLayout}>
                                    <Box
                                        className={specificStyles.questionLabel}
                                    >
                                        <Typography variant="h4">
                                            {profileSteps[index].label}
                                        </Typography>
                                    </Box>
                                    {profileSteps[index].subLabel && (
                                        <Box
                                            className={
                                                specificStyles.questionSubLabel
                                            }
                                        >
                                            <Typography variant="body2">
                                                {profileSteps[index].subLabel}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box
                                        className={specificStyles.answersBlock}
                                    >
                                        {profileSteps[index].template ===
                                            "HAIR_TEXTURE" && (
                                            <HairTextureFormPage
                                                step={profileSteps[index]}
                                                toggleAnswer={toggleAnswer}
                                                answers={currentAnswers}
                                            />
                                        )}

                                        {profileSteps[index].template ===
                                            "MULTI_CHOICE" && (
                                            <MultiChoiceFormPage
                                                step={profileSteps[index]}
                                                toggleAnswer={toggleAnswer}
                                                answers={currentAnswers}
                                            />
                                        )}
                                        {profileSteps[index].template ===
                                            "SINGLE_CHOICE" && (
                                            <SingleChoiceFormPage
                                                step={profileSteps[index]}
                                                toggleAnswer={toggleAnswer}
                                                answers={currentAnswers}
                                            />
                                        )}
                                    </Box>
                                    <Box className={specificStyles.filler} />
                                    <Box className={specificStyles.actionBar}>
                                        {profileSteps[index].validation
                                            .bypass && (
                                            <Box
                                                className={
                                                    specificStyles.bypass
                                                }
                                                onClick={bypass}
                                            >
                                                <Typography variant="body1">
                                                    {
                                                        profileSteps[index]
                                                            .validation
                                                            .bypassLabel
                                                    }
                                                </Typography>
                                            </Box>
                                        )}
                                        <MainCleanCTA
                                            onClick={() => {
                                                nextDisabled
                                                    ? console.log(
                                                          "ça ne marche pas ton plan"
                                                      )
                                                    : validateStepAnswers(
                                                          currentAnswers
                                                      );
                                            }}
                                            disabled={nextDisabled}
                                        >
                                            <Typography variant="h3">
                                                Continuer
                                            </Typography>
                                        </MainCleanCTA>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </>
            )}
        </>
    );
}

/*
Principe de naviagtion : on parcours des listes et on flag les réponses au fûr et à mesure. A la fin on compile les réponses et on calcule des scores de force. Puis on affiche la page de résultats
        
        */
