import React, { useEffect, useState } from "react";
import specificStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { selfUrl } from "../../../../constants/settings";
import { red } from "@mui/material/colors";
import StrengthLegend from "./StrengthLegend";
import LegendPrompt from "../prompts/LegendPrompt";
import { PROFILE_DECOR } from "../../../../constants/profileParameters";

const kahinaPic = "images/illus/portraits/kahina/kahina.png";

export default function ResultCard(props) {
    const [activeDecor, setActiveDecor] = useState(null);
    const strengthMatrix = [
        {
            key: "HYDRATATION"
        },
        {
            key: "PURITY"
        },
        {
            key: "STRENGTH"
        },
        {
            key: "DEFINITION"
        },
        {
            key: "NUTRITION"
        }
    ];

    const openLegend = (decor) => {
        setActiveDecor(decor);
    };

    const closeLegend = () => {
        setActiveDecor(null);
    };

    return (
        <>
            <Box className={specificStyles.resultCardHolder}>
                <Box className={specificStyles.gabyPicHolder}>
                    <img src={kahinaPic} width={"149.5pt"} height={"149.5pt"} />
                </Box>
                <Box className={specificStyles.strengthFullLegendTitleHolder}>
                    <Typography variant="h5">
                        Quelques explications...
                    </Typography>
                </Box>
                {strengthMatrix.map((decor, index) => {
                    return (
                        <StrengthLegend
                            decor={
                                PROFILE_DECOR.filter(
                                    (profDecor) => profDecor.key === decor.key
                                )[0]
                            }
                            strength={props.legend[index]}
                            openLegend={openLegend}
                        />
                    );
                })}
                {activeDecor && (
                    <LegendPrompt
                        legendOpen={activeDecor !== null}
                        decor={activeDecor}
                        closeLegend={closeLegend}
                    />
                )}
            </Box>
        </>
    );
}
