import React, { useEffect, useState } from "react";
import specificStyles from "../Home.module.css";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { StandardCTA } from "../../../MireilleCTAS";
import { setPromoCode } from "../../../../redux/actions/account";
import { useDispatch } from "react-redux";
import LandingPageOfferHandler from "../../../subscriptionHandler/LandingPageOfferHandler";

const flower1 = "images/home/flower1.png";
const flower2 = "images/home/flower2.png";
const flower3 = "images/home/flower3.png";

export default function Landing(props) {
    const dispatch = useDispatch();
    const [specificData, setSpecificData] = useState(null);

    useEffect(() => {
        if (props.promoCode) {
            const validPromo = partnersData.hasOwnProperty(props.promoCode);
            if (validPromo) {
                setSpecificData(partnersData[props.promoCode]);
                if (partnersData[props.promoCode].hasOwnProperty("promoId")) {
                    dispatch(
                        setPromoCode(partnersData[props.promoCode]["promoId"])
                    );
                }
            }
        }
    }, [props.promoCode]);

    return (
        <Box className={specificStyles.SlideLanding} id="application">
            <Box className={specificStyles.pageHeartMobile}>
                {specificData && (
                    <LandingPageOfferHandler
                        forceConnect={true}
                        monthAmount={4}
                        subscription={specificData.subscription}
                        redirectTo="/profile"
                        purchaseButton={specificData.purchaseButton}
                        purchaseIntro={specificData.purchaseIntro}
                    />
                )}
            </Box>
        </Box>
    );
}

const partnersData = {
    FIONAOSLO: {
        name: "FIONAOSLO",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KR1onGBmf3nfive4ITQ733y",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Fiona Oslo ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    PACKROUTINES: {
        name: "PACKROUTINES",
        basePrice: "15€",
        newPrice: "15€",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    GABY: {
        name: "GABY",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KSVlXGBmf3nfiveA4aiaJIb",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec la petite Gaby ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    SOFRESHLY: {
        name: "SOFRESHLY",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWocbGBmf3nfiveN0ohQCb8",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec so freshly ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    AFROLIFEDECHACHA: {
        name: "AFROLIFEDECHACHA",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWoerGBmf3nfivedeyDYvjW",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Afro life de Chacha ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    MANONBHTVL: {
        name: "MANONBHTVL",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWohIGBmf3nfiveuPX6HTV2",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Manon ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    CURLSBYLEA: {
        name: "CURLSBYLEA",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWoiXGBmf3nfive0JbzuBlA",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Curls by Léa ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    MYMICURLY: {
        name: "MYMICURLY",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWojkGBmf3nfivebpAhWzuS",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Mymi Curly ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    ROMYCURLY: {
        name: "ROMYCURLY",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWokyGBmf3nfiveE1MMbewy",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Romy Curly ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    MORGANEHEVASIA: {
        name: "MORGANEHEVASIA",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWom1GBmf3nfive09Wk2woW",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Morgane Hevasia ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    NEDJMA: {
        name: "NEDJMA",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWon8GBmf3nfive5fsfbdaN",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Naturally Nedjma ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    MARGAUXLOOKSANDCURLS: {
        name: "MARGAUXLOOKSANDCURLS",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWooMGBmf3nfiverB2j0U4u",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Margaux de Looks and Curls ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    MISSMANUAUNATUREL: {
        name: "MISSMANUAUNATUREL",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWopWGBmf3nfive0CCWL2q4",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Miss Manu au Naturel ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    MIRIAMSCURLS: {
        name: "MIRIAMSCURLS",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWoqfGBmf3nfiveNyLvOZuo",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Myriams Curls ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    SMELLYAA: {
        name: "SMELLYAA",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWorjGBmf3nfivelKocfpTM",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Smellyaa ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    MEIILYSSANDE: {
        name: "MEIILYSSANDE",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWot2GBmf3nfivetxLvDon3",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Meiilyssande ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    LESBOUCLESDEMEGAN: {
        name: "LESBOUCLESDEMEGAN",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWou6GBmf3nfiveYvqkpds1",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec les Boucles de Megan ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    },
    PETITECURLY: {
        name: "PETITECURLY",
        basePrice: "15€",
        newPrice: "10€",
        promoId: "promo_1KWowmGBmf3nfivepIHUG0YA",
        monthAmount: 4,
        purchaseIntro:
            ", prend ton accès et découvre ta routine capillaire parfaite !\n\nAvec Petite Curly ton accès 4 mois est à 10€ au lieu de 15€",
        purchaseButton: "Je prend mon accès !",
        forMe: true,
        subscription: false
    }
};
