import React from "react";

import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import specificStyles from "../Trad.module.css";
import { Typography } from "@mui/material";

import ImageMap from "../../../../constants/ImageMap";

import FrFR from "../../../../constants/localisations/fr-Fr";

export default function IngredientRow(props) {
    let gradeDescription = "";

    let illus = "";

    if (props.ingredient.INGREDIENT_GRADE === "good") {
        illus = ImageMap.grade.Good;
        gradeDescription = FrFR.component.resultElements.ingredientBrick.GOOD;
    } else if (props.ingredient.INGREDIENT_GRADE === "neutral") {
        illus = ImageMap.grade.Neutral;
        gradeDescription =
            FrFR.component.resultElements.ingredientBrick.NEUTRAL;
    } else if (props.ingredient.INGREDIENT_GRADE === "allergene") {
        illus = ImageMap.grade.Allergene;
        gradeDescription =
            FrFR.component.resultElements.ingredientBrick.ALLERGENE;
    } else if (props.ingredient.INGREDIENT_GRADE === "avoid") {
        illus = ImageMap.grade.Attention;
        gradeDescription =
            FrFR.component.resultElements.ingredientBrick.ATTENTION;
    } else if (props.ingredient.INGREDIENT_GRADE === "danger") {
        illus = ImageMap.grade.Danger;
        gradeDescription = FrFR.component.resultElements.ingredientBrick.DANGER;
    } else if (props.ingredient.INGREDIENT_GRADE === "No") {
        illus = illus = ImageMap.grade.Empty;
        gradeDescription = FrFR.component.resultElements.ingredientBrick.NO;
    } else {
        illus = illus = ImageMap.grade.Empty;
        gradeDescription =
            FrFR.component.resultElements.ingredientBrick.DEFAULT;
    }

    const intermediateClick = (event) => {
        props.onSelect(props.ingredient);
    };

    return (
        <Grid
            container
            spacing={0}
            onClick={intermediateClick}
            className={specificStyles.ingredientRowHolder}
        >
            <Grid item xs={2}>
                <img
                    className={specificStyles.gradeHolder}
                    src={illus}
                    alt=""
                />
            </Grid>
            <Grid item xs={8}>
                <Box className={specificStyles.compositionName}>
                    <Typography variant="h2">
                        {props.ingredient.INGREDIENT_NAME}
                    </Typography>
                </Box>
                <Box className={specificStyles.compositionState}>
                    <Typography variant="body2">{gradeDescription}</Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box className={specificStyles.detailsCTA}>
                    {FrFR.component.resultElements.ingredientBrick.DETAILS}
                </Box>
            </Grid>
        </Grid>
    );
}
