export default {
    navBar: {
        inactive: {
            scan: "images/scan/scan.png",
            translate: "images/translate/translate.png",
            search: "images/search/search.png",
            talk: "images/talk/talk.png",
            account: "images/account/account.png"
        },
        active: {
            scan: "images/scan/scanActive.png",
            translate: "images/translate/translateActive.png",
            search: "images/search/searchActive.png",
            talk: "images/talk/talkActive.png",
            account: "images/account/accountActive.png"
        }
    },
    scanLens: {
        inactive: "images/scanLens/scanLens.png",
        active: "images/scanLens/scanLensActive.png"
    },
    placeHolders: {
        search: "images/placeHolders/search/search.png",
        profile: "images/placeHolders/profile/profile.png",
        email: "images/placeHolders/email/email.png",
        lock: "images/placeHolders/lock/lock.png",
        hidden: "images/placeHolders/eye/hidden.png",
        shown: "images/placeHolders/eye/shown.png"
    },
    grade: {
        Good: "images/grade/good/good.png",
        Attention: "images/grade/attention/attention.png",
        Caution: "images/grade/caution/caution.png",
        Danger: "images/grade/danger/danger.png",
        Neutral: "images/grade/neutral/neutral.png",
        Allergene: "images/grade/allergene/allergene.png",
        Empty: "images/grade/empty/empty.png",
        Grail: "images/grade/grail/grail.png"
    },
    line: "images/line/line.png",
    talkIcons: {
        contract: "images/talkIcons/contract/contract.png",
        info: "images/talkIcons/info/info.png",
        lock: "images/talkIcons/lock/lock.png",
        message: "images/talkIcons/message/message.png",
        subscription: "images/talkIcons/subscription/subscription.png",
        arrow: "images/talkIcons/arrow/arrow.png",
        scanHistory: "images/talkIcons/scanHistory/scanHistory.png"
    },
    social: {
        instagram: "images/social/instagram/insta.png",
        tiktok: "images/social/tiktok/tiktok.png"
    },
    illustrations: {
        productSearch: "images/illus/productSearch/productSearch.png",
        ingredientSearch: "images/illus/ingredientSearch/ingredientSearch.png",
        splash: "images/illus/splash/splash.png",
        translate: "images/illus/translate/translate.png",
        portraits: {
            raph: "images/illus/portraits/raph/raph.png",
            gaby: "images/illus/portraits/gaby/gaby.png",
            kahina: "images/illus/portraits/kahina/kahina.png",
            mireille: "images/illus/portraits/mireille/mireille.png"
        },
        experts: "images/illus/experts/experts.png",
        account: "images/illus/account/account.png"
    }
};
