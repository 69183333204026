import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Congrats from '../../auth/Congrats';
import { initiateStripePurchase } from "../../../redux/actions/account";
import * as SETTINGS from "../../../constants/settings";
import MyOffers from "./MyOffers";
import { Box, StepConnector } from "@mui/material";
import ConnexionPopin from "../ConnexionPopin";
import { MainCTA } from "../../MireilleCTAS";
import SignOut from "../../auth/SignOut";
import specificStyles from "./Paywall.module.css";
import Footer from "../../pages/home/components/Footer";
import { Typography } from "@mui/material";
//import { pushSimpleEvent } from "../../utils/gtmHandler";
import { Dialog } from "@mui/material";
import { StandardWhiteCTA, MainCleanCTA } from "../../MireilleCTAS";
import {
    paywallColumnTitle,
    paywallColumnBase,
    paywallColumnPremium,
    reviews
} from "./constants";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
//import { trackStandardEvent } from "../../utils/gtmHandler";

const kahinaPic = "images/kahina.png";
const perkOk = "images/paywall/perkOk.png";
const perkKo = "images/paywall/perkKo.png";
const fiveStars = "images/paywall/five stars.png";

export default function NewPaywall(props) {
    const stripe = window.Stripe(SETTINGS.stripePublicKey);
    const dispatch = useDispatch();
    const { stripeCheckoutSession, mireilleUser, connexionStatus } =
        useSelector((state) => state.account);
    const [connect, isConnect] = useState(
        connexionStatus !== "Connected" && props.forceConnexion
    );
    const [purchaseParameters, setPurchaseParameters] = useState(null);

    const initiateSubscription = () => {
        //trackStandardEvent("InitiateCheckout", {});
        dispatch(
            initiateStripePurchase(
                true,
                0,
                "/SUCCESS_" + props.onSuccessUrl,
                "/" + props.onCancelUrl
            )
        );
        //Todo call endpoint to get a checkout session
    };

    const initiateOneShotPurchase = (monthAmount, forMe) => {
        dispatch(
            initiateStripePurchase(
                false,
                monthAmount,
                "/SUCCESS_" + props.onSuccessUrl,
                "/" + props.onCancelUrl,
                forMe
            )
        );
    };

    useEffect(() => {
        if (stripeCheckoutSession) {
            stripe
                .redirectToCheckout({
                    sessionId: stripeCheckoutSession.id
                })
                .then(handleResult);
        }
    }, [stripeCheckoutSession]);

    useEffect(() => {
        if (mireilleUser !== null) {
            isConnect(false);
            if (mireilleUser.SUBSCRIPTION_STATUS !== "SUBSCRIBED") {
                purchase(purchaseParameters);
            }
        }
    }, [mireilleUser]);

    const handleResult = () => {};

    const setSelectOffer = (params) => {
        if (
            connexionStatus === "Connected" &&
            mireilleUser.SUBSCRIPTION_STATUS !== "SUBSCRIBED"
        ) {
            purchase(params);
        } else if (
            connexionStatus === "Connected" &&
            mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED"
        ) {
        } else {
            setPurchaseParameters(params);
            //TODO here make sure that the user triggers subscription on connexion completed
            isConnect(true);
        }
    };

    const purchase = (params) => {
        //pushSimpleEvent("add_to_cart", "subscription");
        if (Boolean(params && params.MAKE_PURCHASE)) {
            if (params.SUBSCRIPTION) {
                initiateSubscription();
            } else {
                initiateOneShotPurchase(params.MONTH_AMOUNT, params.FOR_ME);
            }
        }
    };

    const startPurchase = () => {
        var params = {};
        params.MAKE_PURCHASE = true;
        params.SUBSCRIPTION = true;
        params.FOR_ME = true;
        setSelectOffer(params);
    };

    return (
        <Dialog
            fullScreen
            open={true}
            PaperProps={{
                style: {
                    overflowX: "hidden",
                    heigth: "100%",
                    background: "white"
                }
            }}
        >
            {props.canClose && (
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar style={{ background: "white" }}>
                        <IconButton
                            edge="start"
                            color="primary"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            )}
            {connect && (
                <ConnexionPopin
                    isOpen={connect}
                    canClose={true}
                    handleClose={() => {
                        isConnect(false);
                    }}
                />
            )}
            <Box className={specificStyles.paywallCatch}>
                <Box className={specificStyles.catchHolder}>
                    <Box className={specificStyles.catchIllusHolder}>
                        <img src={kahinaPic} width={"247px"} height={"287px"} />
                        <Typography variant="body1">
                            Kahina - Créatrice de Mireille
                        </Typography>
                    </Box>
                    <Box className={specificStyles.catchTextHolder}>
                        <Box className={specificStyles.catchTitleHolder}>
                            <Typography variant="h7">
                                Avec les routines de Mireille, Kahina aurait
                                économisée 650€ de produits pendant sa
                                transition, et toi ?
                            </Typography>
                        </Box>

                        <Box className={specificStyles.catchSubtitleHolder}>
                            <Typography variant="h3">
                                Au lieu de ça elle a acheté au cours de sa
                                transistion une trentaine de produits
                                capillaires qui ne lui conviennent pas du tout.
                            </Typography>
                            <Typography variant="h3">
                                Elle a rempli son cimetière de cosmetiques 🪦 en
                                perdant de l'argent.
                            </Typography>
                            {"\n\n"}
                            <Typography variant="h3">
                                Avec Mireille Premium, tu peux éviter de faire
                                la même chose.
                            </Typography>
                        </Box>

                        <Box className={specificStyles.catchCTAHolder}>
                            <StandardWhiteCTA onClick={startPurchase}>
                                <Typography variant="h3">
                                    Je prend mon accès Premium
                                </Typography>
                            </StandardWhiteCTA>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={specificStyles.paywallTableSection}>
                <Box className={specificStyles.paywallTable}>
                    <Box className={specificStyles.paywallColumnTitle}>
                        <Box className={specificStyles.tableHeaderCell} />
                        {paywallColumnTitle.map((title) => {
                            return (
                                <Box
                                    className={
                                        specificStyles.paywallTableTitleCell
                                    }
                                >
                                    {title.type === "SECTION" ? (
                                        <Typography variant="h5">
                                            {title.label}
                                        </Typography>
                                    ) : (
                                        <Typography variant="h3">
                                            {title.label}
                                        </Typography>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    <Box className={specificStyles.paywallColumnBase}>
                        <Box className={specificStyles.tableHeaderCell}>
                            <Box className={specificStyles.baseCartouche}>
                                <Typography variant="h7">Gratuit</Typography>
                            </Box>
                        </Box>
                        {paywallColumnBase.map((base) => {
                            return (
                                <Box
                                    className={
                                        specificStyles.paywallTableCellBase
                                    }
                                >
                                    {base.key === "OK" && (
                                        <img
                                            src={perkOk}
                                            width={"30px"}
                                            height={"30px"}
                                        />
                                    )}
                                    {base.key === "KO" && (
                                        <img
                                            src={perkKo}
                                            width={"25px"}
                                            height={"25px"}
                                        />
                                    )}
                                    {base.key === "Limité" && (
                                        <Typography variant="h3">
                                            {base.key}
                                        </Typography>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    <Box className={specificStyles.paywallColumnPremium}>
                        <Box className={specificStyles.tableHeaderCell}>
                            <Box
                                className={specificStyles.premiumCartouche}
                                onClick={startPurchase}
                            >
                                <Typography variant="h3">Premium</Typography>
                                <Typography variant="h8">19.99€</Typography>
                                <Typography variant="h5">/an</Typography>
                            </Box>
                        </Box>
                        {paywallColumnPremium.map((premium) => {
                            return (
                                <Box
                                    className={specificStyles.paywallTableCell}
                                >
                                    {premium.key === "OK" && (
                                        <img
                                            src={perkOk}
                                            width={"30px"}
                                            height={"30px"}
                                        />
                                    )}
                                    {premium.key === "Illimité" && (
                                        <Typography variant="h5">
                                            {premium.key}
                                        </Typography>
                                    )}
                                </Box>
                            );
                        })}
                        <Box className={specificStyles.paywallTail}></Box>
                    </Box>
                </Box>
                <Box className={specificStyles.catchCTAHolder}>
                    <MainCleanCTA onClick={startPurchase}>
                        <Typography variant="h3">
                            Je prend mon accès Premium
                        </Typography>
                    </MainCleanCTA>
                </Box>
            </Box>
            <Box className={specificStyles.paywallReviewsSection}>
                <Box className={specificStyles.reviewSectionTitle}>
                    <Typography variant="h7">
                        Qu'en pensent les Mireilles ?
                    </Typography>
                </Box>
                <Box className={specificStyles.reviewsHolder}>
                    {reviews.map((review) => {
                        return (
                            <Box className={specificStyles.reviewHolder}>
                                <Box className={specificStyles.reviewHead}>
                                    <img src={review.pic} width={"150px"} />
                                    <Box
                                        className={
                                            specificStyles.reviewTitleHolder
                                        }
                                    >
                                        <Box
                                            className={
                                                specificStyles.marginBottomText
                                            }
                                        >
                                            <Typography variant="h6">
                                                {review.name}
                                            </Typography>
                                        </Box>
                                        <Box
                                            className={
                                                specificStyles.marginBottomText
                                            }
                                        >
                                            <Typography variant="h5">
                                                {review.insta}
                                            </Typography>
                                        </Box>
                                        <img src={fiveStars} width={"100px"} />
                                    </Box>
                                </Box>
                                <Typography variant="h3">
                                    {review.review}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
                <Box className={specificStyles.catchCTAHolder}>
                    <MainCleanCTA onClick={startPurchase}>
                        <Typography variant="h3">
                            Je prend mon accès Premium
                        </Typography>
                    </MainCleanCTA>
                </Box>
            </Box>

            <Footer displayStoreCTAs={true} />
        </Dialog>
    );
}
