import specificStyles from "../Home.module.css";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import frFr from "../../../../constants/localisations/fr-Fr";

import { StandardYellowCTA } from "../../../MireilleCTAS";

import { Typography } from "@mui/material";

const appStoreCTA = "images/home/appStoreCTA.png";
const playStoreCTA = "images/home/playStoreCTA.png";
const iosOptionLogo = "images/mireilleOptions/iosOptionLogo.png";
const androidOptionLogo = "images/mireilleOptions/androidOptionLogo.png";
const mireilleTradOptionLogo =
    "images/mireilleOptions/mireilleTradOptionLogo.png";
const fullExampleIllus = "images/home/routines.gif";

export default function Routines(props) {
    return (
        <Box className={specificStyles.Slide1} id="application">
            <Box className={specificStyles.pageHeart}>
                {isMobile ? (
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            className={specificStyles.TextHolder}
                        >
                            <Box className={specificStyles.HomeTitleMobile}>
                                <Typography variant="h16">
                                    {frFr.screen.homescreen.ROUTINE_TITLE}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={specificStyles.ImageHolder}
                        >
                            <img
                                className={specificStyles.fullExampleMobile}
                                src={fullExampleIllus}
                                alt=""
                            />
                        </Grid>
                        <Grid item xs={8} className={specificStyles.TextHolder}>
                            <Box className={specificStyles.HomeContent}>
                                <Typography variant="h3">
                                    {frFr.screen.homescreen.ROUTINE_DESCRIPTION}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={specificStyles.TextHolder}
                        >
                            <Box className={specificStyles.CTAHolderMobile}>
                                <StandardYellowCTA onClick={props.goToDiag}>
                                    <Typography variant="h7">
                                        Je commence mon coaching !
                                    </Typography>
                                </StandardYellowCTA>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={5}
                            className={specificStyles.ImageHolder}
                        >
                            <img
                                className={specificStyles.fullExample}
                                src={fullExampleIllus}
                                alt=""
                            />
                        </Grid>
                        <Grid item xs={6} className={specificStyles.TextHolder}>
                            <Box className={specificStyles.HomeTitle}>
                                {frFr.screen.homescreen.ROUTINE_TITLE}
                            </Box>
                            <Box className={specificStyles.HomeContent}>
                                {frFr.screen.homescreen.ROUTINE_DESCRIPTION}
                            </Box>

                            <Box className={specificStyles.CTAHolderMobile}>
                                <StandardYellowCTA onClick={props.goToDiag}>
                                    <Typography variant="h7">
                                        Je commence mon coaching !
                                    </Typography>
                                </StandardYellowCTA>
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
}
