import React, { useEffect, useState } from "react";
import sStyles from "../../Routines.module.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import { CleanGreenLink } from "../../../../MireilleCTAS";
import RoutineElementPrompt from "../../prompts/RoutineElementPrompt";

import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const emptyPopin = {
    open: false,
    title: null,
    description: null,
    link: null,
    pic: null
};

export default function StepElement(props) {
    const [popinContent, setPopinContent] = useState(emptyPopin);

    const [touch, setTouch] = useState({});

    function handleTouchStart(e) {
        setTouch({ start: e.targetTouches[0].clientX });
    }

    function handleTouchMove(e) {
        setTouch({ ...touch, end: e.targetTouches[0].clientX });
    }

    function handleTouchEnd() {
        if (touch.end && touch.start) {
            if (touch.start - touch.end > 150) {
                moveSliderRight();
            }
            if (touch.start - touch.end < -150) {
                moveSliderLeft();
            }
        }
    }

    const moveSliderRight = () => {
        if (props.index === props.maxIndex - 1) {
            props.toIndex(0);
        } else {
            props.toIndex(props.index + 1);
        }
    };

    const moveSliderLeft = () => {
        if (props.index === 0) {
            props.toIndex(props.maxIndex - 1);
        } else {
            props.toIndex(props.index - 1);
        }
    };

    const setPopin = () => {
        console.log("we are here");
        const pop = { ...emptyPopin };
        pop.open = true;
        pop.pic = props.step.PIC;
        pop.title = props.step.TITLE;
        pop.description = props.step.ADVICE_DESCRIPTION;
        pop.link = props.step.TUTO_LINK;
        setPopinContent(pop);
    };
    const resetPopin = () => {
        setPopinContent({ ...emptyPopin });
    };
    const doNothing = () => {};

    return (
        <Box
            onTouchStart={props.maxIndex > 1 ? handleTouchStart : doNothing}
            onTouchMove={props.maxIndex > 1 ? handleTouchMove : doNothing}
            onTouchEnd={props.maxIndex > 1 ? handleTouchEnd : doNothing}
        >
            <RoutineElementPrompt
                content={popinContent}
                open={popinContent.open}
                close={resetPopin}
            />
            <Box className={sStyles.adviceBlock}>
                <Box className={sStyles.stepIntro}>
                    {props.maxIndex > 1 ? (
                        <Box className={sStyles.stepIndex}>
                            <ArrowBackIosNew
                                fontSize="small"
                                onClick={moveSliderLeft}
                            />
                            <Box className={sStyles.stepCounter}>
                                <Typography variant="h13">
                                    ÉTAPE {props.index + 1}/{props.maxIndex}
                                </Typography>
                            </Box>
                            <ArrowForwardIos
                                fontSize="small"
                                onClick={moveSliderRight}
                            />
                        </Box>
                    ) : (
                        <Box className={sStyles.stepIndex}>
                            <Typography variant="h13">
                                UNE SEULE ÉTAPE
                            </Typography>
                        </Box>
                    )}
                </Box>
                {props.displayAllSteps || props.index === 0 ? (
                    <>
                        <Box className={sStyles.stepIntro}>
                            <Box className={sStyles.stepIntroHolder}>
                                <Typography variant="body13">
                                    {props.step.INTRO}
                                </Typography>
                            </Box>
                        </Box>

                        {props.step.PIC && (
                            <Box className={sStyles.productPicHolder}>
                                <img
                                    src={props.step.PIC}
                                    className={sStyles.productPic}
                                />
                            </Box>
                        )}
                        <Box className={sStyles.meanDetailsHolder}>
                            {props.step.ADVICE_TYPE !== "RITUAL" ? (
                                <>
                                    <Typography variant="h13">
                                        {props.step.ADVICE_TITLE}
                                    </Typography>
                                    <Box
                                        component="span"
                                        className={sStyles.showTutoHolder}
                                    >
                                        {props.step.ADVICE_DESCRIPTION && (
                                            <CleanGreenLink onClick={setPopin}>
                                                <Typography variant="h10">
                                                    MODE D'EMPLOI
                                                </Typography>
                                            </CleanGreenLink>
                                        )}
                                    </Box>
                                </>
                            ) : (
                                <Box className={sStyles.ritualTitle}>
                                    <Typography variant="h4">
                                        {props.step.ADVICE_TITLE}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </>
                ) : (
                    <>
                        <Box className={sStyles.stepIntro}>
                            <Box className={sStyles.stepIntroHolder}>
                                <Typography variant="body13">
                                    Pour voir les autres étapes il te faut un
                                    accès
                                </Typography>
                            </Box>
                        </Box>

                        <Box
                            className={sStyles.offersTitle}
                            onClick={props.triggerPurchase}
                        >
                            <Typography variant="h4">
                                je veux voir les offres
                            </Typography>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    );
}
