import React, { useEffect, useState, useCallback, useRef } from "react";
import sStyles from "../Routines.module.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import { CleanLink, CleanWhiteLink } from "../../../MireilleCTAS";
import RoutineElementPrompt from "../prompts/RoutineElementPrompt";
import RitualElement from "./elments/RitualElement";
import RitualHeader from "./RitualHeader";
import { useSelector } from "react-redux";
//import { pushPageView, trackStandardEvent } from "../../../utils/gtmHandler";
import NewPaywall from "../../../subscriptionHandler/Paywall/NewPaywall";
const illus = "images/cart/cart.png";

const emptyPopin = {
    open: false,
    title: null,
    description: null,
    link: null,
    pic: null
};

export default function RoutineDisplay(props) {
    const [popinContent, setPopinContent] = useState(emptyPopin);
    const [sticky, setSticky] = useState(false);
    const [productList, setProductList] = useState(null);
    const [displayPaywall, setDisplayPaywall] = useState(false);
    const { mireilleUser } = useSelector((state) => state.account);

    const [listOpen, setListOpen] = useState(false);

    const [listSize, setListSize] = useState(0);

    const ref = useRef();

    const handleScroll = () => {
        const scrollTop = ref.current.scrollTop;
        scrollTop >= 100 ? setSticky(true) : setSticky(false);
    };

    useEffect(() => {
        //pushPageView("routine_display");
        const timeout = setTimeout(() => {
            if (props.routine !== null && ref.current) {
                ref.current.addEventListener("scroll", handleScroll);
            }
        }, 100);
    }, []);

    useEffect(() => {
        listOpen ? setListSize(productList.length * 85) : setListSize(0);
    }, [listOpen]);

    useEffect(() => {
        setProductList(mapProducts(props.routine.RITUALS));
    }, [props]);

    const displayAuthor = () => {
        var pop = { ...emptyPopin };
        pop.open = true;
        pop.pic = props.routine.AUTHOR.PIC;
        pop.title = props.routine.AUTHOR.NAME;
        pop.description = props.routine.AUTHOR.DESCRIPTION;
        pop.link = props.routine.AUTHOR.LINK;
        setPopinContent(pop);
    };

    const closeRoutine = () => {
        ref.current.removeEventListener("scroll", handleScroll);
        props.handleClose();
    };

    const resetPopin = () => {
        setPopinContent({ ...emptyPopin });
    };

    const mapProducts = (rituals) => {
        var onlySteps = [];
        for (var index = 0; index < rituals.length; index++) {
            for (var jndex = 0; jndex < rituals[index].STEPS.length; jndex++) {
                onlySteps.push(rituals[index].STEPS[jndex]);
            }
        }
        console.log(onlySteps);
        const onlyProducts = onlySteps
            .filter((step) => step.ADVICE_TYPE === "PRODUCT")
            .map((step) => {
                return { NAME: step.ADVICE_TITLE, PIC: step.PIC };
            });
        console.log(onlyProducts);
        const result = onlyProducts.filter(
            (value, index, self) =>
                index === self.findIndex((t) => t.NAME === value.NAME)
        );
        return result;
    };

    useEffect(() => {
        if (
            mireilleUser !== null &&
            mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED"
        ) {
            setDisplayPaywall(false);
        }
    }, [mireilleUser]);

    const AskForPurchase = () => {
        //trackStandardEvent("AddToCart", {});
        setDisplayPaywall(true);
    };

    return (
        <>
            {displayPaywall ? (
                <NewPaywall
                    forceConnexion={false}
                    onSuccessUrl={props.redirect}
                    onCancelUrl={props.redirect}
                    canClose={true}
                    handleClose={() => setDisplayPaywall(false)}
                />
            ) : (
                <Dialog
                    fullScreen
                    onClose={closeRoutine}
                    open={props.routine !== null}
                    PaperProps={{
                        style: {
                            overflowX: "hidden",
                            heigth: "100%",
                            background:
                                "linear-gradient(90deg, #d3208b, #879af2 48%, #4dc2b3 )"
                        }
                    }}
                >
                    <>
                        <RoutineElementPrompt
                            content={popinContent}
                            open={popinContent.open}
                            close={resetPopin}
                        />
                        <Box className={sStyles.simpleLayout} ref={ref}>
                            <RitualHeader
                                title={props.routine.TITLE}
                                displayAuthor={displayAuthor}
                                sticky={sticky}
                                closeRoutine={closeRoutine}
                            />

                            {props.routine.RITUALS.map((ritual) => {
                                return (
                                    <RitualElement
                                        ritual={ritual}
                                        displayAllSteps={
                                            mireilleUser !== null &&
                                            mireilleUser.SUBSCRIPTION_STATUS ===
                                                "SUBSCRIBED"
                                        }
                                        triggerPurchase={AskForPurchase}
                                    />
                                );
                            })}
                            <CleanWhiteLink onClick={closeRoutine}>
                                <Typography variant="h2">fermer</Typography>
                            </CleanWhiteLink>
                        </Box>
                        {mireilleUser !== null &&
                            mireilleUser.SUBSCRIPTION_STATUS ===
                                "SUBSCRIBED" && (
                                <Box
                                    className={sStyles.shoppingListMask}
                                    height={listSize + 44 + "px"}
                                >
                                    <Box
                                        className={
                                            sStyles.shoppinListBackgroundHolder
                                        }
                                        height={listSize + 44 + "px"}
                                    >
                                        <Box
                                            className={
                                                sStyles.shoppingListBackground
                                            }
                                            height={listSize + 44 + "px"}
                                        >
                                            <Box
                                                className={
                                                    sStyles.shoppingListContent
                                                }
                                                height={listSize + 44 + "px"}
                                            >
                                                <Box
                                                    height={"44px"}
                                                    className={
                                                        sStyles.shoppingListHeader
                                                    }
                                                    onClick={() =>
                                                        setListOpen(!listOpen)
                                                    }
                                                >
                                                    <img
                                                        src={illus}
                                                        className={
                                                            sStyles.shoppingListIllus
                                                        }
                                                    />
                                                    <Typography variant="h16">
                                                        Liste de courses
                                                    </Typography>
                                                </Box>
                                                {listOpen && productList && (
                                                    <Box
                                                        height={listSize + "px"}
                                                    >
                                                        {productList.map(
                                                            (product) => {
                                                                return (
                                                                    <Box
                                                                        className={
                                                                            sStyles.productItem
                                                                        }
                                                                    >
                                                                        <Box
                                                                            className={
                                                                                sStyles.productItemPicHolder
                                                                            }
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    product.PIC
                                                                                }
                                                                                className={
                                                                                    sStyles.productItemPic
                                                                                }
                                                                            />
                                                                        </Box>
                                                                        <Box
                                                                            className={
                                                                                sStyles.productItemName
                                                                            }
                                                                        >
                                                                            <Typography variant="h13">
                                                                                {
                                                                                    product.NAME
                                                                                }
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            }
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                    </>
                </Dialog>
            )}
        </>
    );
}
