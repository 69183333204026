import * as PARAMETERS from "../../constants/parameters";
import * as SETTINGS from "../../constants/settings";
//import { pushEvent } from "../../components/utils/gtmHandler";
import * as amplitude from "@amplitude/analytics-browser";

var standardHeaders = new Headers();
standardHeaders.append("Content-Type", "application/json");

export const setFirebaseUser = (uid, email) => {
    return async (dispatch, getState) => {
        if (getState().account.firebaseUser === null) {
            dispatch({
                type: PARAMETERS.SET_FIREBASE_USER,
                payload: { FIREBASE_ID: uid, USER_EMAIL: email }
            });
        }
    };
};

export const setPromoCode = (promoCode) => {
    return async (dispatch) => {
        dispatch({
            type: PARAMETERS.SET_PROMO_CODE,
            payload: promoCode
        });
    };
};

export const setMireilleUser = (user) => {
    console.log(user);
    //pushEvent("login", "auth", user.SUBSCRIPTION_STATUS);
    amplitude.track("accessed personal space");

    return async (dispatch) => {
        await dispatch({
            type: PARAMETERS.SET_MIREILLE_USER,
            payload: user
        });
    };
};

export const setConnexionStatus = (status) => {
    return async (dispatch) => {
        await dispatch({
            type: PARAMETERS.SET_CONNEXION_STATUS,
            payload: status
        });
    };
};

export const getOrCreateMireilleUser = () => {
    return async (dispatch, getState) => {
        if (getState().account.mireilleUser === null) {
            var raw = {
                FIREBASE_ID: getState().account.firebaseUser.FIREBASE_ID
            };

            await fetch(SETTINGS.getMireilleUser, {
                method: "POST",
                headers: standardHeaders,
                body: JSON.stringify(raw)
            })
                .then((response) => response.json())
                .then((data) => {
                    dispatch(setMireilleUser(data.USER));
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
};

export const initiateStripePurchase = (
    subscription,
    monthAmount,
    pathSuccess,
    pathCancel,
    forMe
) => {
    return async (dispatch, getState) => {
        var raw = {
            FIREBASE_ID: getState().account.mireilleUser.FIREBASE_ID,
            SUCCESS_URL: SETTINGS.stripeRootReturnUrl + pathSuccess
        };
        raw.CANCEL_URL = SETTINGS.stripeRootReturnUrl + pathCancel;
        if (getState().account.promoCode) {
            raw.PROMO_CODE = getState().account.promoCode;
        }
        if (subscription) {
            raw.MODE = "subscription";
        } else {
            raw.MODE = "payment";
            raw.MONTHS = monthAmount;
            raw.FOR_ME = forMe;
        }
        await fetch(SETTINGS.subscribeWithStripe, {
            method: "POST",
            headers: standardHeaders,
            body: JSON.stringify(raw)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.Status === "Ok") {
                    amplitude.track("accessed subscription page");
                    dispatch({
                        type: PARAMETERS.STRIPE_CHECKOUT_SESSION,
                        payload: data.SESSION
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const getSubscriptionPortal = (path) => {
    return async (dispatch, getState) => {
        var raw = JSON.stringify({
            FIREBASE_ID: getState().account.mireilleUser.FIREBASE_ID,
            RETURN_URL: SETTINGS.stripeRootReturnUrl + path
        });
        await fetch(SETTINGS.getStripePortal, {
            method: "POST",
            headers: standardHeaders,
            body: raw
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch({
                    type: PARAMETERS.STRIPE_PORTAL_SESSION,
                    payload: data.BILLING_PORTAL
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const getAccessUsingCode = (code) => {
    return async (dispatch, getState) => {
        var raw = JSON.stringify({
            FIREBASE_ID: getState().account.mireilleUser.FIREBASE_ID,
            COUPON: code
        });
        await fetch(SETTINGS.consumeCoupon, {
            method: "POST",
            headers: standardHeaders,
            body: raw
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.Status === "Ok") {
                    dispatch(getOrCreateMireilleUser());
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
};
