import specificStyles from "../Home.module.css";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import frFr from "../../../../constants/localisations/fr-Fr";

import { WhiteCTA } from "../../../MireilleCTAS";
import { Typography } from "@mui/material";

const appStoreCTA = "images/home/appStoreCTA.png";
const playStoreCTA = "images/home/playStoreCTA.png";
const iosOptionLogo = "images/mireilleOptions/iosOptionLogo.png";
const androidOptionLogo = "images/mireilleOptions/androidOptionLogo.png";
const mireilleTradOptionLogo =
    "images/mireilleOptions/mireilleTradOptionLogo.png";
const fullExampleIllus = "images/home/fullExampleIllus.png";

export default function Sales(props) {
    return (
        <Box className={specificStyles.Slide1} id="bob">
            <Box className={specificStyles.pageHeart}>
                <Grid container spacing={0}>
                    {!isMobile && (
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            className={specificStyles.ImageHolder}
                        >
                            <img
                                className={specificStyles.fullExample}
                                src={fullExampleIllus}
                                alt=""
                            />
                        </Grid>
                    )}
                    {isMobile && <Grid item xs={1}></Grid>}
                    <Grid
                        item
                        xs={isMobile ? 12 : 5}
                        className={specificStyles.TextHolder}
                    >
                        <Box className={specificStyles.HomeTitle}>
                            {isMobile ? (
                                <Typography variant="h16">
                                    {frFr.screen.homescreen.SALES_APP_TITLE}
                                </Typography>
                            ) : (
                                <>{frFr.screen.homescreen.SALES_APP_TITLE}</>
                            )}
                        </Box>
                        <Box className={specificStyles.HomeContent}>
                            {frFr.screen.homescreen.SALES_APP}
                        </Box>

                        {props.displayStoreCtas && (
                            <Box className={specificStyles.CTAHolder}>
                                <img
                                    className={specificStyles.storeCTA}
                                    src={appStoreCTA}
                                    alt=""
                                    onClick={() => {
                                        window.location.href =
                                            frFr.general.iosLink;
                                    }}
                                />
                                <img
                                    className={specificStyles.storeCTA}
                                    src={playStoreCTA}
                                    alt=""
                                    onClick={() => {
                                        window.location.href =
                                            frFr.general.androidLink;
                                    }}
                                />
                            </Box>
                        )}
                        {props.displaySubscribeCTA && (
                            <>
                                <Box
                                    className={
                                        isMobile
                                            ? specificStyles.OptionsImageHolderCenter
                                            : specificStyles.OptionsImageHolder
                                    }
                                >
                                    <img
                                        className={specificStyles.iosOption}
                                        src={iosOptionLogo}
                                        alt=""
                                    />
                                    <img
                                        className={specificStyles.androidOption}
                                        src={androidOptionLogo}
                                        alt=""
                                    />
                                    <img
                                        className={
                                            specificStyles.mireilleTradOption
                                        }
                                        src={mireilleTradOptionLogo}
                                        alt=""
                                    />
                                </Box>
                                <Box
                                    className={
                                        isMobile
                                            ? specificStyles.ImageHolderCenter
                                            : specificStyles.ImageHolder
                                    }
                                >
                                    <WhiteCTA onClick={props.launchSusbscribe}>
                                        Je m'abonne
                                    </WhiteCTA>
                                </Box>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
