import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { useDispatch, useSelector } from "react-redux";
import Paywall from "../../subscriptionHandler/Paywall/Paywall";
import Footer from "../home/components/Footer";
import { Box } from "@mui/material";
import specificStyles from "./Account.module.css";
import ManageAccountSection from "./components/ManageAccountSection";
import AccessCouponSection from "./components/AccessCouponsSection";
import MySubscriptionSection from "./components/MySubscriptionSection";

//import { pushPageView } from "../../utils/gtmHandler";

export default function Account() {
    const { mireilleUser } = useSelector((state) => state.account);

    /* useEffect(() => {
        pushPageView("account");
    }, []);*/

    return (
        <>
            <Paywall
                displayOfferMireille={true}
                forceConnexion={false}
                displayHeader={mireilleUser !== null ? false : true}
                displayOffers={mireilleUser !== null ? true : false}
                payWallTitle={
                    "Pour voir les infos de ton compte, tu dois te connecter !"
                }
                payWallOffersTitle={
                    mireilleUser !== null &&
                    mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED"
                        ? "Et voilà les options pour offrir Mireille à tes proches !"
                        : "Et les options pour accéder à Mireille !"
                }
                onSuccessUrl="/account"
                onCancelUrl="/account"
            >
                <Box className={specificStyles.pageHeart}>
                    <ManageAccountSection />
                    {mireilleUser && (
                        <>
                            {mireilleUser && <MySubscriptionSection />}
                            {mireilleUser &&
                                mireilleUser.COUPONS.length > 0 && (
                                    <AccessCouponSection />
                                )}
                        </>
                    )}
                </Box>
            </Paywall>
        </>
    );
}
