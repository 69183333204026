import React, { useEffect, useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useParams,
    useNavigate
} from "react-router-dom";
import MireilleTradWrapper from "./components/pages/trad/MireilleTradWrapper";
import Offers from "./components/pages/offers/Offers";
import Home from "./components/pages/home/Home";
import specificStyles from "./App.module.css";
import { Box } from "@mui/material";
import Menu from "./components/Menu";
import { isMobile } from "react-device-detect";
import PromotionPage from "./components/pages/home/PromotionPage";

//import TagManager from "react-gtm-module";
import * as PARAMETERS from "./constants/parameters";
import {
    getAuth,
    onAuthStateChanged,
    onIdTokenChanged,
    signOut
} from "firebase/auth";

import { setFirebaseUser } from "./redux/actions/account";
import { useSelector, useDispatch } from "react-redux";

import AuthManager from "./components/auth/AuthManager";
import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from "@mui/material/styles";
import WorkSansRegular from "./assets/fonts/WorkSans-Regular.ttf";
import WorkSansBold from "./assets/fonts/WorkSans-Bold.ttf";
//import WorkSansItalic from "./assets/fonts/WorkSans-Italic.ttf";
//import WorkSansLight from "./assets/fonts/WorkSans-Light.ttf";
//import WorkSansSemiBold from "./assets/fonts/WorkSans-Medium.ttf";
import Account from "./components/pages/account/Account";
import Press from "./components/pages/press/Press";
import FormProfile from "./components/pages/formProfile/FormProfile";
import RoutineList from "./components/pages/routines/pages/RoutineList";
import LandingPage from "./components/pages/home/LandingPage";
import RoutineLandingPage from "./components/pages/routines/pages/RoutineLandingPage";
//import { trackStandardEvent } from "./components/utils/gtmHandler";

//old buildpack https://buildpack-registry.s3.amazonaws.com/buildpacks/mars/create-react-app.tgz

const titleIllusWhite = "images/home/titleIllusWhite.png";

const worksansregular = {
    fontFamily: "WorkSansRegular",
    src: `
    local('WorkSansRegular'),
    url(${WorkSansRegular}) format('ttf')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};

const worksansbold = {
    fontFamily: "WorkSansBold",
    src: `
    local('WorkSansBold'),
    url(${WorkSansBold}) format('ttf')
  `,
    unicodeRange:
        "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
};
export default function App() {
    let theme = createTheme({
        components: {
            MuiInputLabel: {
                filled: {
                    styleOverrides: {
                        root: {
                            border: "1px solid black",
                            width: 80,
                            height: 80,
                            borderRadius: 8
                        }
                    }
                }
            },
            MuiContainer: {
                styleOverrides: {
                    root: {
                        border: "1px solid black",
                        width: 80,
                        height: 80,
                        borderRadius: 8
                    }
                }
            }
        }
    });

    //used for minor caps CTAs

    theme.typography.h10 = {
        fontFamily: "WorkSansBold",
        fontSize: "0.75rem",
        letterSpacing: "0.009rem"
    };

    //used for decor items
    theme.typography.body12 = {
        fontFamily: "WorkSansRegular",
        fontSize: "10.5pt",
        letterSpacing: "0,23pt"
    };

    theme.typography.h12 = {
        fontFamily: "WorkSansBold",
        fontSize: "0.875rem",
        letterSpacing: "0.019rem"
    };

    //used for plain descriptions items
    theme.typography.body13 = {
        fontFamily: "WorkSansRegular",
        fontSize: "0.938rem",
        lineHeight: "1.125rem",
        letterSpacing: "0.023rem"
    };

    theme.typography.h13 = {
        fontFamily: "WorkSansBold",
        fontSize: "0.938rem",
        lineHeight: "1.188rem",
        letterSpacing: "0.01188rem"
    };

    //used for title items
    theme.typography.h16 = {
        fontFamily: "WorkSansBold",
        fontSize: "1.375rem",
        letterSpacing: "0.033rem"
    };

    //used for major title items
    theme.typography.h17 = {
        fontFamily: "WorkSansBold",
        fontSize: "0.938rem",
        lineHeight: "1.125rem",
        letterSpacing: "0.023rem"
    };

    /*{
    typography: {
      fontFamily: 'WorkSansRegular, WorkSansBold',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [worksansregular],
        },
      },
    },
  }*/

    theme.typography.h4 = {
        fontFamily: "WorkSansBold",
        fontSize: "1.1rem",
        "@media (min-width:450px)": {
            fontSize: "1.3rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1.4rem"
        }*/
    };
    theme.typography.h5 = {
        fontFamily: "WorkSansBold",
        fontSize: "1rem",
        "@media (min-width:450px)": {
            fontSize: "1.1rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1.2rem"
        }*/
    };
    theme.typography.h6 = {
        fontFamily: "WorkSansRegular",
        fontSize: "1.4rem",
        "@media (min-width:450px)": {
            fontSize: "1.6rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1.8rem"
        }*/
    };

    theme.typography.h7 = {
        fontFamily: "WorkSansBold",
        fontSize: "1.4rem",
        "@media (min-width:450px)": {
            fontSize: "1.6rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1.8rem"
        }*/
    };
    theme.typography.h8 = {
        fontFamily: "WorkSansBold",
        fontSize: "1.6rem",
        "@media (min-width:450px)": {
            fontSize: "1.9rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1.8rem"
        }*/
    };
    theme.typography.h3 = {
        fontFamily: "WorkSansRegular",
        fontSize: "1rem",
        "@media (min-width:450px)": {
            fontSize: "1.1rem"
        }
    };
    theme.typography.h2 = {
        fontFamily: "WorkSansBold",
        fontSize: "0.8rem",
        letterSpacing: "0.015rem",
        "@media (min-width:450px)": {
            fontSize: "0.9rem",
            letterSpacing: "0.017rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1rem",
            letterSpacing: "0.019rem"
        }*/
    };
    theme.typography.body1 = {
        fontFamily: "WorkSansRegular",
        fontSize: "0.8rem",
        "@media (min-width:450px)": {
            fontSize: "0.9rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1rem"
        }*/
    };
    theme.typography.body2 = {
        fontFamily: "WorkSansRegular",
        fontSize: "0.8rem",
        "@media (min-width:450px)": {
            fontSize: "0.9rem"
        }
        /*[theme.breakpoints.up("md")]: {
            fontSize: "1rem"
        }*/
    };
    //theme = responsiveFontSizes(theme);

    const dispatch = useDispatch();
    const { stripePortalSession, firebaseUser } = useSelector(
        (state) => state.account
    );

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            getAuth(),
            function (UserCredentialImpl) {
                console.log(UserCredentialImpl);
                if (firebaseUser === null) {
                    if (UserCredentialImpl && UserCredentialImpl.user) {
                        dispatch(
                            setFirebaseUser(
                                UserCredentialImpl.user.uid,
                                UserCredentialImpl.user.email
                            )
                        );
                    } else {
                    }
                }
            }
        );

        const idChangedUnsubscribe = onIdTokenChanged(
            getAuth(),
            function (UserImpl) {
                if (firebaseUser === null) {
                    if (UserImpl) {
                        dispatch(setFirebaseUser(UserImpl.uid, UserImpl.email));
                    } else {
                    }
                }
            }
        );

        // unsubscribing from the listener when the component is unmounting.
        return { unsubscribe, idChangedUnsubscribe };
    }, []);

    useEffect(() => {
        if (stripePortalSession) {
            window.location.href = stripePortalSession.url;
        }
    }, [stripePortalSession]);

    /*const tagManagerArgs = {
        gtmId: "GTM-KWVS8XQ"
    };*/

    /*useEffect(() => {
        //const isConsent = getCookieConsentValue();
        //if (isConsent === "true") {
        handleAcceptCookie();
        // } 
    }, []);*/

    /*const handleAcceptCookie = () => {
        TagManager.initialize(tagManagerArgs);
        //ReactPixel.grantConsent();
    };*/

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {/*<CookieConsent
                    enableDeclineButton
                    onAccept={handleAcceptCookie}
                    buttonText="c'est d'accord"
                    declineButtonText="Je ne préfère pas"
                    style={{
                        background: "#d3208b",
                        fontFamily: "WorkSansRegular",
                        color: "white"
                    }}
                    buttonStyle={{
                        height: "35pt",
                        minWidth: "100pt",
                        borderRadius: "5pt",
                        fontFamily: "WorkSansBold",
                        color: "black",
                        background: "#FFE71C"
                    }}
                    declineButtonStyle={{
                        height: "35pt",
                        minWidth: "100pt",
                        borderRadius: "5pt",
                        fontFamily: "WorkSansBold",
                        color: "white",
                        background: "#301366"
                    }}
                >
                    Mireille utilise des cookies optionnels de mesure d'audience
                    destinés à améliorer le site et ses fonctionnalités.
                </CookieConsent>*/}
                <AuthManager />
                <Router>
                    <Routes>
                        <Route
                            path="/landing/:promo_code"
                            element={<Child />}
                        />
                        <Route
                            path="/promo:promo_code"
                            element={<PromoPage />}
                        />
                        <Route
                            path="/routine_:deep_link"
                            element={<RoutinePage />}
                        />
                        <Route
                            path="/SUCCESS_:route"
                            element={<SuccessPage />}
                        />
                        <Route path="/logout" element={<LocalLogout />} />
                        <Route path="*" element={<MainMenu />} />
                    </Routes>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

//<Route path="/landing/:promo_code" children={<Child />}/>

function Child() {
    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { promo_code } = useParams();

    return <PromotionPage promoCode={promo_code} />;
}

function PromoPage() {
    let { promo_code } = useParams();
    return <LandingPage promoCode={promo_code} />;
}

function RoutinePage() {
    let { deep_link } = useParams();
    return <RoutineLandingPage deepLink={deep_link} />;
}

function SuccessPage() {
    const navigate = useNavigate();
    let { route } = useParams();
    //"Subscribe", {};
    navigate("/" + route, { replace: true });
    return <></>;
}

function MainMenu() {
    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);
    return (
        <>
            <Box className={specificStyles.mireilleHeader}>
                <Box className={specificStyles.illusHolder}>
                    <img
                        className={specificStyles.logoImage}
                        src={titleIllusWhite}
                        alt=""
                    />
                </Box>
                {isMobile ? (
                    <>
                        <Box
                            className={specificStyles.mireilleHamburgerMenu}
                            onClick={() => {
                                setDisplayMobileMenu(!displayMobileMenu);
                            }}
                        >
                            <svg viewBox="0 0 100 80" width="4vh" height="4vh">
                                <rect
                                    width="10vh"
                                    height="2vh"
                                    fill="white"
                                ></rect>
                                <rect
                                    y="3vh"
                                    width="10vh"
                                    height="2vh"
                                    fill="white"
                                ></rect>
                                <rect
                                    y="6vh"
                                    width="10vh"
                                    height="2vh"
                                    fill="white"
                                ></rect>
                            </svg>
                        </Box>
                        {displayMobileMenu && (
                            <Box className={specificStyles.mobileMenuHolder}>
                                <Menu
                                    closeMenu={() =>
                                        setDisplayMobileMenu(!displayMobileMenu)
                                    }
                                />
                            </Box>
                        )}
                    </>
                ) : (
                    <Box className={specificStyles.mireilleMenu}>
                        <Menu closeMenu={() => console.log("")} />
                    </Box>
                )}
            </Box>

            <Routes>
                <Route
                    path="/mireille-trad"
                    element={<MireilleTradWrapper />}
                />
                <Route path="/offres" element={<Offers />} />
                <Route path="/home" element={<Home />} />
                <Route path="/account" element={<Account />} />
                <Route path="/press" element={<Press />} />
                <Route path="/profile" element={<FormProfile />} />
                <Route path="/routines" element={<RoutineList />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </>
    );
}

function LocalLogout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    signOut(getAuth());
    dispatch({
        type: PARAMETERS.DISCONNECT,
        payload: ""
    });
    navigate("/home", { replace: true });
    return <></>;
}

/*
<Home/>
function Topics() {
  let match = useRouteMatch();

  return (
    <div>
      <h2>Topics</h2>

      <ul>
        <li>
          <Link to={`${match.url}/components`}>Components</Link>
        </li>
        <li>
          <Link to={`${match.url}/props-v-state`}>
            Props v. State
          </Link>
        </li>
      </ul>

      {}
      <Switch>
        <Route path={`${match.path}/:topicId`}>
          <Topic />
        </Route>
        <Route path={match.path}>
          <h3>Please select a topic.</h3>
        </Route>
      </Switch>
    </div>
  );
}

function Topic() {
  let { topicId } = useParams();
  return <h3>Requested topic ID: {topicId}</h3>;
}*/
