import React, {useState} from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import specificStyles from "../Account.module.css"
import { getSubscriptionPortal } from '../../../../redux/actions/account.js'
import { StandardCleanCTA } from '../../../MireilleCTAS.js'
import EnterCodePopin from './prompts/EnterCodePopin'

export default function MySubscriptionSection() {
    var [enterCode, setEnterCode] = useState(false)
    const dispatch = useDispatch()

    const onOpenProfile = () => {
        dispatch(getSubscriptionPortal())
      }

    const { mireilleUser } = useSelector((state) => state.account)
    return (
        <>
            <EnterCodePopin enterCode={enterCode} handleClose={() => setEnterCode(false)}/>
            <Box className={specificStyles.salesBlock}>
                <Typography variant="h5" className={specificStyles.manageDataTitleHolder}>
                    Mon abonnement :
                </Typography>
                {mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED" ?
                    <>
                    {mireilleUser.PLATFORM === "STRIPE" &&
                        <>
                            <Box className={[specificStyles.manageDataSubTitleHolder, specificStyles.expand]}>
                                <Typography variant="body1">
                                    Tu as souscrit à un abonnement sur le site mireille.co (ici même)
                                </Typography>
                            </Box>
                            <Box  className = {specificStyles.ctaZone} >
                                <Box className = {specificStyles.ctaHolder}>
                                    <StandardCleanCTA onClick={()=> onOpenProfile()}>
                                        <Typography variant="h3">
                                            Gérer
                                        </Typography>
                                    </StandardCleanCTA>
                                </Box>
                            </Box>
                        </>
                    }
                    {mireilleUser.PLATFORM === "APPLE" &&
                        <Box className={specificStyles.manageDataSubTitleHolder}>
                            <Typography variant="body1">
                                Tu es abonné à Mireille via le système de paiements d'Apple. Pour gérer ton abonnement utilises l'application Mireille pour Iphone ! 
                            </Typography>
                        </Box>
                    }
                    {mireilleUser.PLATFORM === "GOOGLE" &&
                        <Box className={specificStyles.manageDataSubTitleHolder}>
                            <Typography variant="body1">
                                Tu es abonné à Mireille via le système de paiements de Google. Pour gérer ton abonnement utilises l'application Mireille pour Android ! 
                            </Typography>
                        </Box>
                    }
                    {mireilleUser.PLATFORM === "COUPON" &&
                        <Box className={specificStyles.manageDataSubTitleHolder}>
                            <Typography variant="body1">
                                Tu as accès à Mireille grâce à une formule à durée limitée ! 
                            </Typography>
                        </Box>
                    }
                    </>
                    :
                    <>
                    <Box className={[specificStyles.manageDataSubTitleHolder, specificStyles.expand]}>
                        <Typography variant="body1">
                            Tu n'as pas accès à Mireille. Pour utiliser Mireille tu dois prendre un abonnement, acheter un accès ou utiliser un code cadeau!
                        </Typography>
                    </Box>
                    <Box  className = {specificStyles.ctaZone} >
                        <Box className = {specificStyles.ctaHolder}>
                            <StandardCleanCTA onClick={() => setEnterCode(true)}>
                                <Typography variant="h3">
                                    Utiliser un code
                                </Typography>
                            </StandardCleanCTA>
                        </Box>
                    </Box>
                    </>
                }
                
            </Box>
        </>

    )
}
