import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";

export const BlankCTA = withStyles((theme) => ({
    root: {
        fontSize: "1.7vh",
        fontFamily: ["WorkSansBold"],
        textTransform: "none",
        height: "6vh",
        minWidth: "20vh",
        borderRadius: "1vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}))(Button);

export const StandardCTA = withStyles((theme) => ({
    root: {
        color: "white",
        background: "#4dc2b3",
        "&:hover": {
            background: "#4dc2b3"
        },
        "&:disabled": {
            background: "#cccccc",
            color: "white"
        }
    }
}))(BlankCTA);

export const MainCTA = withStyles((theme) => ({
    root: {
        color: "white",
        background:
            "linear-gradient(45deg, #D3208B 0%, #879AF2 50%, #4DC2B3 100%)",
        "&:hover": {
            background:
                "linear-gradient(45deg, #D3208B 0%, #879AF2 50%, #4DC2B3 100%)"
        }
    }
}))(BlankCTA);

export const WhiteCTA = withStyles((theme) => ({
    root: {
        background: "white",
        color: "#301366",
        "&:hover": {
            background: "white"
        }
    }
}))(BlankCTA);

export const CleanLink = withStyles((theme) => ({
    root: {
        textTransform: "none",
        textDecoration: "underline",
        height: "20pt",
        margin: "0pt",
        padding: "0pt",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#301366",
        background: "transparent",
        "&:hover": {
            background: "transparent"
        },
        "&:disabled": {
            background: "transparent"
        }
    }
}))(Button);

export const CleanWhiteLink = withStyles((theme) => ({
    root: {
        color: "white"
    }
}))(CleanLink);

export const CleanGreenLink = withStyles((theme) => ({
    root: {
        color: "#4dc2b3"
    }
}))(CleanLink);

export const CleanCTA = withStyles((theme) => ({
    root: {
        textTransform: "none",
        height: "35pt",
        minWidth: "120pt",
        borderRadius: "5pt",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}))(Button);

export const StandardCleanCTA = withStyles((theme) => ({
    root: {
        color: "white",
        background: "#4dc2b3",
        "&:hover": {
            background: "#4dc2b3"
        },
        "&:disabled": {
            background: "#cccccc",
            color: "white"
        }
    }
}))(CleanCTA);

export const StandardWhiteCTA = withStyles((theme) => ({
    root: {
        background: "white",
        color: "#301366",
        "&:hover": {
            background: "white"
        },
        "&:disabled": {
            background: "#cccccc",
            color: "white"
        }
    }
}))(CleanCTA);

export const StandardYellowCTA = withStyles((theme) => ({
    root: {
        color: "black",
        background: "#FFE71C",
        "&:hover": {
            background: "#FFE71C"
        },
        "&:disabled": {
            background: "#cccccc",
            color: "black"
        }
    }
}))(CleanCTA);

export const MainCleanCTA = withStyles((theme) => ({
    root: {
        color: "white",
        background:
            "linear-gradient(45deg, #D3208B 0%, #879AF2 50%, #4DC2B3 100%)",
        "&:hover": {
            background:
                "linear-gradient(45deg, #D3208B 0%, #879AF2 50%, #4DC2B3 100%)"
        },
        "&:disabled": {
            opacity: 0.3,
            color: "white",
            background:
                "linear-gradient(45deg, #D3208B 0%, #879AF2 50%, #4DC2B3 100%)"
        }
    }
}))(CleanCTA);
