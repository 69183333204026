//parameters for the account handling actions

export const SET_FIREBASE_USER = "SET_FIREBASE_USER";
export const SET_MIREILLE_USER = "SET_MIREILLE_USER";
export const SET_USER_SUBSCRIPTION_LIST = "SET_USER_SUBSCRIPTION_LIST";
export const SET_STRIPE_CLIENT_ID = "SET_STRIPE_CLIENT_ID";
export const DISCONNECT = "DISCONNECT";
export const SET_USER_SUBSCRIPTION_STATUS = "SET_USER_SUBSCRIPTION_STATUS";
export const STRIPE_CHECKOUT_SESSION = "STRIPE_CHECKOUT_SESSION";
export const STRIPE_PORTAL_SESSION = "STRIPE_PORTAL_SESSION";
export const SET_PROMO_CODE = "SET_PROMO_CODE";
export const SET_FIRST_NAME = "SET_FIRST_NAME";
export const SET_CONNEXION_STATUS = "SET_CONNEXION_STATUS";

//parameters for the hair profile and routines actions

export const SET_HAIR_MATRIX = "SET_HAIR_MATRIX";
