import React, { useEffect, useState } from "react";
import sStyles from "../../Routines.module.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Dialog } from "@mui/material";
import StepElement from "./StepElement";
import { CalendarTodayOutlined } from "@mui/icons-material";

export default function RitualElement(props) {
    const [currentIndex, setCurrentIndex] = useState(0);

    return (
        <Box className={sStyles.ritualBlock}>
            <Box className={sStyles.ritualBlockContent}>
                <Box component="span" className={sStyles.ritualTitle}>
                    <Typography variant="h16">{props.ritual.TITLE}</Typography>
                </Box>
                <Typography variant="body13">
                    {props.ritual.DESCRIPTION}
                </Typography>

                <Box className={sStyles.ritualRules}>
                    <CalendarTodayOutlined fontSize="small" />
                    <Box className={sStyles.ritualFreq}>
                        <Typography variant="h12">
                            {props.ritual.FREQUENCY.DISPLAY}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <StepElement
                step={props.ritual.STEPS[currentIndex]}
                index={currentIndex}
                toIndex={setCurrentIndex}
                maxIndex={props.ritual.STEPS.length}
                displayAllSteps={props.displayAllSteps}
                triggerPurchase={props.triggerPurchase}
            />
        </Box>
    );
}
