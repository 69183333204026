import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import specificStyles from "../../Account.module.css";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { StandardConnexionInput } from "../../../../MireilleInputFields";
import { MainCTA } from "../../../../MireilleCTAS";
import { InputAdornment } from "@mui/material";
import { getAccessUsingCode } from "../../../../../redux/actions/account";

const lockPlaceHolder = "images/placeHolders/lock/lockPlaceHolder.png";

export default function EnterCodePopin(props) {
    var [code, setCode] = useState("");
    var [errorMessage, setErrorMessage] = useState(null);
    const codePlaceHolderText = "mon-code-cadeau";
    const dispatch = useDispatch();

    const purgeInputData = () => {
        setErrorMessage(null);
        setCode("");
        props.handleClose();
    };

    const updateCode = (event) => {
        const { value } = event.currentTarget;
        setErrorMessage(null);
        setCode(value);
    };

    const submitCode = () => {
        dispatch(getAccessUsingCode(code));
        purgeInputData();
    };

    return (
        <Dialog open={props.enterCode} onClose={purgeInputData}>
            <Box className={specificStyles.manageAccountPopin}>
                {props.enterCode && (
                    <>
                        <Box className={specificStyles.manageDataTitleHolder}>
                            <Typography variant="h5">
                                Enter un code 🎁
                            </Typography>
                        </Box>

                        {errorMessage && (
                            <Box className={specificStyles.manageDataError}>
                                <Typography variant="body1">
                                    {errorMessage}
                                </Typography>
                            </Box>
                        )}
                        <Box
                            className={specificStyles.manageDataSubTitleHolder}
                        >
                            <Typography variant="body1">
                                Dis moi le code que tu veux utiliser :)
                            </Typography>
                        </Box>
                        <Box>
                            <StandardConnexionInput
                                value={code}
                                disableUnderline={true}
                                onChange={updateCode}
                                multiline={false}
                                placeholder={codePlaceHolderText}
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment
                                        position="start"
                                        className={
                                            specificStyles.imagePlaceHolderBox
                                        }
                                    >
                                        <img
                                            src={lockPlaceHolder}
                                            className={
                                                specificStyles.manageAccountImage
                                            }
                                        />
                                    </InputAdornment>
                                }
                            />
                        </Box>

                        <Box className={specificStyles.ctaZone}>
                            <Box className={specificStyles.ctaHolder}>
                                <MainCTA onClick={() => submitCode()}>
                                    Utiliser ce code
                                </MainCTA>
                            </Box>
                            <Box className={specificStyles.ctaHolder}>
                                <MainCTA onClick={props.handleClose}>
                                    Finalement non
                                </MainCTA>
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Dialog>
    );
}
