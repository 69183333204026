export const paywallColumnTitle = [
    {
        label: "Diagnostic capillaire",
        type: "ITEM"
    },
    {
        label: "Routines personnalisées",
        type: "ITEM"
    },
    {
        label: "Bulet journal - suivi capillaire",
        type: "ITEM"
    },
    {
        label: "Analyse de produits :",
        type: "SECTION"
    },
    {
        label: "Scan",
        type: "ITEM"
    },
    {
        label: "Recherche",
        type: "ITEM"
    },
    {
        label: "Mireille Trad",
        type: "ITEM"
    },
    {
        label: "Salle de bain",
        type: "ITEM"
    }
];

export const paywallColumnBase = [
    {
        key: "OK"
    },
    {
        key: "KO"
    },
    {
        key: "KO"
    },
    {
        key: null
    },
    {
        key: "Limité"
    },
    {
        key: "Limité"
    },
    {
        key: "Limité"
    },
    {
        key: "Limité"
    }
];

export const paywallColumnPremium = [
    {
        key: "OK"
    },
    {
        key: "OK"
    },
    {
        key: "OK"
    },
    {
        key: "Illimité"
    },
    {
        key: "OK"
    },
    {
        key: "OK"
    },
    {
        key: "OK"
    },
    {
        key: "OK"
    }
];

export const reviews = [
    {
        name: "Kenza",
        insta: "@kenzablkn",
        pic: "images/paywall/kenza.png",
        review: "Cette appli est incroyable ! J’ai vu un petit peu sur les réseaux mais je pensais pas à ce point. C’est très bien détaillé, on voit de suite de quoi il s’agit. C’est un excellent outil qui va aider énormément de personnes."
    },
    /* {
        name: "Delphine",
        insta: "@noscheveuxextraordinaires",
        pic: "images/paywall/delphine.png",
        review: ""
    },*/
    {
        name: "Aïcha",
        insta: "@lamamandekahina",
        pic: "images/paywall/aicha.png",
        review: "C’est pas parce que c’est ma fille qui l’a crée, mais vraiment, Mireille c’est super. J’ai maintenant des boucles dont je suis fière ! Merci ma chérie ! (pense à me ramener mes tupperwares quand même)"
    },
    {
        name: "Sarah",
        insta: "@petitecurly",
        pic: "images/paywall/sarah.png",
        review: "C’est vraiment génial ! C’est tous les trucs auxquels on pense pendant des années, on prend des tips à droite à gauche mais il n’y a pas moyen de les centraliser. C’est l’outil dont tu ne savais pas que tu en avais besoin jusqu’à ce que tu le vois ! "
    },
    {
        name: "Amira",
        insta: "@larevolutiondesboucles",
        pic: "images/paywall/amira.png",
        review: "Je trouve l’interface hyper simple et claire. L’idée est très cool et va aider beaucoup de personnes ! Hâte de voir la suite c’est très excitant ! Bravo !"
    }
];
