import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as SETTINGS from "../../constants/settings";
import { initiateStripePurchase } from "../../redux/actions/account";
import sStyles from "./AuthActions.module.css";
import { Dialog, Typography, Box } from "@mui/material";
import ConnexionForm from "./ConnexionForm";
import { useNavigate } from "react-router-dom";
const illus = "images/offers/lvl4.png";

export default function LandingPageOfferHandler(props) {
    const stripe = window.Stripe(SETTINGS.stripePublicKey);
    const navigate = useNavigate();
    var [connect, isConnect] = useState(props.forceConnect);
    var { mireilleUser, stripeCheckoutSession } = useSelector(
        (state) => state.account
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (mireilleUser !== null) {
            isConnect(false);
            if (mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED") {
                goToRedirect();
            } else {
                setTimeout(() => {
                    initiateSubscription();
                }, 500);
            }
        } else {
            isConnect(true);
        }
    }, [mireilleUser]);

    useEffect(() => {
        console.log(stripeCheckoutSession);
        if (stripeCheckoutSession) {
            stripe
                .redirectToCheckout({
                    sessionId: stripeCheckoutSession.id
                })
                .then(handleResult);
        }
    }, [stripeCheckoutSession]);

    const goToRedirect = () => {
        const redirectMe = props.redirectTo ? props.redirectTo : "/account";
        navigate(redirectMe, { replace: true });
    };

    const handleResult = () => {};

    const initiateSubscription = () => {
        const redirectMe = props.redirectTo ? props.redirectTo : "/account";
        dispatch(
            initiateStripePurchase(
                props.subscription,
                props.monthAmount,
                redirectMe,
                window.location.pathname,
                true
            )
        );
        //Todo call endpoint to get a checkout session
    };

    return (
        <>
            {connect ? (
                <ConnexionForm
                    button={props.purchaseButton}
                    intro={props.purchaseIntro}
                />
            ) : (
                <Box className={sStyles.routineListIntroHolder}>
                    <Box className={sStyles.routineListIntro}>
                        <img src={illus} className={sStyles.introIllus} />
                        <Box className={sStyles.routineIntroHolder}>
                            <Typography variant="h5">
                                Coucou Mireille 💜
                            </Typography>
                            <Typography variant="h5">
                                Tu es connectée, on te redirige vers la page de
                                paiement...
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    );
}

/*
<Dialog
                    open={displayUnavailable}
                    onClose={props.cancelPurchase}
                >
                    <Box className={specificStyles.authModal}>
                        <Box className={specificStyles.HomeContentTitleHolder}>
                            <Typography
                                variant="h5"
                                className={
                                    specificStyles.HomeContentTitlePurple
                                }
                            >
                                Tu as déja accès à Mireille, pas besoin d'en
                                rajouter :)
                            </Typography>
                        </Box>
                    </Box>
                </Dialog>*/
