import React from "react";
import specificStyles from "../Press.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

const separator = " : ";

export default function ContactBlock() {
    return (
        <Box className={specificStyles.ContactBrick}>
            <Box className={specificStyles.ContactLine}>
                <Typography variant="h4">Marie Legrand</Typography>
            </Box>
            <Box className={specificStyles.ContactLine}>
                <Typography variant="body1">
                    Agence impulse Communication
                </Typography>
            </Box>
            <Box className={specificStyles.ContactLine}>
                <Typography variant="body1">
                    marie@impulse-communication.fr
                </Typography>
            </Box>
            <Box className={specificStyles.ContactLine}>
                <Typography variant="body1">Tél. 06 09 88 62 27</Typography>
            </Box>
        </Box>
    );
}
