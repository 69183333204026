import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as SETTINGS from "../../constants/settings";
import ConnexionPopin from "./ConnexionPopin";
import { initiateStripePurchase } from "../../redux/actions/account";
import specificStyles from "./AuthActions.module.css";
import { Dialog, Typography, Box } from "@mui/material";

export default function SpecialOfferHandler(props) {
    const stripe = window.Stripe(SETTINGS.stripePublicKey);
    var [connect, isConnect] = useState(props.forceConnect);
    var [displayUnavailable, setDisplayUnavailable] = useState(
        props.forceConnect
    );
    var { mireilleUser, stripeCheckoutSession } = useSelector(
        (state) => state.account
    );

    const dispatch = useDispatch();

    useEffect(() => {
        if (mireilleUser !== null) {
            isConnect(false);
            if (mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED") {
                setDisplayUnavailable(true);
            } else {
                initiateSubscription();
            }
        } else {
            setDisplayUnavailable(false);
            isConnect(true);
        }
    }, [mireilleUser]);

    useEffect(() => {
        if (stripeCheckoutSession) {
            stripe
                .redirectToCheckout({
                    sessionId: stripeCheckoutSession.id
                })
                .then(handleResult);
        }
    }, [stripeCheckoutSession]);

    const handleResult = () => {};

    const initiateSubscription = () => {
        dispatch(
            initiateStripePurchase(
                props.subscription,
                props.monthAmount,
                "/account",
                window.location.pathname,
                true
            )
        );
        //Todo call endpoint to get a checkout session
    };

    return (
        <>
            {connect ? (
                <ConnexionPopin
                    isOpen={connect}
                    canClose={true}
                    handleClose={props.cancelPurchase}
                />
            ) : (
                <Dialog
                    open={displayUnavailable}
                    onClose={props.cancelPurchase}
                >
                    <Box className={specificStyles.authModal}>
                        <Box className={specificStyles.HomeContentTitleHolder}>
                            <Typography
                                variant="h5"
                                className={
                                    specificStyles.HomeContentTitlePurple
                                }
                            >
                                Tu as déja accès à Mireille, pas besoin d'en
                                rajouter :)
                            </Typography>
                        </Box>
                    </Box>
                </Dialog>
            )}
        </>
    );
}
