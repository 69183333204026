import React, {useState, useEffect} from 'react'
import MireilleTrad from "./MireilleTrad"
import { useSelector} from 'react-redux'
import AuthActionsWrapper from '../../subscriptionHandler/AuthActionsWrapper'

import { Paper, Box } from '@mui/material'
import specificStyles from '../../subscriptionHandler/AuthActions.module.css'
import Paywall from '../../subscriptionHandler/Paywall/Paywall'
export default function MireilleTradWrapper(props) {

    const { mireilleUser} = useSelector((state) => state.account)

    
//IF user is subscribed -> Display 
    return(
        <>
            {mireilleUser && mireilleUser.SUBSCRIPTION_STATUS === "SUBSCRIBED" ?
                <MireilleTrad/>
            :
                <Paywall 
                    displayOfferMireille={false}
                    displayOffers={true} 
                    forceConnexion={true}
                    onSuccessUrl="/mireille-trad"
                    onCancelUrl="/mireille-trad"
                    displayHeader={false}
                    payWallTitle={"Pour accéder à Mireille trad il te faut un accès !"}
                    payWallOffersTitle={"Pour accéder à Mireille trad il te faut un accès !"}/>
            
            }
        </>
    )
}

/*<Paper className={specificStyles.authPaper}>
                <Box className={specificStyles.authModal}>
                    <AuthActionsWrapper displayOfferMireille={false} forceConnexion={true}/>
                </Box>
            </Paper>*/