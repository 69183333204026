import React from "react";
import Dialog from "@mui/material/Dialog";
import specificStyles from "../Trad.module.css";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import ImageMap from "../../../../constants/ImageMap";
import { Typography } from "@mui/material";

import { isMobile } from "react-device-detect";

export default function IngredientDetailModal(props) {
    let illus = "";
    const portraitMireille = ImageMap.illustrations.portraits.mireille;
    if (props.ingredient.INGREDIENT_GRADE === "good") {
        illus = ImageMap.grade.Good;
    } else if (props.ingredient.INGREDIENT_GRADE === "neutral") {
        illus = ImageMap.grade.Neutral;
    } else if (props.ingredient.INGREDIENT_GRADE === "allergene") {
        illus = ImageMap.grade.Allergene;
    } else if (props.ingredient.INGREDIENT_GRADE === "avoid") {
        illus = ImageMap.grade.Attention;
    } else if (props.ingredient.INGREDIENT_GRADE === "danger") {
        illus = ImageMap.grade.Danger;
    } else {
        illus = illus = ImageMap.grade.Empty;
    }

    const handleClose = () => {
        props.closeSelf();
    };

    return (
        <Dialog
            open={props.isOpen}
            onClose={handleClose}
            PaperProps={{
                style: {
                    borderRadius: (isMobile ? 0.8 : 1) * 25 + "pt",
                    maxWidth: "100%",
                    margin: "auto"
                }
            }}
        >
            <Box className={specificStyles.ingredientDetailBox}>
                <Grid container spacing={0}>
                    <Grid item xs={11}>
                        <Box className={specificStyles.IngredientDetailTitle}>
                            <Typography variant="h7">
                                {props.ingredient.INGREDIENT_NAME}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={1} className={specificStyles.IconHolder}>
                        <img
                            className={specificStyles.gradeHolder}
                            src={illus}
                            alt=""
                        />
                    </Grid>
                </Grid>
                <Box>
                    {props.ingredient.DESCRIPTION !== "" && (
                        <Box className={specificStyles.detailsHolder}>
                            <Typography variant="h4">
                                Qu'est ce que c'est ?
                            </Typography>
                            <Box className={specificStyles.detailsContent}>
                                <Typography variant="body2">
                                    {props.ingredient.DESCRIPTION}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {props.ingredient.ADVICE_RAPH !== "" && (
                        <Box className={specificStyles.detailsHolder}>
                            <Box className={specificStyles.adviceTitle}>
                                <img
                                    className={specificStyles.advicePortrait}
                                    src={portraitMireille}
                                    alt=""
                                />
                                <Typography variant="h4">
                                    l'analyse de Mireille
                                </Typography>
                            </Box>
                            <Box className={specificStyles.detailsContent}>
                                <Typography variant="body2">
                                    {props.ingredient.ADVICE_RAPH}
                                </Typography>
                            </Box>
                        </Box>
                    )}
                    {/* props.ingredient.ADVICE_GABY !== "" && (
                        <Box className={specificStyles.detailsHolder}>
                            <Box className={specificStyles.adviceTitle}>
                                <img
                                    className={specificStyles.advicePortrait}
                                    src={portraitGaby}
                                    alt=""
                                />
                                <Typography variant="h4">
                                    le conseil de Gaby
                                </Typography>
                            </Box>
                            <Box className={specificStyles.detailsContent}>
                                <Typography variant="body2">
                                    {props.ingredient.ADVICE_GABY}
                                </Typography>
                            </Box>
                        </Box>
                    )*/}
                    {props.ingredient.INCI_FUNCTIONS.length > 0 && (
                        <Box className={specificStyles.detailsHolder}>
                            <Typography variant="h4">
                                Fonctions INCI:
                            </Typography>
                            {props.ingredient.INCI_FUNCTIONS.map(
                                (inciFunction) => {
                                    return (
                                        <Box
                                            className={
                                                specificStyles.detailsContent
                                            }
                                        >
                                            <Typography variant="body2">
                                                {inciFunction.TITLE} :{" "}
                                                {inciFunction.DESCRIPTION}
                                            </Typography>
                                        </Box>
                                    );
                                }
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
}

/*<DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
<div style={getModalStyle()} className={classes.paper}>
                    <Box className={myStyles.legendModalHeader}>
                        <Box className={myStyles.ingredientGradeIcon}>
                            <img src={icon} alt="" className={myStyles.ingredientGradeIcon}/>
                        </Box>
                        <Box className={myStyles.gradeText}>{this.props.legendName}</Box>
                        <Box className={myStyles.description}>
                            <Box className={myStyles.gradeDescription}>
                                {description}
                            </Box>
                        </Box>
                    </Box>
                </div>*/
