import React from "react";
import Dialog from "@mui/material/Dialog";
import sStyles from "../Routines.module.css";
import { Box, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";
import { CleanLink } from "../../../MireilleCTAS";

export default function RoutineElementPrompt(props) {
    console.log(props.content.link);

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            PaperProps={{
                style: { borderRadius: (isMobile ? 0.8 : 1) * 25 + "pt" }
            }}
        >
            <Box className={sStyles.routineElementCard}>
                {props.content.pic && (
                    <Box className={sStyles.promptPicHolder}>
                        <img
                            className={sStyles.promptPic}
                            src={props.content.pic}
                            alt=""
                        />
                    </Box>
                )}

                <Box className={sStyles.routineElementTitle}>
                    <Typography variant="h5">{props.content.title}</Typography>
                </Box>

                <Box className={sStyles.routineElementDescription}>
                    <Typography variant="body2" multiline>
                        {props.content.description}
                    </Typography>
                </Box>
                {props.content.link && (
                    <a
                        className={sStyles.outLink}
                        href={props.content.link}
                        target="_blank"
                    >
                        <Typography variant="h2">En savoir plus</Typography>
                    </a>
                )}
            </Box>
        </Dialog>
    );
}
