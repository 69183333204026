import specificStyles from "../Home.module.css";
import frFr from "../../../../constants/localisations/fr-Fr";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { MainCTA } from "../../../MireilleCTAS";

const portraitRaph = "images/home/portraitRaph2.png";
const portraitGaby = "images/home/portraitGaby2.png";

export default function Experts(props) {
    return (
        <Box className={specificStyles.Slide2} id="experts">
            <Box className={specificStyles.pageHeart}>
                <Box className={specificStyles.columnDisplay}>
                    <Box className={specificStyles.TextHolder}>
                        <Box className={specificStyles.HomeTitlePurple}>
                            {frFr.screen.homescreen.SALES_EXPERTS_TITLE}
                        </Box>
                    </Box>
                    <Grid container spacing={0}>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            className={specificStyles.picPlusLegendHolder}
                        >
                            <Box className={specificStyles.ImageHolder}>
                                <img
                                    className={specificStyles.portrait}
                                    src={portraitGaby}
                                    alt=""
                                />
                            </Box>
                            <Box className={specificStyles.ExpertLegendTitle}>
                                {
                                    frFr.screen.homescreen
                                        .SALES_EXPERTS_GABY_TITLE
                                }
                            </Box>
                            <Box className={specificStyles.ExpertLegend}>
                                {frFr.screen.homescreen.SALES_EXPERTS_GABY}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            sm={6}
                            xs={12}
                            className={specificStyles.picPlusLegendHolder}
                        >
                            <Box className={specificStyles.ImageHolder}>
                                <img
                                    className={specificStyles.portrait}
                                    src={portraitRaph}
                                    alt=""
                                />
                            </Box>
                            <Box className={specificStyles.ExpertLegendTitle}>
                                {
                                    frFr.screen.homescreen
                                        .SALES_EXPERTS_RAPH_TITLE
                                }
                            </Box>
                            <Box className={specificStyles.ExpertLegend}>
                                {frFr.screen.homescreen.SALES_EXPERTS_RAPH}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                {props.displaySubscribeCTA && (
                    <Box
                        className={
                            isMobile
                                ? specificStyles.ImageHolderCenter
                                : specificStyles.ImageHolder
                        }
                    >
                        <MainCTA onClick={props.launchSusbscribe}>
                            Je m'abonne
                        </MainCTA>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
