export default {
    general: {
        androidLink:
            "https://play.google.com/store/apps/details?id=com.kahalex.composcan&hl=fr",
        iosLink: "https://apps.apple.com/fr/app/mireille/id1297199609",
        cgvLink: "https://composcan.fr/cgv.pdf",
        policyLink: "https://composcan.fr/politique-confidentialite.pdf",
        CLOSE: "Fermer",
        SEARCH: "Rechercher"
    },
    screen: {
        homescreen: {
            ROUTINE_TITLE: "Mireille c'est une véritable Coach Capilaire",
            ROUTINE_DESCRIPTION:
                "Pour enfin donner à tes cheveux ce dont ils ont vraiment besoin :\n\n 📱 Fais ton Diagnostic Capillaire\n\n👌 Découvre ta routine parfaite\n\n👑 Kiffe tes cheveux",
            SALES_APP_TITLE:
                "Mais aussi... une App pour Scanner tes cosmétiques",
            SALES_APP: `Pars en quête de produits clean :\n • Scan de codes barres\n • Recherche de produits\n • Traduction de compos\n\nTout pour comprendre et choisir tes cosmétiques.\n\nTélécharge Mireille !`,
            LOVE_TITLE: "Elles 💜 Mireille",
            LOVE_DESCRIPTION:
                "Pour créer cette super fonctionnalité, on l'a présenté à des dizaines de passionnées des cheveux.\n\nAu début, on écoutait  les retours médusées. Et puis on s'est mise à les filmer, parce que... WOHOU.\n\nParce que oui, on y crois à mort à notre Coach Capillaire mais entendre les autres dire que c'est génial...",
            SALES_EXPERTS_TITLE: "Deux super experts à ton service",
            SALES_EXPERTS_GABY_TITLE: "Gabrielle - Youtubeuse",
            SALES_EXPERTS_RAPH_TITLE: "Raphaël - Biologiste",
            SALES_EXPERTS_GABY:
                "analyse les compositons des produits et la communication des marques",
            SALES_EXPERTS_RAPH:
                "spécialiste de l'impact des cosmétiques sur la santé et l'environnement",
            SALES_INDEPENDANCE_TITLE: "L'indépendance",
            SALES_INDEPENDANCE:
                "Mireille, c'est une App payante\n\nPourquoi ? C'est assez simple: C'est le seul moyen de financer l'app sans la transformer en pub géante.\n\nTu t'inquiètes de savoir si des marques payent pour changer l'évaluation de leurs produits ? Dans Mireille c'est impossible !",
            SALES_INDEPENDANCE_QUOTE:
                "“Si c'est gratuit, c'est toi le produit”",
            COMPANY_DETAILS_TITLE: "Mireille est une App de Kahalex",
            COMPANY_DETAILS: `Siren : 813302627\nn° de TVA : FR60813302627`,
            CONTACT_DETAILS_TITLE: "Tu veux parler avec Kahina ?"
        },
        talkscreen: {
            TALK_TITLE: "Ton compte",
            TALK_GTU: "les CGV de Mireille",
            TALK_PRIVACY: "Confidentialité",
            TALK_CONTACT: "Nous contacter",
            SCAN_HISTORY: "Ton historique de scans",
            TALK_FOLLOW_US: "Suis-nous sur les réseaux !",
            TALK_SUBSCRIPTION_ANDROID:
                "Tu as un abonnement à Mireille via ton compte Google Play avec le moyen de paiement associé à ce compte. Les abonnements se renouvellent automatiquement à moins d’être annulés au moins 24h avant la fin de la période en cours, c’est valable aussi pour la période gratuite. L'annulation de l'abonnement se fait depuis les paramètres de ton compte Google Play. Désinstaller l’application ne termine pas l’abonnement.",
            TALK_SUBSCRIPTION:
                "Tu as un abonnement à Mireille via ton compte iTunes avec le moyen de paiement associé à ce compte. Les abonnements se renouvellent automatiquement à moins d’être annulés au moins 24h avant la fin de la période en cours, c’est valable aussi pour la période gratuite. L'annulation de l'abonnement se fait depuis les paramètres de ton compte iTunes. Désinstaller l’application ne termine pas l’abonnement."
        },
        searchscreen: {
            SEARCH_TITLE: "Rechercher",
            PRODUCT_PLACEHOLDER: "Nom du produit ou marque",
            RESULT_TITLE: "Résultats :",
            DESC_01: "Cherche un produit par...",
            DESC_02: "nom, marque, type de produit",
            DESC_03: "ou un mélange de tout ça !"
        },
        translatescreen: {
            PLACEHOLDER:
                "ex : Aloe Barbadensis Leaf Juice, Aqua (Water), Glyceryl Stearate, Rosmarinus Officinalis (Rosemary) Extract, Citrus Aurantium Amara (Bitter Orange) Flower Extract, Cetearyl Alcohol, Simmondsia Chinensis (Jojoba) Seed Oil, Glycerin, Persea Gratissima (Avocado) Oil, Butyrospermum Parkii (Shea) Butter",
            TRANSLATE_TITLE: "Mireille-trad",
            TRANSLATE_DESC:
                "Tu as trouvé une compo sur internet ?\nTraduis la avec un simple copier-coller.",
            TRANSLATE_INVITE: "Composition à traduire :",
            TRANSLATE_EMPTY: "EFFACER",
            TRANSLATE_PASTE: "Coller",
            TRANSLATE_TRANSLATE: "TRADUIRE"
        }
    },
    navbar: {
        bottom: {
            SCAN: "Scanner",
            SEARCH: "Rechercher",
            TRANSLATE: "Traduire",
            TALK: "Discuter",
            ACCOUNT: "Ton Compte"
        }
    },
    component: {
        layers: {
            purchaseHandler: {
                HEADER_TITLE_ERROR: "Aie...",
                HEADER_DETAIL_ERROR:
                    "Mireille ne parvient pas à réccupérer ton statut d'abonnement. C'est peut être lié à ta connexion",
                HEADER_TITLE_REGULAR_ERROR: "Vérifie ta connexion et...",
                HEADER_TITLE_RELOAD: "Recharge la page",

                // OFFER
                HEADER_TITLE_OFFER: "Abonne-toi maintenant et profite de ",
                HEADER_TITLE_BOLD_OFFER: "7 jours d’essai gratuits",
                HEADER_TITLE_REGULAR_OFFER:
                    "Abonne-toi maintenant et profite de ",
                HEADER_TITLE_SUBSCRIBE: "Je suis déja abonné",

                HEADER_TITLE_OFFER_GTU: "CGV",
                HEADER_TITLE_OFFER_PRIVACY: "Confidentialité",
                HEADER_TITLE_OFFER_SUBSCRIPTION_ANDROID:
                    "Les achats réalisés dans l’app se font via ton compte Google Play avec le moyen de paiement paramétré pour ce compte. Les abonnements se renouvellent automatiquement à moins d’être annulés au moins 24h avant la fin de la période en cours, depuis les paramètres de ton compte iTunes. C’est également valable pour la période gratuite. Sauf annulation au moins 24h avant la fin de la phase d’essai, tu bascules sur l’abonnement choisi.  Désinstaller l’application ne termine pas l’abonnement.",
                HEADER_TITLE_OFFER_SUBSCRIPTION:
                    "Les achats réalisés dans l’app se font via ton compte iTunes avec le moyen de paiement paramétré pour ce compte. Les abonnements se renouvellent automatiquement à moins d’être annulés au moins 24h avant la fin de la période en cours, depuis les paramètres de ton compte iTunes. C’est également valable pour la période gratuite. Sauf annulation au moins 24h avant la fin de la phase d’essai, tu bascules sur l’abonnement choisi.  Désinstaller l’application ne termine pas l’abonnement."
            },
            productLayer: {
                HEADER_TITLE: "Traduction",
                HEADER_CLOSE: "Fermer"
            },
            multiLayerHolder: {
                ERROR_TEXT_UNKNOWN:
                    "On ne connait pas encore ce produit, mais on travaille dur pour référencer tous les produits alors réessaye dans quelques jours !",
                ERROR_TEXT_NETWORK:
                    "Il semblerait que le réseau ne soit pas au top. Nous te conseillons de ré-essayer dans quelques instants.",
                ERROR_TEXT_FORMAT:
                    "Il y a un problème avec le format de ce code barre."
            },
            ingredientLayer: {
                RAPHAEL_ADVICE: "L'avis de Raphaël",
                RAPHAEL_ADVICE_NEUTRAL:
                    "Cet ingrédient ne présente pas de bénéfice du point de vu de la santé, mais il ne présente pas de danger non plus. Aujourd'hui, dans la presse scientifique, il n'y a pas de danger identifié lié à l'usage de cet ingrédient.",
                RAPHAEL_ADVICE_GOOD:
                    "Sur le plan strictement biologique, cet ingrédient est plutôt bon pour le corps ou les cheveux.",

                GABY_ADVICE: "Le conseil de Gaby",
                TITLE_FUNCTION_INCI: "Fonctions INCI :",
                BUTTON_THANKS: "Ok, merci"
            },
            compositionLayer: {
                TITLE: "Composition",
                FOOTER_DETAIL_01: "Composition analysée par nos experts",
                FOOTER_DETAIL_02: "Raphaël et Gabrielle."
            }
        },
        resultElements: {
            ingredientBrick: {
                GOOD: "BON",
                NEUTRAL: "NEUTRE",
                AVOID: "PRUDENCE",
                ATTENTION: "PRECAUTION D'EMPLOI",
                ALLERGENE: "ALLERGENE",
                DANGER: "DECONSEILLE",
                NO: "NON RECONNU",
                DEFAULT: "EN COURS D'EVALUATION",
                DETAILS: "Détails"
            },
            productHeader: {
                OVERVIEW_DANGER: "Déconseillé",
                OVERVIEW_AVOID: "Prudence",
                OVERVIEW_DEFAULT: "Rien de dangereux"
            }
        }
    }
};
