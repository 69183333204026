import * as CONSTS from "../../constants/parameters";

const defaultState = {
    //What will be stored here ?
    //The answers to all the questions of the form
    //The results of the crude profile
    //The advanced results of the needs profile
    hairProfile: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case CONSTS.SET_HAIR_MATRIX:
            return {
                ...state,
                hairProfile: action.payload
            };
        default:
            return state;
    }
};
