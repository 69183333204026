const prodServer = true;
const prodStripe = true;

const prodFront = true;
const modeDebug = false;
const rootUrl = prodServer
    ? modeDebug
        ? "http://195.15.228.146:8133"
        : "https://productapi.cosmetichacker.biz:8503"
    : modeDebug
    ? "http://82.64.117.122:8133"
    : "http://82.64.117.122:8003";
export const selfUrl = prodFront
    ? "https://www.mireille.co"
    : "http://localhost:3000";
export const stripePublicKey = prodStripe
    ? "pk_live_yBgARulgmiR5FX7y0YNMzMLz00ht2MeHGX"
    : "pk_test_XrMNi9HQ85yflOGDqesqxR0D00yhZAzo2c";
export const stripeRootReturnUrl = selfUrl;
export const getMireilleUser = rootUrl + "/get_or_create_mireille_user/";
export const getMireilleSubscriptionStatus =
    rootUrl + "/check_mireille_user_subscription_status/";
export const subscribeWithStripe = rootUrl + "/subscribe_with_stripe/";
export const getStripePortal = rootUrl + "/get_stripe_portal/";
export const consumeCoupon = rootUrl + "/consume_stripe_coupon/";

export const pressFolderUrl = selfUrl + "/press";
export const pressComunqueFolderUrl = pressFolderUrl + "/communiques/";
export const pressComunquePicFolderUrl = pressFolderUrl + "/CPIllus/";
export const pressRTPIllusFolderUrl = pressFolderUrl + "/RTPIllus/";

export const profileMicroUrl = prodServer
    ? "https://productapi.cosmetichacker.biz:8825"
    : "";

//export const getProfilesUrl = profileMicroUrl + "/get_hair_profiles/";
//export const deleteProfileUrl = profileMicroUrl + "/delete_hair_profile/";
//export const createProfileUrl = profileMicroUrl + "/create_hair_profile/";

export const getProfileUrl =
    "https://mireille-routines-back.herokuapp.com/profile/retrieve";
export const updateProfileUrl =
    "https://mireille-routines-back.herokuapp.com/profile/update";
export const getRoutinesByProfileUrl =
    "https://mireille-routines-back.herokuapp.com/routines/filter";
export const getRoutinesByDeepLinkUrl =
    "https://mireille-routines-back.herokuapp.com/routines/get_deep_link";
export const standardHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json"
};
