import React, { useEffect, useState } from "react";
import specificStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function StrengthDecor(props) {
    return (
        <Box
            className={specificStyles.strengthDecorHolder}
            style={{
                marginLeft: props.dimMarginLeft,
                marginTop: props.dimMarginTop
            }}
        >
            <img
                src={props.decorIllus}
                width={props.dimWidth}
                height={props.dimHeight}
                className={specificStyles.decorIllus}
            />
        </Box>
    );
}
