import specificStyles from "../Home.module.css";
import frFr from "../../../../constants/localisations/fr-Fr";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";

const titleIllusPurple = "images/home/titleIllusPurple.png";
const arobase = "images/home/arobase.png";
const phone = "images/home/phone.png";

export default function Footer(props) {
    return (
        <Box className={specificStyles.Slide4}>
            <Grid container spacing={0} className={specificStyles.footerHeart}>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    className={specificStyles.FooterBlockHolder}
                >
                    <Box className={specificStyles.storeZone}>
                        <Box>
                            <img
                                className={specificStyles.mireilleLogoImage}
                                src={titleIllusPurple}
                                alt=""
                            />
                        </Box>

                        <Box className={specificStyles.legalCTAHolder}>
                            <a
                                className={specificStyles.legalCTA}
                                href={"https://www.mireille.co/cgv.pdf"}
                            >
                                CGV
                            </a>
                            <Box
                                className={specificStyles.legalCTASeparator}
                                component="span"
                            >
                                {" "}
                                |{" "}
                            </Box>
                            <a
                                className={specificStyles.legalCTA}
                                href={
                                    "https://www.mireille.co/politique-confidentialite.pdf"
                                }
                            >
                                Confidentialité
                            </a>
                        </Box>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={5}
                    className={specificStyles.FooterBlockHolder}
                >
                    <Box className={specificStyles.HomeContentTitlePurple}>
                        {frFr.screen.homescreen.CONTACT_DETAILS_TITLE}
                    </Box>
                    <Box>
                        <Grid container spacing={0}>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                className={specificStyles.HomeContentPurple}
                            >
                                <img
                                    className={specificStyles.picto}
                                    src={arobase}
                                    alt=""
                                />
                                Kahina@mireille.co
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                className={specificStyles.HomeContentPurple}
                            >
                                <img
                                    className={specificStyles.picto}
                                    src={phone}
                                    alt=""
                                />
                                0786966095
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    className={specificStyles.FooterBlockHolder}
                >
                    <Box className={specificStyles.HomeContentTitlePurple}>
                        {frFr.screen.homescreen.COMPANY_DETAILS_TITLE}
                    </Box>
                    <Box className={specificStyles.HomeContentPurple}>
                        {frFr.screen.homescreen.COMPANY_DETAILS}
                    </Box>
                    <Box className={specificStyles.HomeContentPurple}>
                        <a
                            className={specificStyles.legalCTA}
                            href={
                                "https://www.mireille.co/mentions-legales.pdf"
                            }
                        >
                            Mentions légales
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
