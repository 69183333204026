import * as PARAMETERS from "../../constants/parameters";
import * as SETTINGS from "../../constants/settings";
var standardHeaders = new Headers();
standardHeaders.append("Content-Type", "application/json");

export const setHairProfileMatrix = (matrix) => {
    return async (dispatch, getState) => {
        dispatch({
            type: PARAMETERS.SET_HAIR_MATRIX,
            payload: matrix
        });
    };
};
