import React, { useEffect, useState } from "react";
import sStyles from "../Routines.module.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

export default function RoutineRow(props) {
    return (
        <Box
            className={sStyles.RoutineRow}
            onClick={() => props.openRoutine(props.routine)}
        >
            <Box className={sStyles.RoutineNameMask}>
                <Typography variant="h5">{props.routine.TITLE}</Typography>
            </Box>
            {props.routine.AUTHOR && props.routine.AUTHOR.PIC && (
                <Box className={sStyles.RoutineAuthorHolder}>
                    <img
                        className={sStyles.authorIllus}
                        src={props.routine.AUTHOR.PIC}
                        alt=""
                    />
                    {/*<Box className={sStyles.authorName}>
                    <Box>
                        <Typography variant="body2">
                            Par : {props.routine.AUTHOR.NAME}
                        </Typography>
                    </Box>
                </Box>*/}
                </Box>
            )}
        </Box>
    );
}
