import React, { useEffect, useState } from "react";
import specificStyles from "../Home.module.css";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { StandardCTA } from "../../../MireilleCTAS";
import { setPromoCode } from "../../../../redux/actions/account";
import { useDispatch } from "react-redux";

const flower1 = "images/home/flower1.png";
const flower2 = "images/home/flower2.png";
const flower3 = "images/home/flower3.png";

export default function Promo(props) {
    const dispatch = useDispatch();
    const validPromo = partnersData.hasOwnProperty(props.promoCode);

    const [isVIP, setIsVip] = useState(props.promoCode === "IAMVIP");
    const [partnerIllus, setPartnerIllus] = useState(
        partnersData.hasOwnProperty(props.promoCode)
            ? partnersData[props.promoCode]["illus"]
            : "images/partners/gaby.PNG"
    );
    const [partnerName, setPartnerName] = useState(
        partnersData.hasOwnProperty(props.promoCode)
            ? partnersData[props.promoCode]["name"]
            : ""
    );
    const [promoPrice, setPromoPrice] = useState(
        partnersData.hasOwnProperty(props.promoCode)
            ? partnersData[props.promoCode]["price"]
            : "19.99€"
    );

    useEffect(() => {
        if (validPromo) {
            dispatch(setPromoCode(partnersData[props.promoCode]["promoId"]));
        }
    }, []);

    return (
        <>
            {isMobile ? (
                <Box
                    className={specificStyles.SlidePromoMobile}
                    id="application"
                >
                    <Box className={specificStyles.pageHeartMobile}>
                        <Box className={specificStyles.ImageHolderCenter}>
                            <img
                                src={partnerIllus}
                                className={specificStyles.partnerIllus}
                            />
                        </Box>
                        <Box className={specificStyles.TextHolder}>
                            <Box className={specificStyles.HomeTitlePurple}>
                                {isVIP ? (
                                    <>
                                        <Box component="span">
                                            Tu as reçu une invitation
                                        </Box>
                                        <Box
                                            component="span"
                                            className={
                                                specificStyles.InfluencerName
                                            }
                                        >
                                            {partnerName}
                                        </Box>
                                        <Box component="span">
                                            ? Abonne toi à Mireille pour{" "}
                                            {promoPrice} par an au lieu de{" "}
                                        </Box>
                                        <Box
                                            component="span"
                                            className={
                                                specificStyles.StrokedPrice
                                            }
                                        >
                                            19,99€
                                        </Box>
                                    </>
                                ) : (
                                    <>
                                        {validPromo ? (
                                            <>
                                                <Box component="span">Avec</Box>
                                                <Box
                                                    component="span"
                                                    className={
                                                        specificStyles.InfluencerName
                                                    }
                                                >
                                                    {partnerName}
                                                </Box>
                                                <Box component="span">
                                                    , abonne toi à Mireille pour{" "}
                                                    {promoPrice} par an au lieu
                                                    de{" "}
                                                </Box>
                                                <Box
                                                    component="span"
                                                    className={
                                                        specificStyles.StrokedPrice
                                                    }
                                                >
                                                    19,99€
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                Mireille, la meilleure App pour
                                                scanner tes cosmétiques. Abonne
                                                toi pour 19,99€ par an.
                                            </>
                                        )}
                                    </>
                                )}
                            </Box>
                            {isVIP ? (
                                <Box
                                    className={[
                                        specificStyles.HomeContentPurple,
                                        specificStyles.freeTrialLabel
                                    ]}
                                >
                                    Tu peux utiliser Mireille gratuitement
                                </Box>
                            ) : (
                                <Box
                                    className={[
                                        specificStyles.HomeContentPurple,
                                        specificStyles.freeTrialLabel
                                    ]}
                                >
                                    On t'offre 14 jours pour essayer
                                    l'application
                                </Box>
                            )}
                            <Box className={specificStyles.ImageHolderCenter}>
                                <StandardCTA onClick={props.launchSusbscribe}>
                                    Je m'abonne
                                </StandardCTA>
                            </Box>
                        </Box>
                    </Box>
                    <img src={flower1} className={specificStyles.flower1} />
                    <img src={flower3} className={specificStyles.flower3} />
                </Box>
            ) : (
                <>
                    <Box className={specificStyles.SlidePromo} id="application">
                        <Box className={specificStyles.pageHeart}>
                            <Grid container spacing={0}>
                                <Grid
                                    item
                                    sm={9}
                                    xs={12}
                                    className={specificStyles.TextHolder}
                                >
                                    <Box
                                        className={
                                            specificStyles.HomeTitlePurple
                                        }
                                    >
                                        {isVIP ? (
                                            <>
                                                <Box component="span">
                                                    Tu as reçu une invitation
                                                </Box>
                                                <Box
                                                    component="span"
                                                    className={
                                                        specificStyles.InfluencerName
                                                    }
                                                >
                                                    {partnerName}
                                                </Box>
                                                <Box component="span">
                                                    ? Abonne toi à Mireille pour{" "}
                                                    {promoPrice} par an au lieu
                                                    de{" "}
                                                </Box>
                                                <Box
                                                    component="span"
                                                    className={
                                                        specificStyles.StrokedPrice
                                                    }
                                                >
                                                    19,99€
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                {validPromo ? (
                                                    <>
                                                        <Box component="span">
                                                            Avec
                                                        </Box>
                                                        <Box
                                                            component="span"
                                                            className={
                                                                specificStyles.InfluencerName
                                                            }
                                                        >
                                                            {partnerName}
                                                        </Box>
                                                        <Box component="span">
                                                            , abonne toi à
                                                            Mireille pour{" "}
                                                            {promoPrice} par an
                                                            au lieu de{" "}
                                                        </Box>
                                                        <Box
                                                            component="span"
                                                            className={
                                                                specificStyles.StrokedPrice
                                                            }
                                                        >
                                                            19,99€
                                                        </Box>
                                                    </>
                                                ) : (
                                                    <>
                                                        Mireille, la meilleure
                                                        App pour scanner tes
                                                        cosmétiques. Abonne toi
                                                        pour 19,99€ par an.
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>
                                    {isVIP ? (
                                        <Box
                                            className={[
                                                specificStyles.HomeContentPurple,
                                                specificStyles.freeTrialLabel
                                            ]}
                                        >
                                            Tu peux utiliser Mireille
                                            gratuitement
                                        </Box>
                                    ) : (
                                        <Box
                                            className={[
                                                specificStyles.HomeContentPurple,
                                                specificStyles.freeTrialLabel
                                            ]}
                                        >
                                            On t'offre 14 jours pour essayer
                                            l'application
                                        </Box>
                                    )}
                                    <Box>
                                        <StandardCTA
                                            onClick={props.launchSusbscribe}
                                        >
                                            Je m'abonne
                                        </StandardCTA>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    sm={3}
                                    xs={12}
                                    className={
                                        specificStyles.PartnerImageHolder
                                    }
                                >
                                    <img
                                        src={partnerIllus}
                                        className={specificStyles.partnerIllus}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <img src={flower1} className={specificStyles.flower1} />
                        <img src={flower2} className={specificStyles.flower2} />
                        <img src={flower3} className={specificStyles.flower3} />
                    </Box>
                </>
            )}
        </>
    );
}

/*
<Grid sm={4}>

                </Grid>
                <Grid sm={4}>
                    
                </Grid>
                <Grid sm={4}>
                    
                </Grid>
                */

const partnersData = {
    IAMVIP: {
        name: "VIP",
        price: "0€",
        promoId: "promo_1HyxdwGBmf3nfivevtAJF8DL",
        illus: "images/partners/gaby.PNG"
    },
    CHARLOTTEVIDL: {
        name: "Charlotte Vidl",
        price: "14,99€",
        promoId: "promo_1HxymRGBmf3nfivevHGvDx2x",
        illus: "images/partners/charlotteVidl.PNG"
    },
    MYPRETTYSUN: {
        name: "my Pretty Sun",
        price: "14,99€",
        promoId: "promo_1HxrFVGBmf3nfivePvwKhkYL",
        illus: "images/partners/myPrettySun.PNG"
    },
    AXPOVI: {
        name: "Axpovi",
        price: "14,99€",
        promoId: "promo_1HxrFVGBmf3nfivec9nATFZ7",
        illus: "images/partners/axpovi.PNG"
    },
    LAPETITEGABY: {
        name: "La Petite Gaby",
        price: "14,99€",
        promoId: "promo_1HxrFVGBmf3nfiveUSr3witH",
        illus: "images/partners/gaby.PNG"
    },
    MARINEPLNN: {
        name: "Marine plnn",
        price: "14,99€",
        promoId: "promo_1HyGWcGBmf3nfivewrJ5K0WM",
        illus: "images/partners/marinePlnn.PNG"
    },
    LAUTHENTIQUEHAIRSHOW: {
        name: "l'authentique hair show",
        price: "14,99€",
        promoId: "promo_1HyGXGGBmf3nfiveF3ifm6i6",
        illus: "images/partners/authentiqueHairShow.PNG"
    },
    ASMAEHA: {
        name: "Asmaeha",
        price: "14,99€",
        promoId: "promo_1HyHh6GBmf3nfiveBGS12lle",
        illus: "images/partners/asmaeha.PNG"
    },
    MARIEYOUPIE: {
        name: "Marie Youpie",
        price: "14,99€",
        promoId: "promo_1HyHhOGBmf3nfiveeuzuvyR8",
        illus: "images/partners/marieYoupie.PNG"
    },
    GINGERCHLOE: {
        name: "the Ginger Chloé",
        price: "14,99€",
        promoId: "promo_1HyHi4GBmf3nfive0FuirCMC",
        illus: "images/partners/theGingerChloe.PNG"
    },
    MARIE: {
        name: "Marie",
        price: "14,99€",
        promoId: "promo_1HyHiDGBmf3nfiveyeYV4vq6",
        illus: "images/partners/enjoyPhoenix.PNG"
    },
    MARTINE: {
        name: "Gaelle",
        price: "14,99€",
        promoId: "promo_1HyHlmGBmf3nfiveAT0OI5JY",
        illus: "images/partners/gaelleGarciaDiaz.PNG"
    },
    ELYKILLEUSE: {
        name: "Ely Killeuse",
        price: "14,99€",
        promoId: "promo_1HyHpfGBmf3nfivewaGtnqpy",
        illus: "images/partners/elyKilleuse.PNG"
    },
    LECOINDESNATURELLES: {
        name: "le coin des naturelles",
        price: "14,99€",
        promoId: "promo_1HyHscGBmf3nfivec3x6yMoF",
        illus: "images/partners/leCoinDesNaturelles.PNG"
    },
    JUSTINE: {
        name: "Justine",
        price: "14,99€",
        promoId: "promo_1HyI99GBmf3nfiveNXsXHu4F",
        illus: "images/partners/justineHutteau.PNG"
    },
    KAROLINERO: {
        name: "Karoline Ro",
        price: "14,99€",
        promoId: "promo_1HyedeGBmf3nfiveC1WGzObL",
        illus: "images/partners/karolineRo.PNG"
    },
    HOLYCAMILLE: {
        name: "Holy Camille",
        price: "14,99€",
        promoId: "promo_1HyefvGBmf3nfiveacDZmoRu",
        illus: "images/partners/holyCamille.PNG"
    },
    MARIECOOLES: {
        name: "Marie Cooles",
        price: "14,99€",
        promoId: "promo_1HyegvGBmf3nfivePEiPJ8eP",
        illus: "images/partners/marieCooles.PNG"
    },
    ANISSARYU: {
        name: "Anissa Ryu",
        price: "14,99€",
        promoId: "promo_1HyemmGBmf3nfivepWJfa0ky",
        illus: "images/partners/anissaRyu.PNG"
    },
    SARAHBA: {
        name: "Sarah",
        price: "14,99€",
        promoId: "promo_1HyiifGBmf3nfiveAhUBsFKF",
        illus: "images/partners/sarahBA.PNG"
    },
    VOSSTARSENREALITE: {
        name: "vos Stars en réalité",
        price: "14,99€",
        promoId: "promo_1Hyii4GBmf3nfivebZDHJ5AP",
        illus: "images/partners/vosStarsEnRealite.PNG"
    },
    LANANASBLONDE: {
        name: "lananasblonde",
        price: "14,99€",
        promoId: "promo_1Hz53aGBmf3nfiveoWuufj9L",
        illus: "images/partners/laNanasBlonde.PNG"
    },
    KHAIR: {
        name: "Khair",
        price: "14,99€",
        promoId: "promo_1HzQ3zGBmf3nfivemMQGyL8h",
        illus: "images/partners/kHair.PNG"
    },
    ROUTINESNATURELLES: {
        name: "Routines naturelles",
        price: "14,99€",
        promoId: "promo_1HzQ3lGBmf3nfiveHCkSWsJ1",
        illus: "images/partners/routinesNaturelles.PNG"
    },
    MONAHL: {
        name: "Mona",
        price: "14,99€",
        promoId: "promo_1HzQ3QGBmf3nfiveBAlClJyW",
        illus: "images/partners/monaHL.PNG"
    },
    JOYEUXNOEL: {
        name: "ce fantastique cadeau de Noël",
        price: "14,99€",
        promoId: "promo_1I0pODGBmf3nfivehz0lSr7r",
        illus: "images/partners/joyeuxNoel.PNG"
    },
    IVORYSANDY225: {
        name: "Ivory Sandy",
        price: "14,99€",
        promoId: "promo_1I0ryNGBmf3nfive37IEkUxg",
        illus: "images/partners/ivorySandy.PNG"
    },
    MARGAUXSTIPS: {
        name: "Margaux",
        price: "14,99€",
        promoId: "promo_1I0ry9GBmf3nfiveNxdSN44G",
        illus: "images/partners/margauxsTips.PNG"
    },
    VOICI: {
        name: "Voici",
        price: "14,99€",
        promoId: "promo_1IAI0YGBmf3nfive4qVSyyBf",
        illus: "images/partners/voici.PNG"
    },
    NINATURELLE: {
        name: "Ninaturelle",
        price: "14,99€",
        promoId: "promo_1IDc1IGBmf3nfivefNUlJBLO",
        illus: "images/partners/ninaturelle.PNG"
    },
    PROMO25: {
        name: "notre réduction spéciale",
        price: "14,99€",
        promoId: "promo_1IGt1SGBmf3nfivexUyZormd",
        illus: "images/partners/gaby.PNG"
    },
    CAMILLE: {
        name: "Camille",
        price: "14,99€",
        promoId: "promo_1IGtJuGBmf3nfiveEUK8xvaL",
        illus: "images/partners/camille.PNG"
    },
    KAZIDOMI: {
        name: "Kazidomi",
        price: "10,99€",
        promoId: "promo_1IJbKpGBmf3nfive1WY1By4G",
        illus: "images/partners/kazidomi.PNG"
    },
    SOUTIENAUXETUDIANTS: {
        name: "notre offre spéciale en soutien aux étudiants",
        promoId: "promo_1IVJB5GBmf3nfiveryfnoMEH",
        price: "9,99€",
        illus: "images/partners/etudiants.jpg"
    },
    ATELIERRICOCHET: {
        name: "l'Atelier Ricochet",
        promoId: "promo_1J5rzxGBmf3nfiveXkkGeUCZ",
        price: "14,99€",
        illus: "images/partners/atelierRicochet.PNG"
    }
};
