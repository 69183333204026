import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import specificStyles from "../App.module.css";
import { Box } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

export default function Menu(props) {
    return (
        <>
            <Box component="span" className={specificStyles.mireilleMenuEntry}>
                <HashLink
                    to="/home#application"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => props.closeMenu()}
                >
                    <Typography variant="h5">Application</Typography>
                </HashLink>
            </Box>
            {/*<Box component="span" className={specificStyles.mireilleMenuEntry}>
                <HashLink
                    to="/home#experts"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => props.closeMenu()}
                >
                    <Typography variant="h5">les Experts</Typography>
                </HashLink>
            </Box>
            <Box component="span" className={specificStyles.mireilleMenuEntry}>
                <HashLink
                    to="/home#independance"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => props.closeMenu()}
                >
                    <Typography variant="h5">Indépendance</Typography>
                </HashLink>
    </Box>*/}
            <Box component="span" className={specificStyles.mireilleMenuEntry}>
                <Link
                    to="/mireille-trad"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => props.closeMenu()}
                >
                    <Typography variant="h5">Mireille Trad</Typography>
                </Link>
            </Box>
            <Box component="span" className={specificStyles.mireilleMenuEntry}>
                <Link
                    to="/profile"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => {
                        props.closeMenu();
                    }}
                >
                    <Typography variant="h5">Diagnostic capillaire</Typography>
                </Link>
            </Box>
            <Box component="span" className={specificStyles.mireilleMenuEntry}>
                <Link
                    to="/offres"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => {
                        props.closeMenu();
                    }}
                >
                    <Typography variant="h5">Mes offres</Typography>
                </Link>
            </Box>

            <Box component="span" className={specificStyles.mireilleMenuEntry}>
                <Link
                    to="/account"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => {
                        props.closeMenu();
                    }}
                >
                    <Typography variant="h5">Mon compte</Typography>
                </Link>
            </Box>
            <Box component="span" className={specificStyles.mireilleMenuEntry}>
                <Link
                    to="/press"
                    className={specificStyles.mireilleMenuLabel}
                    onClick={() => {
                        props.closeMenu();
                    }}
                >
                    <Typography variant="h5">Presse</Typography>
                </Link>
            </Box>
        </>
    );
}
/*
 <Box component="span" className={specificStyles.mireilleMenuEntry}>
            <Link
              to="/press"
              className={specificStyles.mireilleMenuLabel}
              onClick={()=>{
                props.closeMenu()
              }}>
              <Typography variant="h5">
                Presse
              </Typography>
            </Link>
        </Box>
        */
