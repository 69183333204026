import specificStyles from "../Home.module.css";
import frFr from "../../../../constants/localisations/fr-Fr";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import { WhiteCTA } from "../../../MireilleCTAS";

const exampleIllus = "images/home/exampleIllus.png";

export default function Independance(props) {
    return (
        <Box className={specificStyles.Slide3} id="independance">
            <Box className={specificStyles.pageHeart}>
                {isMobile ? (
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            className={specificStyles.ImageHolderCenter}
                        >
                            <img
                                className={specificStyles.simpleExampleMobile}
                                src={exampleIllus}
                                alt=""
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={specificStyles.TextHolder}
                        >
                            <Box className={specificStyles.HomeTitle}>
                                {
                                    frFr.screen.homescreen
                                        .SALES_INDEPENDANCE_TITLE
                                }
                            </Box>
                            <Box className={specificStyles.HomeContent}>
                                {frFr.screen.homescreen.SALES_INDEPENDANCE}
                            </Box>
                            <Box className={specificStyles.HomeContentQuote}>
                                {
                                    frFr.screen.homescreen
                                        .SALES_INDEPENDANCE_QUOTE
                                }
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={4}
                            className={specificStyles.ImageHolder}
                        >
                            <img
                                className={specificStyles.simpleExample}
                                src={exampleIllus}
                                alt=""
                            />
                        </Grid>
                        <Grid item xs={8} className={specificStyles.TextHolder}>
                            <Box className={specificStyles.HomeTitle}>
                                {
                                    frFr.screen.homescreen
                                        .SALES_INDEPENDANCE_TITLE
                                }
                            </Box>
                            <Box className={specificStyles.HomeContent}>
                                {frFr.screen.homescreen.SALES_INDEPENDANCE}
                            </Box>
                            <Box className={specificStyles.HomeContentQuote}>
                                {
                                    frFr.screen.homescreen
                                        .SALES_INDEPENDANCE_QUOTE
                                }
                            </Box>
                        </Grid>
                    </Grid>
                )}

                {props.displaySubscribeCTA && (
                    <Box
                        className={
                            isMobile
                                ? specificStyles.ImageHolderCenter
                                : specificStyles.ImageHolder
                        }
                    >
                        <WhiteCTA onClick={props.launchSusbscribe}>
                            Je m'abonne
                        </WhiteCTA>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
