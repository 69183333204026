import React, { useEffect, useState } from "react";
import specificStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function Intro() {
    const illus = "images/offers/lvl3.png";
    return (
        <>
            <img src={illus} className={specificStyles.introIllus} />
            <Box className={specificStyles.questionLabel}>
                <Typography variant="h4">
                    Tu veux savoir de quoi tes cheveux ont vraiment besoin ?
                </Typography>
            </Box>
            <Box className={specificStyles.questionSubLabel}>
                <Typography variant="body2">
                    Décris-nous ta routine actuelle, tes objectifs et tes
                    problématiques (si tu en as), et on t’aidera à identifier
                    les besoins de ta crinière.
                </Typography>
            </Box>
            <Box className={specificStyles.introFiller} />
        </>
    );
}
