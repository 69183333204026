import React, { useEffect, useState } from "react";
import sStyles from "../Routines.module.css";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import RoutineRow from "../components/RoutineRow";
import {
    getRoutinesByProfileUrl,
    standardHeaders
} from "../../../../constants/settings";
import RoutineDisplay from "../components/RoutineDisplay";
import { Typography } from "@mui/material";
import RoutineSearchFilter from "../components/util/RoutineSearchFilter";
import { searchOptions } from "../constants/routineParams";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getProfileUrl } from "../../../../constants/settings";
import ConnexionPopin from "../../../subscriptionHandler/ConnexionPopin";
//import { pushPageView } from "../../../utils/gtmHandler";

import { setHairProfileMatrix } from "../../../../redux/actions/hair";

const illus = "images/offers/lvl4.png";

const emptyFilters = {
    POROSITY: null,
    PURITY: null,
    TEXTURE: null,
    PUBLISHED: true
};

export default function RoutineList() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { hairProfile } = useSelector((state) => state.hair);
    const { mireilleUser } = useSelector((state) => state.account);
    const [routines, setRoutines] = useState([]);
    const [cheatClickCount, setCheatClickCount] = useState(0);
    const [cheatActive, setCheatActive] = useState(false);

    const [routine, setRoutine] = useState(null);
    const [filters, setFilters] = useState(emptyFilters);

    const [connect, isConnect] = useState(false);

    /*useEffect(() => {
        pushPageView("routines");
    }, []);*/

    useEffect(() => {
        if (filters.POROSITY !== null) {
            loadRoutinesForProfile();
        }
    }, [filters]);

    useEffect(() => {
        if (mireilleUser !== null) {
            isConnect(false);
            loadUserProfile();
        } else {
            isConnect(true);
        }
    }, [mireilleUser]);

    useEffect(() => {
        if (hairProfile !== null) {
            setFilters({
                ...filters,
                POROSITY: hairProfile.POROSITY,
                PURITY: hairProfile.PURITY,
                TEXTURE: hairProfile.TEXTURE
            });
        } else {
            loadUserProfile();
        }
    }, [hairProfile]);

    const loadUserProfile = () => {
        if (mireilleUser && mireilleUser.FIREBASE_ID) {
            var raw = {
                FIREBASE_ID: mireilleUser.FIREBASE_ID
            };
            var standardHeaders = new Headers();
            standardHeaders.append("Content-Type", "application/json");

            fetch(getProfileUrl, {
                method: "POST",
                headers: standardHeaders,
                body: JSON.stringify(raw)
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.STATUS === "Ok") {
                        dispatch(
                            setHairProfileMatrix(
                                normalizeHairProfile(data.PROFILE)
                            )
                        );
                    } else {
                        goToDiag();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const normalizeHairProfile = (matrix) => {
        var result = {};
        result.TEXTURE = matrix.TEXTURE;
        result.POROSITY = normalizeHairStat(matrix.POROSITY, 8);
        result.PURITY = normalizeHairStat(matrix.SCORES.PURITY, 12);
        return result; //TODO change return value with result
    };

    const normalizeHairStat = (stat, root) => {
        return Math.min(Math.max(Math.floor(stat / root), 0), 24 / root - 1);
    };

    useEffect(() => {
        if (cheatClickCount >= 5) {
            setCheatActive(true);
        }
    }, [cheatClickCount]);

    const loadRoutinesForProfile = () => {
        fetch(getRoutinesByProfileUrl, {
            headers: standardHeaders,
            method: "POST",
            body: JSON.stringify(filters)
        })
            .then((response) => response.json())
            .then((data) => {
                setRoutines(
                    data.ROUTINES.map((item) => {
                        return item.ROUTINE;
                    })
                );
            })
            .catch((errorData) => console.log(errorData));
    };

    const updateFilter = (key, value) => {
        var filt = { ...filters };
        filt[key] = value;
        setFilters({ ...filt });
    };

    const handleFilter = (event, newFilters, currentFilter, key) => {
        var filterResult = currentFilter;
        newFilters.some((filter) => {
            if (currentFilter === filter) {
            } else {
                filterResult = filter;
                return true;
            }
            return false;
        });
        updateFilter(key, filterResult);
    };

    const goToDiag = () => {
        isConnect(false);
        setRoutine(null);
        navigate("/profile", { replace: true });
    };

    return (
        <Box className={sStyles.simpleLayout}>
            {connect ? (
                <ConnexionPopin
                    isOpen={connect}
                    canClose={false}
                    handleClose={() => {
                        goToDiag();
                    }}
                    overrideInviteCreate={"et découvre les routines"}
                />
            ) : (
                <>
                    {routine && (
                        <RoutineDisplay
                            routine={routine}
                            handleClose={() => setRoutine(null)}
                            redirect={"/routines"}
                        />
                    )}
                    <Box className={sStyles.routineListIntroHolder}>
                        <Box className={sStyles.routineListIntro}>
                            <img
                                src={illus}
                                className={sStyles.introIllus}
                                onClick={() =>
                                    setCheatClickCount(cheatClickCount + 1)
                                }
                            />
                            <Box className={sStyles.routineIntroHolder}>
                                <Typography variant="h5">
                                    Coucou Mireille 💜
                                </Typography>
                                <Typography variant="h5">
                                    {routines.length > 0
                                        ? "Voici ce que l'on a en stock, spécialement fait pour ton profil !"
                                        : "On n'a pas encore de routine pour ton profil, ça arrive... 🚀"}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                    {cheatActive && (
                        <Box className={sStyles.cheatFilters}>
                            <RoutineSearchFilter
                                filter={filters.POROSITY}
                                criterion={searchOptions.porosity}
                                handleFilter={handleFilter}
                            />
                            <RoutineSearchFilter
                                filter={filters.PURITY}
                                criterion={searchOptions.purity}
                                handleFilter={handleFilter}
                            />
                            <RoutineSearchFilter
                                filter={filters.TEXTURE}
                                criterion={searchOptions.texture}
                                handleFilter={handleFilter}
                            />
                            <RoutineSearchFilter
                                filter={filters.PUBLISHED}
                                criterion={searchOptions.published}
                                handleFilter={handleFilter}
                            />
                        </Box>
                    )}
                    {routines.map((routine) => {
                        return (
                            <RoutineRow
                                routine={routine}
                                openRoutine={(rout) => setRoutine(rout)}
                            />
                        );
                    })}
                </>
            )}
        </Box>
    );
}
