import React from "react";
import specificStyles from "../Press.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

const separator = " : ";

export default function CommuniqueBlock(props) {
    return (
        <Box className={specificStyles.CPBrick}>
            <Box className={specificStyles.CPillusHolder}>
                <a href={props.url} className={specificStyles.ArticleLabel}>
                    <img src={props.illus} className={specificStyles.CPIllus} />
                </a>
            </Box>
            <Box className={specificStyles.ContactLine}>
                <a href={props.url} className={specificStyles.ArticleLabel}>
                    <Typography variant="h4">
                        {props.date + " - " + props.title}
                    </Typography>
                </a>
            </Box>
        </Box>
    );
}
