import React from "react";
import Dialog from "@mui/material/Dialog";
import specificStyles from "../Profile.module.css";
import { Box, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

export default function LegendPrompt(props) {
    return (
        <Dialog
            open={props.legendOpen}
            onClose={props.closeLegend}
            PaperProps={{
                style: { borderRadius: (isMobile ? 0.8 : 1) * 25 + "pt" }
            }}>
            <Box className={specificStyles.legendPromptPopin}>
                <Box className={specificStyles.gabyPicHolder}>
                    <img
                        src={props.decor.decorIllus}
                        width={props.decor.dimWidth}
                        height={props.decor.dimHeight}
                    />
                </Box>
                <Box className={specificStyles.strengthLegendTitle}>
                    <Typography variant="h3">{props.decor.label}</Typography>
                </Box>
                <Box className={specificStyles.strengthLegendTitle}>
                    <Typography
                        variant="body2"
                        style={{ whiteSpace: "pre-line" }}
                    >
                        {props.decor.definition}
                    </Typography>
                </Box>
            </Box>
        </Dialog>
    );
}
