import React, { useEffect, useState } from "react";
import specificStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

export default function HairTextureAnswer(props) {
    const toggleAnswer = () => {
        props.toggleAnswer(props.answer);
    };
    return (
        <Box
            className={specificStyles.hairTextureAnswer}
            style={{
                backgroundImage: "url(" + props.answer.pic + ")",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                border: props.selected
                    ? " 1pt solid var(--mireille-purple)"
                    : "1pt solid var(--mireille-purple-opacified)"
            }}
            onClick={toggleAnswer}
        >
            <Box
                className={specificStyles.hairTextureAnswerLabel}
                onClick={toggleAnswer}
            >
                <Typography variant="h2">{props.answer.label}</Typography>
            </Box>
        </Box>
    );
}
/**/
