import React, { useEffect, useState } from "react";
import sStyles from "../Routines.module.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { CleanWhiteLink, CleanLink } from "../../../MireilleCTAS";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function RitualHeader(props) {
    return (
        <header
            className={
                props.sticky ? sStyles.stickyHeader : sStyles.unstickyHeader
            }
        >
            <Box className={sStyles.headerHolder}>
                <Box className={sStyles.backButton}>
                    <CleanWhiteLink onClick={props.closeRoutine}>
                        <ArrowBackIosIcon />
                    </CleanWhiteLink>
                </Box>
                <Box className={sStyles.routineTitle}>
                    <Typography variant="h7">{props.title}</Typography>

                    <CleanWhiteLink onClick={props.displayAuthor}>
                        <Typography variant="h2">
                            en savoir plus sur l'autrice
                        </Typography>
                    </CleanWhiteLink>
                </Box>
                <Box className={sStyles.backButton}></Box>
            </Box>
        </header>
    );
}
