import React, { useEffect } from "react";
import { getOrCreateMireilleUser } from "../../redux/actions/account";
import { useDispatch, useSelector } from "react-redux";
//import { pushEvent } from "../utils/gtmHandler";

export default function AuthManager(props) {
    const dispatch = useDispatch();
    const { firebaseUser } = useSelector((state) => state.account);

    useEffect(() => {
        if (firebaseUser) {
            dispatch(getOrCreateMireilleUser());
        }
    }, [firebaseUser]);
    return <></>;
}
