import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import EditAccountDataPopin from "./EditAccountDataPopin.js";
import specificStyles from "../Account.module.css";
import SignOut from "../../../auth/SignOut.js";
import { getAuth } from "firebase/auth";
import ConnexionPopin from "../../../subscriptionHandler/ConnexionPopin.js";
import { StandardCleanCTA } from "../../../MireilleCTAS.js";

const emailPlaceHolder = "images/placeHolders/email/emailPlaceHolder.png";
const lockPlaceHolder = "images/placeHolders/lock/lockPlaceHolder.png";

export default function ManageAccountSection() {
    const { mireilleUser, connexionStatus } = useSelector(
        (state) => state.account
    );

    var [editEmail, setEditEmail] = useState(false);
    var [editPwd, setEditPwd] = useState(false);
    var [myEmail, setMyEmail] = useState("");

    const [plainConnect, isPlainConnect] = useState(false);

    useEffect(() => {
        getCurrentEmail();
    }, [mireilleUser]);

    const getCurrentEmail = () => {
        let firebaseUser = getAuth().currentUser;
        if (firebaseUser !== null) {
            setMyEmail(firebaseUser.email);
        } else {
            setMyEmail("");
        }
    };
    const handleEditAccountDataClose = () => {
        setEditEmail(false);
        setEditPwd(false);
        getCurrentEmail();
    };

    useEffect(() => {
        if (connexionStatus === "Connected") {
            isPlainConnect(false);
        }
    }, [connexionStatus]);

    return (
        <Box className={specificStyles.salesBlock}>
            <ConnexionPopin
                isOpen={plainConnect}
                canClose={true}
                handleClose={() => isPlainConnect(false)}
            />
            {mireilleUser && (
                <EditAccountDataPopin
                    editEmail={editEmail}
                    editPwd={editPwd}
                    handleClose={handleEditAccountDataClose}
                />
            )}
            <Typography
                variant="h5"
                className={specificStyles.manageDataTitleHolder}
            >
                Mon compte :
            </Typography>
            {mireilleUser ? (
                <>
                    <Box
                        onClick={() => setEditEmail(true)}
                        className={specificStyles.manageableAccountDataHolder}
                    >
                        <Box className={specificStyles.manageableAccountData}>
                            <Typography
                                noWrap
                                style={{ textOverflow: "ellipsis" }}
                                variant="body1"
                                className={specificStyles.textDisplay}
                            >
                                {myEmail}
                            </Typography>
                        </Box>
                        <Box className={specificStyles.imagePlaceHolderBox}>
                            <img
                                className={specificStyles.manageAccountImage}
                                src={emailPlaceHolder}
                                alt=""
                            />
                        </Box>
                    </Box>

                    <Box
                        onClick={() => setEditPwd(true)}
                        className={specificStyles.manageableAccountDataHolder}
                    >
                        <Box className={specificStyles.manageableAccountData}>
                            <Typography variant="body1">**********</Typography>
                        </Box>
                        <Box className={specificStyles.imagePlaceHolderBox}>
                            <img
                                className={specificStyles.manageAccountImage}
                                src={lockPlaceHolder}
                                alt=""
                            />
                        </Box>
                    </Box>
                    <Box className={specificStyles.expand} />
                    <SignOut />
                </>
            ) : (
                <>
                    <Box
                        className={[
                            specificStyles.manageDataSubTitleHolder,
                            specificStyles.expand
                        ]}
                    >
                        <Typography variant="body1">
                            Pour voir les informations de ton compte tu dois te
                            connecter :)
                        </Typography>
                    </Box>
                    <Box className={specificStyles.manageDataTitleHolder}>
                        <StandardCleanCTA onClick={() => isPlainConnect(true)}>
                            <Typography variant="h3">Me connecter</Typography>
                        </StandardCleanCTA>
                    </Box>
                </>
            )}
        </Box>
    );
}
