import specificStyles from "../Home.module.css";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";
import frFr from "../../../../constants/localisations/fr-Fr";

import { Typography } from "@mui/material";
import { StandardYellowCTA } from "../../../MireilleCTAS";

const appStoreCTA = "images/home/appStoreCTA.png";
const playStoreCTA = "images/home/playStoreCTA.png";
const iosOptionLogo = "images/mireilleOptions/iosOptionLogo.png";
const androidOptionLogo = "images/mireilleOptions/androidOptionLogo.png";
const mireilleTradOptionLogo =
    "images/mireilleOptions/mireilleTradOptionLogo.png";
const fullExampleIllus = "images/home/fullExampleIllus.png";

export default function Love(props) {
    return (
        <Box className={specificStyles.Slide2} id="love">
            <Box className={specificStyles.pageHeart}>
                {isMobile ? (
                    <Grid container spacing={0}>
                        <Grid
                            item
                            xs={12}
                            className={specificStyles.TextHolder}
                        >
                            <Box
                                className={specificStyles.HomeTitlePurpleMobile}
                            >
                                <Typography variant="h16">
                                    {frFr.screen.homescreen.LOVE_TITLE}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={7} className={specificStyles.TextHolder}>
                            <Box className={specificStyles.HomeContentPurple}>
                                <Typography variant="h3">
                                    {frFr.screen.homescreen.LOVE_DESCRIPTION}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            className={specificStyles.ImageHolder}
                        >
                            <video width="150" controls>
                                <source
                                    src="/videos/love.mp4"
                                    type="video/mp4"
                                />
                            </video>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={specificStyles.TextHolder}
                        >
                            <Box className={specificStyles.CTAHolderMobile}>
                                <StandardYellowCTA onClick={props.goToDiag}>
                                    <Typography variant="h7">
                                        Je commence mon coaching !
                                    </Typography>
                                </StandardYellowCTA>
                            </Box>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={0}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={6} className={specificStyles.TextHolder}>
                            <Box className={specificStyles.HomeTitlePurple}>
                                {frFr.screen.homescreen.LOVE_TITLE}
                            </Box>
                            <Box className={specificStyles.HomeContentPurple}>
                                {frFr.screen.homescreen.LOVE_DESCRIPTION}
                            </Box>

                            <Box className={specificStyles.CTAHolder}>
                                <StandardYellowCTA onClick={props.goToDiag}>
                                    <Typography variant="h7">
                                        Je commence mon coaching !
                                    </Typography>
                                </StandardYellowCTA>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className={specificStyles.ImageHolderLeft}
                        >
                            <video width="300" controls>
                                <source
                                    src="/videos/love.mp4"
                                    type="video/mp4"
                                />
                            </video>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Box>
    );
}
