import React, { useState } from "react";

import SubscriptionHandlerModal from "../../subscriptionHandler/SubscriptionHandlerModal";
import SpecialOfferHandler from "../../subscriptionHandler/SpecialOfferHandler";
import Sales from "./components/Sales";
import Experts from "./components/Experts";
import Independance from "./components/Independance";
import Promo from "./components/Promo";
import Footer from "./components/Footer";

export default function PromotionPage(props) {
    const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);

    const launchSusbscribe = () => {
        setOpenSubscriptionModal(true);
    };

    return (
        <>
            <Promo
                launchSusbscribe={launchSusbscribe}
                promoCode={props.promoCode}
            />
            <Sales
                displayStoreCtas={false}
                displaySubscribeCTA={true}
                launchSusbscribe={launchSusbscribe}
            />
            <Experts
                displaySubscribeCTA={true}
                launchSusbscribe={launchSusbscribe}
            />
            <Independance
                displaySubscribeCTA={true}
                launchSusbscribe={launchSusbscribe}
            />
            <Footer displayStoreCTAs={false} />
            {openSubscriptionModal && (
                <SpecialOfferHandler
                    forceConnect={openSubscriptionModal}
                    cancelPurchase={() => setOpenSubscriptionModal(false)}
                    monthAmount={0}
                    subscription={true}
                />
            )}
        </>
    );
}
