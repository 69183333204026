import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import Congrats from '../../auth/Congrats';
import { initiateStripePurchase } from "../../../redux/actions/account";
import * as SETTINGS from "../../../constants/settings";
import MyOffers from "./MyOffers";
import { Box, StepConnector } from "@mui/material";
import ConnexionPopin from "../ConnexionPopin";
import { MainCTA } from "../../MireilleCTAS";
import SignOut from "../../auth/SignOut";
import specificStyles from "./Paywall.module.css";
import Footer from "../../pages/home/components/Footer";
import { Typography } from "@mui/material";
//import { pushSimpleEvent } from "../../utils/gtmHandler";
export default function Paywall(props) {
    const stripe = window.Stripe(SETTINGS.stripePublicKey);
    const dispatch = useDispatch();
    const { stripeCheckoutSession, mireilleUser, connexionStatus } =
        useSelector((state) => state.account);
    const [connect, isConnect] = useState(
        connexionStatus !== "Connected" && props.forceConnexion
    );
    const [purchaseParameters, setPurchaseParameters] = useState(null);
    const [paywallTitle, setPaywallTitle] = useState(props.payWallTitle);
    const [offersTitle, setOffersTitle] = useState(props.payWallOffersTitle);

    const initiateSubscription = () => {
        dispatch(
            initiateStripePurchase(
                true,
                0,
                props.onSuccessUrl,
                props.onCancelUrl
            )
        );
        //Todo call endpoint to get a checkout session
    };

    const initiateOneShotPurchase = (monthAmount, forMe) => {
        dispatch(
            initiateStripePurchase(
                false,
                monthAmount,
                props.onSuccessUrl,
                props.onCancelUrl,
                forMe
            )
        );
    };

    useEffect(() => {
        if (stripeCheckoutSession) {
            stripe
                .redirectToCheckout({
                    sessionId: stripeCheckoutSession.id
                })
                .then(handleResult);
        }
    }, [stripeCheckoutSession]);

    useEffect(() => {
        if (mireilleUser !== null) {
            isConnect(false);
            if (mireilleUser.SUBSCRIPTION_STATUS !== "SUBSCRIBED") {
                purchase(purchaseParameters);
            } else {
                setPaywallTitle(
                    "Tu es déja abonnée, pas besoin d'en rajouter :) Mais tu peux toujours offrir Mireille si tu veux !"
                );
                setOffersTitle(
                    "Voici les options pour offrir Mireille à tes proches !"
                );
            }
        }
    }, [mireilleUser]);

    const handleResult = () => {};

    const setSelectOffer = (params) => {
        if (connexionStatus === "Connected") {
            purchase(params);
        } else {
            setPurchaseParameters(params);
            //TODO here make sure that the user triggers subscription on connexion completed
            isConnect(true);
        }
    };

    const purchase = (params) => {
        //pushSimpleEvent("add_to_cart", "subscription");
        if (Boolean(params && params.MAKE_PURCHASE)) {
            if (params.SUBSCRIPTION) {
                initiateSubscription();
            } else {
                initiateOneShotPurchase(params.MONTH_AMOUNT, params.FOR_ME);
            }
        }
    };

    return (
        <>
            {connect && (
                <ConnexionPopin
                    isOpen={connect}
                    canClose={true}
                    handleClose={() => {
                        isConnect(false);
                    }}
                />
            )}
            <Box className={specificStyles.background}>
                <Box className={specificStyles.pageHeart}>
                    {props.displayHeader && (
                        <Box className={specificStyles.paywallHeader}>
                            <Typography variant="h4">{paywallTitle}</Typography>
                        </Box>
                    )}
                    {props.children}
                    {props.displayOffers && (
                        <>
                            <Box className={specificStyles.paywallHeader}>
                                <Typography variant="h6">
                                    {offersTitle}
                                </Typography>
                            </Box>
                            <Box>
                                <MyOffers
                                    selectOffer={setSelectOffer}
                                    displayOfferMireille={
                                        props.displayOfferMireille
                                    }
                                    displayForMe={
                                        !mireilleUser ||
                                        mireilleUser.SUBSCRIPTION_STATUS !==
                                            "SUBSCRIBED"
                                    }
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            <Footer displayStoreCTAs={true} />
        </>
    );
}
