import withStyles from "@mui/styles/withStyles";
import Input from "@mui/material/Input";

export const StandardConnexionInput = withStyles((theme) => ({
    root: {
        height: "6vh",
        padding: "1vh",
        borderRadius: "1vh",
        backgroundColor: "rgba(48, 19, 102, 0.05)",
        fontFamily: "WorkSansBold",
        width: "300pt",
        maxWidth: "90vw",
        fontSize: "1.7vh",
        lineHeight: "4vh",
        verticalAlign: "middle",
        color: "#301366"
    },
    input: {
        height: "4vh"
    }
}))(Input);
