import React from "react";
import { useEffect } from "react";

import Experts from "./components/Experts";
import Independance from "./components/Independance";
import Footer from "./components/Footer";
import Sales from "./components/Sales";
import Routines from "./components/Routines";
import Love from "./components/Love";
import { useNavigate } from "react-router-dom";
//import { pushPageView, trackStandardEvent } from "../../utils/gtmHandler";

export default function Home() {
    const navigate = useNavigate();

    const goToDiag = () => {
        //trackStandardEvent("Lead", { lead_name: "home CTA" });
        navigate("/profile", { replace: true });
    };

    /* useEffect(() => {
        pushPageView("home");
    }, []);*/

    return (
        <>
            <Routines
                displayStoreCtas={true}
                displaySubscribeCTA={false}
                goToDiag={goToDiag}
            />
            <Love displaySubscribeCTA={false} goToDiag={goToDiag} />
            <Sales displayStoreCtas={true} displaySubscribeCTA={false} />
            <Footer displayStoreCTAs={true} />
        </>
    );
}
