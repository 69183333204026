import React, { useEffect, useState } from "react";
import specificStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

const infoPic = "images/profile/decor/info.png";

export default function StrengthLegend(props) {
    const openLegendPrompt = () => {
        props.openLegend(props.decor);
    };

    return (
        <>
            <Box className={specificStyles.strengthLegendTitleHolder}>
                <Box className={specificStyles.illusHolder}>
                    <img
                        src={props.decor.decorIllus}
                        width={props.decor.dimWidth}
                        height={props.decor.dimHeight}
                    />
                </Box>
                <Box className={specificStyles.strengthLegendTitle}>
                    <Typography variant="h3">{props.decor.label}</Typography>
                </Box>
                <Box
                    className={specificStyles.legendDefinitionHolder}
                    onClick={openLegendPrompt}
                >
                    <img src={infoPic} width={"15pt"} height={"15pt"} />
                </Box>
            </Box>
            <Box className={specificStyles.emptyGauge}>
                {props.strength.SCORE === 0 && (
                    <Box className={specificStyles.minGauge}> </Box>
                )}
                {props.strength.SCORE === 1 && (
                    <Box className={specificStyles.medInfGauge}> </Box>
                )}
                {props.strength.SCORE === 2 && (
                    <Box className={specificStyles.medSupGauge}> </Box>
                )}
                {props.strength.SCORE === 3 && (
                    <Box className={specificStyles.maxGauge}> </Box>
                )}
            </Box>
            <Box className={specificStyles.strengthLegendTitle}>
                <Typography variant="body2">
                    {props.strength.DESCRIPTION}
                </Typography>
            </Box>
        </>
    );
}
/**/
