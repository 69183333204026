import React from "react";
import specificStyles from "../Press.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";

const separator = " : ";

export default function ArticleBrick(props) {
    return (
        <Box className={specificStyles.ArticleBrick}>
            <Box className={specificStyles.illusHolder}>
                <a href={props.url} className={specificStyles.ArticleLabel}>
                    <img
                        src={props.illus}
                        className={specificStyles.ArticleIllus}
                    />
                </a>
            </Box>
            <Box className={specificStyles.quoteHolder}>
                <a href={props.url} className={specificStyles.ArticleLabel}>
                    <Typography variant="body1">{props.quote}</Typography>
                </a>
            </Box>
        </Box>
    );
}
