import React, { useEffect, useState } from "react";
import specificStyles from "../Profile.module.css";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import StrengthDecor from "./StrengthDecor";
import { PROFILE_DECOR } from "../../../../constants/profileParameters";

//isMobile ? 0.8 : 1) *
export default function GraphQuadrantDecor(props) {
    const toggleAnswer = () => {
        props.toggleAnswer(props.answer.keyLabel);
    };
    return (
        <Box className={specificStyles.decorLayout}>
            {PROFILE_DECOR.map((decor, index) => {
                return (
                    <>
                        {(index === 0 || index === 1 || index === 3) && (
                            <Box className={[specificStyles.decorRow]}>
                                {PROFILE_DECOR.map((dec, decIndex) => {
                                    return (
                                        <>
                                            {((index === 0 && decIndex === 0) ||
                                                (index === 1 &&
                                                    (decIndex === 1 ||
                                                        decIndex === 2)) ||
                                                (index === 3 &&
                                                    (decIndex === 3 ||
                                                        decIndex === 4))) && (
                                                <StrengthDecor
                                                    decorIllus={dec.pic}
                                                    dimWidth={dec.dimWidth}
                                                    dimHeight={dec.dimHeight}
                                                    dimMarginTop={
                                                        dec.dimMarginTop
                                                    }
                                                    dimMarginLeft={
                                                        dec.dimMarginLeft
                                                    }
                                                />
                                            )}
                                        </>
                                    );
                                })}
                            </Box>
                        )}
                    </>
                );
            })}
        </Box>
    );
}
