import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SignIn from "../auth/SignIn";
import SignUp from "../auth/SignUp";
import Dialog from "@mui/material/Dialog";
import AuthActionsWrapper from "./AuthActionsWrapper";
import specificStyles from "./AuthActions.module.css";
import { Box } from "@mui/material";
import SignOut from "../auth/SignOut";
import ResetPassword from "../auth/ResetPassword";
import ConnexionForm from "./ConnexionForm";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

export default function ConnexionPopin(props) {
    return (
        <Dialog
            fullScreen
            open={props.isOpen}
            PaperProps={{
                style: {
                    overflowX: "hidden",
                    heigth: "100%",
                    background: "white"
                }
            }}
        >
            {props.canClose && (
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar style={{ background: "white" }}>
                        <IconButton
                            edge="start"
                            color="primary"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            )}
            <Box className={specificStyles.pageHeartMobile}>
                <ConnexionForm
                    intro={
                        props.overrideInviteCreate
                            ? props.overrideInviteCreate
                            : null
                    }
                />
            </Box>
        </Dialog>
    );
}

/*
 <Dialog
            open={props.isOpen}
            onClose={props.handleClose}
            classes={{
                paper: specificStyles.authDialogBox
            }}
        >
            <Box className={specificStyles.authModal}>
                <ConnexionForm />
            </Box>
        </Dialog>*/
